
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { openMapUtil } from "../../js/modules/map-utils";
import { setPushNotification, showErroNotification, detectBrowser } from '../../js/utils/notifications/push-notification-util';
import { setResizeEvent } from '../../js/utils/resize-util';
export default {
  methods: {
    openToilet(id, type) {
      if (!e) {
        var e = window.event;
      }

      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$router.navigate('/toilet/' + id + '/' + type);
    },

    openMap(lat, lon) {
      if (!e) {
        var e = window.event;
      }

      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      openMapUtil(lat, lon);
    }

  },
  on: {
    pageInit: function (page) {
      var self = this; // Resize event

      setResizeEvent(self); // INIT Searchbars

      var searchbars = {};

      for (var pdi_type of self.pdi_pass_types) {
        for (var pass_type of pdi_type.pass_types) {
          searchbars[pass_type.name] = self.$app.searchbar.create({
            el: `.search-${pass_type.name}`,
            // inputEl: `.searchbar-input-${pass_type.name}`,
            // backdrop: true,
            // backdropEl: `.searchbar-backdrop-${pass_typename}`,
            searchContainer: `.searchbar-found-${pass_type.name}`,
            notFoundEl: `.searchbar-not-found-${pass_type.name}`,
            searchIn: [`.item-search-${pass_type.name}`]
          });
        }
      } // Set Notifications


      if (this.$app.data.user.role === "Admin") {
        setPushNotification(this).then(response => {}).catch(error => {
          console.error(error);
        });
      }
    }
  },
  id: 'fe95b1c0ba',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="home">\n\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n\n				<!-- Left -->\n				<div class="left">\n					<a href="#" class="link icon-only panel-open navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n					<div class="title">Lista Punti Di Interesse</div>\n				</div>\n\n				<div class="right">\n					<a href="#" class="link icon-only" @click="window.print()">\n						<i class="icon f7-icons tooltip-init"\n						   data-tooltip="Stampa pagina web">\n							printer_fill\n						</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<!-- End navbar -->\n\n		<!-- Tabs selector -->\n		<div class="toolbar toolbar-top tabbar">\n			<div class="toolbar-inner">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<a href="#tab-';
              r += c(ctx_3.name, ctx_3);
              r += '" class="tab-link">\n					';
              r += Template7Helpers.js_if.call(ctx_3, 'window.innerWidth <= 800 && this.hasOwnProperty("title_short")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title_short', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				</a>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<!-- END Tabs selector -->\n\n\n		<div class="tabs-animated-wrap">\n			<!-- Tabs Views -->\n			<div class="tabs">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="page-content tab" id="tab-';
              r += c(ctx_3.name, ctx_3);
              r += '">\n					<!-- Searchbar bagni -->\n					<div class="searchbar-div">\n						<form class="searchbar search-';
              r += c(ctx_3.name, ctx_3);
              r += '" >\n							<div class="searchbar-inner">\n								<div class="searchbar-input-wrap">\n									<input class=\'searchbar-input-';
              r += c(ctx_3.name, ctx_3);
              r += '\' type="search" placeholder="Cerca ';
              r += c(ctx_3.title, ctx_3);
              r += '..."/>\n									<i class="searchbar-icon"></i>\n									<span class="input-clear-button"></span>\n								</div>\n								<span class="searchbar-disable-button if-not-aurora">Annulla</span>\n							</div>\n						</form>\n					</div>\n					<!-- End Searchbar -->\n\n					<!-- Searchbar backdrop-->\n					<div class="searchbar-backdrop" id="searchbar-backdrop-';
              r += c(ctx_3.name, ctx_3);
              r += '"></div>\n					<div class="list media-list searchbar-found-';
              r += c(ctx_3.name, ctx_3);
              r += ' searchbar-found-div">\n						<ul>\n							';
              r += Template7Helpers.each.call(ctx_3, ctx_3.elems, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<li>\n								<a href="#" class="item-link item-content" @click="openToilet(\'';
                  r += c(ctx_4._id, ctx_4);
                  r += '\', \'';
                  r += c(ctx_3.name, ctx_4);
                  r += '\')">\n									<div class="item-media">\n										<i class="f7-icons text-color-yellow">scope</i>\n									</div>\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '">\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '">\n													';
                  r += Template7Helpers.js.call(ctx_4, 'this._id', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' - ';
                  r += Template7Helpers.js.call(ctx_4, 'this.name', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n												</span>\n											</div>\n											<div class="item-after">\n												<!-- Cleaning suspended -->\n												';
                  r += Template7Helpers.js_if.call(ctx_4, 'typeof this.passes_summary ==="string"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n													';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.passes_summary === "cleaning_suspended"', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n													<span class="item-search-';
                          r += c(ctx_5.name, ctx_6);
                          r += '">Pulizie sospese!</span>\n													';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n												';
                      r += Template7Helpers.js_if.call(ctx_5, 'this === 1', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.js_if.call(ctx_5, 'this === 0', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.js_if.call(ctx_5, 'this === -1', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.js_if.call(ctx_5, 'this === -2', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += Template7Helpers.js_if.call(ctx_5, 'this === -3', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n												<!-- Summary -->\n												<div>\n													';
                      r += Template7Helpers.each.call(ctx_5, ctx_5.passes_summary, {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '\n														';
                          r += Template7Helpers.js_if.call(ctx_6, 'this === 1', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n														<!-- DONE -->\n															<i class="icon f7-icons text-color-lightblue tooltip-init" data-tooltip="Passaggio atteso completato">drop_fill</i>\n														';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n														';
                          r += Template7Helpers.js_if.call(ctx_6, 'this === 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n														<!-- IN PROGRESS -->\n															<i class="icon f7-icons text-color-lightblue tooltip-init" data-tooltip="Passaggio atteso in corso">drop</i>\n														';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n														';
                          r += Template7Helpers.js_if.call(ctx_6, 'this === -1', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n														<!-- NOT DONE -->\n															<i class="icon f7-icons text-color-gray tooltip-init" data-tooltip="Passaggio atteso non ancora completato">drop</i>\n														';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n														';
                          r += Template7Helpers.js_if.call(ctx_6, 'this === -2', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n														<!-- ADDITIONAL DONE -->\n															<i class="icon f7-icons text-color-yellow tooltip-init" data-tooltip="Passaggio aggiuntivo completato">drop_fill</i>\n														';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n														';
                          r += Template7Helpers.js_if.call(ctx_6, 'this === -3', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '\n														<!-- ADDITIONAL IN PROGRESS -->\n															<i class="icon f7-icons text-color-yellow tooltip-init" data-tooltip="Passaggio aggiuntivo in corso">drop</i>\n														';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '\n													';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n												</div>\n												';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											</div>\n										</div>\n										<div class="item-subtitle">\n											';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.passes_summary_frequency==="week"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n											<span class="item-search-';
                      r += c(ctx_5.name, ctx_5);
                      r += '">Piano Settimanale</span>\n											';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.passes_summary_frequency==="day"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n											<span class="item-search-';
                      r += c(ctx_5.name, ctx_5);
                      r += '">Piano Giornaliero</span>\n											';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n										</div>\n										<div class="item-text">\n											<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '">';
                  r += c(ctx_4.address, ctx_4);
                  r += '</span>\n											<i class="icons f7-icons tooltip-init text-color-lightblue"\n											   @click="openMap(\'';
                  r += c(ctx_4.gps.lat, ctx_4);
                  r += '\',\'';
                  r += c(ctx_4.gps.lon, ctx_4);
                  r += '\')"\n											   data-tooltip="Apri Mappa" >\n												map_pin_ellipse\n											</i>\n										</div>\n									</div>\n								</a>\n							</li>\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						</ul>\n					</div>\n\n					<!-- Not found -->\n					<div class="block searchbar-not-found-';
              r += c(ctx_3.name, ctx_3);
              r += ' searchbar-not-found">\n						<div class="block-inner">\n							Nessun ';
              r += Template7Helpers.js.call(ctx_3, 'this.title.toLowerCase()', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' trovato\n						</div>\n					</div>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    