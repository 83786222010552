
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  methods: {},
  on: {
    pageInit: function (page) {}
  },
  id: '05d42936cc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=pdi-stats-details><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only back navbar-icon-link" data-ignore-cache=true data-force=true><i class="icon f7-icons">arrow_left</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Statistiche ';
      r += Template7Helpers.js.call(ctx_1, 'this.pdi._id', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' - ';
      r += Template7Helpers.js.call(ctx_1, 'this.pdi.name', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><!-- Page Content --><div class=page-content><div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">calendar</i></div>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pdi.stats.from && this.pdi.stats.to', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=demo-facebook-name>Statistiche relative al periodo dal <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'new Date(this.pdi.stats.from).toLocaleDateString("it")', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' </span>al <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'new Date(this.pdi.stats.to).toLocaleDateString("it")', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=demo-facebook-name>Statistiche <span class=text-color-yellow>NON disponibili</span></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pdi.stats.from && this.pdi.stats.to', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.stats, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="fas fa-toilet text-color-yellow font-awesome-icons"></i><!--\n						<i class="icon f7-icons text-color-yellow">square_favorites</i>\n						--></div><div class=demo-facebook-name>Statistiche Passaggi su ';
              r += Template7Helpers.js.call(ctx_3, 'this.title', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div><div class=card-content><div class=list><ul><li class=item-content><div class="item-inner item-row"><div class=item-cell>Numero di passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.object.number_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span></div><div class=item-cell>Numero di passaggi completati: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.object.completed_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span></div></div></li>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.object.completed_passes > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li class=item-content><div class="item-inner item-row"><div class=item-cell>Durata media dei passaggi: <span class=text-color-yellow>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.object.formatted_mean_time.hour > 0', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.formatted_mean_time.hour', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.object.formatted_mean_time.hour > 1', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ore ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ora ';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.object.formatted_mean_time.min > 0', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.formatted_mean_time.min', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.object.formatted_mean_time.min > 1', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' minuti ';
                          return r;
                        },
                        inverse: function (ctx_6, data_6) {
                          var r = '';
                          r += ' minuto ';
                          return r;
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</span></div>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.object.completed_passes > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=item-cell>Deviazione standard della durata dei passaggi: <span class=text-color-yellow>';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.object.formatted_standard_deviation.hour > 0', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ';
                          r += Template7Helpers.js.call(ctx_6, 'this.object.formatted_standard_deviation.hour', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.object.formatted_standard_deviation.hour > 1', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' ore ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += ' ora ';
                              return r;
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.object.formatted_standard_deviation.min > 0', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ';
                          r += Template7Helpers.js.call(ctx_6, 'this.object.formatted_standard_deviation.min', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.object.formatted_standard_deviation.min > 1', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += ' minuti ';
                              return r;
                            },
                            inverse: function (ctx_7, data_7) {
                              var r = '';
                              r += ' minuto ';
                              return r;
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div>';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=item-cell>Deviazione standard non disponibile.<br>Sono necessari almeno due passaggi completati.</div>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></li>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.object.anomalies.time + this.object.anomalies.gps.total > 0', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li class=item-content><div class=item-inner><div class=item-title><strong>Riepilogo Anomalie</strong></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Numero di anomalie di posizione: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.gps.total', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div><div class=item-cell>Numero di anomalie di durata: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.time', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Correzioni di entrambe le posizioni: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.gps.resolved_both', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div><div class=item-cell>Correzioni della posizione di partenza: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.gps.resolved_start', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Correzioni della posizione finale: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.gps.resolved_end', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div><div class=item-cell>Anomalie di posizione ignorate: <span class=text-color-yellow>';
                      r += Template7Helpers.js.call(ctx_5, 'this.object.anomalies.gps.resolved_ignore', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</span></div></div></li>';
                      r += Template7Helpers.js_if.call(ctx_5, 'Object.keys(this.object.user_anomalies).length > 0', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<!-- USER RANKING --> ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.object.user_anomalies_ranking.gps_top.length > 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '<li class=item-content><div class=item-inner><div class=item-title><strong>Operatori con maggiori anomalie di posizione registrate</strong></div></div></li><li class=item-content><div class="item-inner item-row">';
                              r += Template7Helpers.each.call(ctx_7, ctx_7.object.user_anomalies_ranking.gps_top, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '<div class=item-cell>';
                                  r += Template7Helpers.js.call(ctx_8, 'this.user_name', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [ctx_7, ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                                  });
                                  r += ': <span class=text-color-yellow>';
                                  r += Template7Helpers.js.call(ctx_8, 'this.total', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [ctx_7, ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                                  });
                                  r += '</span></div>';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '</div></li>';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          r += Template7Helpers.js_if.call(ctx_6, 'this.object.user_anomalies_ranking.time_top.length > 0', {
                            hash: {},
                            data: data_6 || {},
                            fn: function (ctx_7, data_7) {
                              var r = '';
                              r += '<li class=item-content><div class=item-inner><div class=item-title><strong>Operatori con maggiori anomalie di durata registrate</strong></div></div></li><li class=item-content><div class="item-inner item-row">';
                              r += Template7Helpers.each.call(ctx_7, ctx_7.object.user_anomalies_ranking.time_top, {
                                hash: {},
                                data: data_7 || {},
                                fn: function (ctx_8, data_8) {
                                  var r = '';
                                  r += '<div class=item-cell>';
                                  r += Template7Helpers.js.call(ctx_8, 'this.user_name', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [ctx_7, ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                                  });
                                  r += ': <span class=text-color-yellow>';
                                  r += Template7Helpers.js.call(ctx_8, 'this.total', {
                                    hash: {},
                                    data: data_8 || {},
                                    fn: function empty() {
                                      return '';
                                    },
                                    inverse: function empty() {
                                      return '';
                                    },
                                    root: root,
                                    parents: [ctx_7, ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                                  });
                                  r += '</span></div>';
                                  return r;
                                },
                                inverse: function empty() {
                                  return '';
                                },
                                root: root,
                                parents: [ctx_6, ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                              });
                              r += '</div></li>';
                              return r;
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += ' ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li class=item-content><div class=item-inner><div class=item-title><strong>Nessuna Anomalia Registrata</strong></div></div></li>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<li class=item-content><div class=item-inner><div class=item-title><strong>Statistiche non disponibili. Nessun passaggio completato</strong></div></div></li>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</ul></div></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    