
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { openMapUtil } from "../js/modules/map-utils";
import { startDownload, loadImagesUtil } from "../js/utils/checks.js";
export default {
  methods: {
    openPass(id, type) {
      this.$router.navigate('/pass/' + id + '/' + type + '/');
    },

    openToilet(id, type) {
      this.$router.navigate('/toilet/' + id + '/' + type + '/');
    },

    openMap(lat, lon) {
      openMapUtil(lat, lon);
    },

    downloadImages() {
      startDownload(this);
    },

    loadImages(e) {
      loadImagesUtil(e, this);
    }

  },
  on: {
    pageBeforeOut: function (page) {},
    pageInit: function (page) {
      var photoBrowser = this.$app.photoBrowser.create({
        photos: this.photoArr
      });
      this.$$('.card-header').on('click', el => {
        photoBrowser.open();
      });
    }
  },
  id: 'c129c59dc8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=check-details><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only back navbar-icon-link" data-ignore-cache=true data-force=true><i class="icon f7-icons">arrow_left</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Controllo su Passaggio del ';
      r += c(ctx_1.check.pass.name, ctx_1);
      r += '</div></div><div class=right><a href=# class="link icon-only" @click=downloadImages()><i class="icon f7-icons tooltip-init" data-tooltip="Scarica Tutte le Immagini">arrow_down_circle_fill </i></a><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><!-- End navbar --><!-- Page content --><div class=page-content><div class="card main-card">';
      r += Template7Helpers.js_if.call(ctx_1, 'this.new_org', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.images.pre.length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card-header align-items-flex-end" style="background-image: url(\'';
              r += Template7Helpers.js.call(ctx_3, 'this.images.pre[0].url', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\')"><span class=size-22>';
              r += Template7Helpers.js.call(ctx_3, 'this.images.pre[0].caption', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</span></div>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card-header align-items-flex-end"><span class=size-22>Nessuna Foto Disponibile</span></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card-header style="background-image: url(\'data:image/jpg;base64,';
          r += c(ctx_2.images.mainImage, ctx_2);
          r += '\')"><!-- Icons? --></div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="card-content card-content-padding"><!-- Sheet menu? --><p>Controllo relativo a <span class=text-color-lightblue @click="openPass(\'';
      r += c(ctx_1.check.pass.id, ctx_1);
      r += '\',\'toilets\')">';
      r += c(ctx_1.check.pass.name, ctx_1);
      r += ' </span>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.check.toilet.type.includes("sanitization")', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' (Sanificazione Bagno: ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.check.toilet.type.includes("toilets")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.check.toilet.type.includes("toilets")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' (Bagno/Cabina: ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' (Bagno: ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' (Cabina: ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.check.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' (Piazzale: ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <span class=text-color-lightblue @click="openToilet(\'';
      r += c(ctx_1.check.toilet.id, ctx_1);
      r += '\',\'toilets\')">';
      r += Template7Helpers.js_if.call(ctx_1, 'this.check.toilet.elem_id && !this.check.toilet.type.includes("square")', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'this.check.toilet.elem_id', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += c(ctx_1.check.toilet.name, ctx_1);
      r += ' </span>)</p><div class=text-color-lightblue><i class="icons f7-icons tooltip-init" @click="openMap(\'';
      r += c(ctx_1.check.gps.lat, ctx_1);
      r += '\',\'';
      r += c(ctx_1.check.gps.lon, ctx_1);
      r += '\')" data-tooltip="Apri Mappa">map_pin_ellipse </i>Accuratezza GPS: ';
      r += c(ctx_1.check.gps.acc, ctx_1);
      r += ' metri</div><p class=text-color-gray>Segnalato da: ';
      r += c(ctx_1.check.user.firstname, ctx_1);
      r += ' ';
      r += c(ctx_1.check.user.familyname, ctx_1);
      r += ' il ';
      r += c(ctx_1.check.date_time, ctx_1);
      r += '</p></div><div class=card-footer>';
      r += c(ctx_1.check.notes, ctx_1);
      r += '</div></div><!-- Images --> ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new_org, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- PRE PHOTOS --> ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.images.pre.length !== 1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card separator-card"><div class="card-header text-color-lightblue">Foto Pre Pulizia</div></div>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.images.pre.length > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.each.call(ctx_4, ctx_4.images.pre, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ';
                      r += Template7Helpers.js_if.call(ctx_5, '!@first', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += '<div class="card main-card"><div class=card-header style="background-image: url(\'';
                          r += c(ctx_6.url, ctx_6);
                          r += '\')"></div><div class="card-content card-content-padding"><p>';
                          r += Template7Helpers.js.call(ctx_6, 'this.caption', {
                            hash: {},
                            data: data_6 || {},
                            fn: function empty() {
                              return '';
                            },
                            inverse: function empty() {
                              return '';
                            },
                            root: root,
                            parents: [ctx_5, ctx_4, ctx_3, ctx_2, ctx_1]
                          });
                          r += '</p></div></div>';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += ' ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.@root.check.manual_insert && (this.@root.$app.data.user.role === "Coordinator" || @root.$app.data.user.role==="Admin")', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.@root.check.manual_insert && (this.@root.$app.data.user.role === "Coordinator" || @root.$app.data.user.role==="Admin")', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class="card main-card"><div class="card-content card-content-padding"><div class=list><ul><li class="item-content intem-input"><div class=item-inner><div class=item-input-wrap><input type=file class=pre-photos accept=image/* name=check-pre-photo photo-type=pre @change=loadImages multiple=multiple></div></div></li></ul></div></div></div>';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class="card main-card"><div class="card-content card-content-padding"><p>Foto non disponibile</p></div></div>';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<!-- POST PHOTOS --><div class="card separator-card"><div class="card-header text-color-lightblue">Foto Post Pulizia</div></div>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.images.post.length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.images.post, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card main-card"><div class=card-header style="background-image: url(\'';
                  r += c(ctx_4.url, ctx_4);
                  r += '\')"></div><div class="card-content card-content-padding"><p>';
                  r += Template7Helpers.js.call(ctx_4, 'this.caption', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</p></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.@root.check.manual_insert && (this.@root.$app.data.user.role === "Coordinator" || @root.$app.data.user.role==="Admin")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.@root.check.manual_insert && (this.@root.$app.data.user.role === "Coordinator" || @root.$app.data.user.role==="Admin")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card main-card"><div class="card-content card-content-padding"><div class=list><ul><li class="item-content intem-input"><div class=item-inner><div class=item-input-wrap><input type=file class=post-photos accept=image/* name=check-post-photo photo-type=post @change=loadImages multiple=multiple></div></div></li></ul></div></div></div>';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card main-card"><div class="card-content card-content-padding"><p>Foto non disponibile</p></div></div>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- OLD ORG --> ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.images, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, '@key != "mainImage"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card main-card"><div class=card-header style="background-image: url(\'data:image/jpg;base64,';
                  r += c(ctx_4, ctx_4);
                  r += '\')"></div><div class="card-content card-content-padding"><p>Immagine aggiuntiva n. ';
                  r += Template7Helpers.js.call(ctx_4, "(@key).split('addImg')[1]", {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</p></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    