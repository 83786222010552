import {
	default_limit,
	getAnomalies,
	resolveGpsAnomaly,
	setResolvedFlagAnomaly
} from '../modules/couchdb-anomalies-module';
import {getAddressFromGps} from '../modules/map-utils';

export function reloadAnomaliesUtils(resolved, self) {
	if (self.resolved === resolved) {
		return;
	}
	self.$app.preloader.show();
	getAnomalies(self.anomalies_type, undefined, resolved, (error, response) => {
		if (error) {
			console.log(error);
			self.$app.preloader.hide();
			self.$app.dialog.alert("Qualcosa è andato storto!", ()=>{
				self.$router.refreshPage();
			});
		} else {
			self.$app.preloader.hide();
			self.resolved = resolved;
			self.anomalies = response;
			self.$setState(self.anomalies, () => {
				initInfiniteScroll(self)
			});
		}
	});
}

export function initInfiniteScroll(self) {
	if (self.anomalies.length < default_limit) {
		self.$(`.anomalies-scroll-preloader`).css('display', 'none');
		return;
	}
	self.$(`#anomalies-infinite-preloader`).css('display', 'block');
	var allow_infinite = true;
	self.$('#anomalies-page-content').on('infinite', function() {
		if (!allow_infinite) {
			return;
		}
		allow_infinite = false;
		getAnomalies(self.anomalies_type, self.anomalies[self.anomalies.length - 1]._id, self.resolved, (error, response) => {
			if (error) {
				self.$app.dialog.alert("Qualcosa è andato storto!", () => {
					allow_infinite = true;
				});
			} else {
				if (response.length === 0) {
					self.$(`#anomalies-infinite-preloader`).css('display', 'none');
				} else {
					self.anomalies = self.anomalies.concat(response);
					self.$setState();
					allow_infinite = true;
				}
			}
		});
	});
}

function resolveAnomalyUtil(mode, self) {
	self.$app.preloader.show();
	var pass_gps_obj = undefined;
	if (mode !== 'ignore') {
		const pdi_gps_obj = {
			gps_lat: self.anomaly.pdi.gps.lat,
			gps_lon: self.anomaly.pdi.gps.lon,
			gps_acc: -1
		};
		switch (mode) {
			case 'both':
				pass_gps_obj = {
					start: pdi_gps_obj,
					end: pdi_gps_obj
				};
				break;
			case 'start':
				pass_gps_obj = {
					start: pdi_gps_obj,
					end: self.anomaly.pass.gps.end
				};
				break;
			case 'end':
				pass_gps_obj = {
					start: self.anomaly.pass.gps.start,
					end: pdi_gps_obj
				};
				break;
			default:
				return;
		}
	}
	resolveGpsAnomaly(self.anomaly, pass_gps_obj, mode, (error, respose) => {
		if (error) {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Qualcosa è andato storto!', () => {
				self.$router.refreshPage();
			});
		} else {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Anomalia risolta!', () => {
				self.$router.navigate(`/anomalies/${self.anomaly.type}`);
			});
		}
	});
}

export function resolveAnomalyConfirmMsg(mode, self) {
	var msg = ``;
	switch (mode) {
		case 'both':
			msg = `Sicuro di voler correggere le posizioni di
			<span class="text-color-black">partenza</span> e <span class="text-color-black">fine</span>
			del passaggio utilizzando la posizione del punto di interesse?`;
			break;
		case 'start':
			msg = `Sicuro di voler correggere la posizione di
			<span class="text-color-black">partenza</span>
			del passaggio utilizzando la posizione del punto di interesse?`;
			if (self.anomaly.gps_end) {
				msg = `${msg}<br><span class="text-color-red">La posizione finale non sarà modificata e non sarà più possibile modificarla!</span>`
			}
			break;
		case 'end':
			msg = `Sicuro di voler correggere la posizione
			<span class="text-color-black">finale</span>
			del passaggio utilizzando la posizione del punto di interesse?`;
			if (self.anomaly.gps_start) {
				msg = `${msg}<br><span class="text-color-red">La posizione di partenza non sarà modificata e non sarà più possibile modificarla!</span>`
			}
			break;
		case 'ignore':
			msg = `Sicuro di voler <span class="text-color-black">ignorare</span> l'anomalia?`
			break;
		default:
			return;
	}
	self.$app.dialog.confirm(msg, () => {
		resolveAnomalyUtil(mode, self);
	});
}

export function resolveTimeAnomalyUtil(self) {
	self.$app.dialog.confirm(`Sicuro di voler rimuovere l'anomalia dalla lista delle non risolte?`, () => {
		self.$app.preloader.show();
		setResolvedFlagAnomaly(self.anomaly._id, undefined, (error, response) => {
			if (error) {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Qualcosa è andato storto!', () => {
					self.$router.refreshPage();
				});
			} else {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Anomalia risolta!', () => {
					self.$router.navigate(`/anomalies/${self.anomaly.type}`);
				});
			}
		});
	});
}
