import Template7 from 'template7';
import $$ from 'dom7';


export function customTemplate7reg() {
	// Cleaning plan table input
	Template7.registerHelper('getCleaningPlanInput', function(type, week_day, pdi) {
		var $input = $$('<input></input>');
		$input.attr('type', 'number');
		$input.prop('id', `pdi-${type}-cleaning-plan-${week_day.index}`);
		$input.addClass(`pdi-cleaning-plan`);
		$input.attr('pdi_type', type);
		$input.attr('day_index', week_day.index);
		$input.attr('value', pdi[type].wpass[week_day.index]);
		$input.prop('value', pdi[type].wpass[week_day.index]);
		$input.attr('min', '0');

		if (pdi[type].cleaning_suspended) {
			$input.prop('disabled', true);
		} else {
			$input.prop('disabled', false);
		}

		return $input.prop('outerHTML');
	});

	// ID cards for toilets and cabins
	Template7.registerHelper('getElemIdCards', function(type, gender, pdi_elems, user_role) {
		if (type !== 'toilets' && type !== 'cabins') {
			return '';
		}
		console.log(user_role);
		var output = ``;
		var keys_array = Object.keys(pdi_elems).sort();
		for(var key of keys_array) {
			var $col_div = $$('<div></div>');
			$col_div.addClass('col-20 card');

			// CARD HEADER
			var $card_header = $$('<div></div>');
			$card_header.addClass('card-header');
			$card_header.text(key);

			if (user_role === "Admin") {
				var $remove_icon_div = $$('<div></div>');
				$remove_icon_div.addClass('right');
				var $remove_icon_link = $$('<a></a>');

				var $remove_icon = $$('<i></i>');
				$remove_icon.addClass('icon f7-icons tooltip-init');
				$remove_icon.text('minus_circle');

				switch (type) {
					case 'toilets':
						$remove_icon_link.addClass('remove-toilet-icon');
						$remove_icon_link.attr('toilet_id', key);
						$remove_icon_link.attr('toilet_gender', gender);

						$remove_icon.attr('toilet_id', key);
						$remove_icon.attr('toilet_gender', gender);
						$remove_icon.attr('data-tooltip', 'Rimouvi Bagno');
						break;
					case 'cabins':
						$remove_icon_link.addClass('remove-cabin-icon');
						$remove_icon_link.attr('cabin_id', key);

						$remove_icon.attr('cabin_id', key);
						$remove_icon.attr('data-tooltip', 'Rimouvi Cabina');
						break;
					default:
						return '';
				}

				$remove_icon_link.append($remove_icon);
				$remove_icon_div.append($remove_icon_link);
				$card_header.append($remove_icon_div);
			}

			$col_div.append($card_header);

			// CARD CONTENT
			switch (type) {
				case 'toilets':
					var $card_content = $$('<div></div>');
					$card_content.addClass('card-content card-content-padding');

					var $list_div = $$('<div></div>');
					$list_div.addClass('list');

					var $list_container = $$('<ul></ul>');
					var $list_elem = $$('<li></li>');

					var $label = $$('<label></label>');
					$label.addClass('item-checkbox item-content');
					var $input = $$('<input></input>');
					$input.attr('type', 'checkbox');
					$input.attr('id', `toilet-unusable-${key}`);
					$input.addClass('toilet-unusable');
					$input.attr('toilet_gender', gender);
					$input.attr('toilet_id', key);
					if (pdi_elems[key].unusable) {
						$input.prop('defaultChecked', true);
						$input.prop('checked', true);
					} else {
						$input.prop('defaultChecked', false);
						$input.prop('checked', false);
					}
					var $checkbox_icon = $$('<i></i>');
					$checkbox_icon.addClass('icon icon-checkbox');
					var $checkbox_inner = $$('<div></div>');
					$checkbox_inner.addClass('item-inner');
					var $item_title = $$('<div></div>');
					$item_title.text('Inagibile');

					$label.append($input);
					$label.append($checkbox_icon);
					$checkbox_inner.append($item_title);
					$label.append($checkbox_inner);
					$list_elem.append($label);
					$list_container.append($list_elem);
					$list_div.append($list_container);
					$card_content.append($list_div);

					$col_div.append($card_content);
					break;
				default:
					break;
			}
			output = `${output}${$col_div.prop('outerHTML')}`
		}

		return output;
	});
}
