import {toiletsDB} from '../modules/config';

function getPdi() {
	return new Promise((resolve, reject) => {
		toiletsDB.allDocs({
			include_docs: true
		}).then((response) => {
			return resolve(response.rows);
		}).catch((error) => {
			return reject(error);
		});
	});
}

function refactorPdi(pdi) {
	return new Promise((resolve, reject) => {
		// TOILETS
		if (Object.keys(pdi.toilets).length > 0) {
			// MALE
			var number = pdi.toilets.male.number;
			var unusable = pdi.toilets.male.unusable;
			pdi.toilets.male.elems = {};
			for (var i = 0; i < number; i++) {
				var id = `${pdi._id}-TM-00${i+1}`;
				pdi.toilets.male.elems[id] = {
					unusable: (i < unusable)? true:false
				};
			}
			// FEMALE
			var number = pdi.toilets.female.number;
			var unusable = pdi.toilets.female.unusable;
			pdi.toilets.female.elems = {};
			for (var i = 0; i < number; i++) {
				var id = `${pdi._id}-TF-00${i+1}`;
				pdi.toilets.female.elems[id] = {
					unusable: (i < unusable)? true:false
				};
			}
		}

		if(Object.keys(pdi.cabins).length > 0) {
			var number = pdi.cabins.number;
			pdi.cabins.elems = {};
			for (var i = 0; i < number; i++) {
				var id = `${pdi._id}-C-00${i+1}`;
				pdi.cabins.elems[id] = {
					unusable: false
				};
			}
		}

		// console.log(pdi);
		// return resolve('');
		toiletsDB.put(pdi).then((response) => {
			console.log(response);
			return resolve(response);
		}).catch((error) => {
			console.error(error);
			return reject(error);
		});

	});
}

export function refactorPdis(self) {
	self.$app.preloader.show();
	getPdi().then((pdis) => {
		console.log(pdis);
		var promises = [];
		for(var pdi of pdis) {
			promises.push(refactorPdi(pdi.doc));
		}
		Promise.all(promises).then((response) => {
			self.$app.preloader.hide();
			console.log("DONE");
		}).catch((error) => {
			self.$app.preloader.hide();
			console.error(error);
		});
	}).catch((error) => {
		self.$app.preloader.hide();
		console.error(error);
		return -1;
	});
}
