
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { saveMailNotificationsSettings } from '../js/utils/notifications/mail-notification-util';
import { savePushNotificationsSettings } from '../js/utils/notifications/push-notification-util';
export default {
  methods: {
    updateMail: function (e) {
      this.notification_doc.mail_notification.email = e.target.value;

      if (!this.notification_doc.mail_notification.email || this.notification_doc.mail_notification.email === '') {
        console.log(this.notification_doc);
        this.notification_doc.mail_notification.time = false;
        this.notification_doc.mail_notification.gps = false;
      }

      this.$setState();
    },
    saveMailChanges: function (e) {
      saveMailNotificationsSettings(this);
    },
    savePushChanges: function (e) {
      savePushNotificationsSettings(this);
    }
  },
  on: {
    pageInit: function (page) {}
  },
  id: '61eacb7c70',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="notification-page">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n\n				<!-- Left -->\n				<div class="left">\n					<a href="#" class="link icon-only panel-open navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">Gestione Notifiche</div>\n				</div>\n\n				<div class="right">\n					<a href="#" class="link icon-only" @click="window.print()">\n						<i class="icon f7-icons tooltip-init"\n							 data-tooltip="Stampa pagina web">\n							printer_fill\n						</i>\n					</a>\n				</div>\n			</div>\n		</div>\n\n		<!-- Page Content -->\n		<div class="page-content">\n			<!-- EMAIL NOTIFICATION SETTINGS -->\n			<div class="block-title">Notifiche e-mail</div>\n			<div class="list inset inline-labels">\n				<ul>\n					<li class="item-content item-input">\n						<div class="item-media">\n							<i class="icon f7-icons text-color-lightblue">envelope</i>\n						</div>\n						<div class="item-inner">\n							<div class="item-title item-label">Inserisci l\'indirizzo mail</div>\n							<div class="item-input-wrap">\n								<input type="text" placeholder="Inserisci l\'indirizzo mail..." value="';
      r += c(ctx_1.notification_doc.mail_notification.email, ctx_1);
      r += '" @change="updateMail" >\n							</div>\n						</div>\n					</li>\n					<li class="item-content item-input">\n						<div class="item-media">\n							<i class="icon f7-icons text-color-lightblue">bell</i>\n						</div>\n						<div class="item-inner">\n							<div class="item-title item-label">Imposta il tipo di notifiche che vuoi ricevere</div>\n						</div>\n					</li>\n					<li>\n						<label class="item-checkbox item-content">\n							<input type="checkbox" name="mail-notification-type" value="gps_anomalies" id="gps-anomalies-mail-checkbox"\n								';
      r += Template7Helpers.js_if.call(ctx_1, 'this.notification_doc.mail_notification.email && this.notification_doc.mail_notification.email !== ""', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.js_if.call(ctx_2, 'this.notification_doc.mail_notification.gps', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								checked\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n								disabled\n								';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n								/>\n							<i class="icon icon-checkbox"></i>\n							<div class="item-inner">\n								<div class="item-title">\n									Anomalie Posizione\n								</div>\n							</div>\n						</label>\n					</li>\n					<li>\n						<label class="item-checkbox item-content">\n							<input type="checkbox" name="mail-notification-type" value="time_anomalies" id="time-anomalies-mail-checkbox"\n								';
      r += Template7Helpers.js_if.call(ctx_1, 'this.notification_doc.mail_notification.email && this.notification_doc.mail_notification.email !== ""', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								';
          r += Template7Helpers.js_if.call(ctx_2, 'this.notification_doc.mail_notification.time', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								checked\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n								disabled\n								';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n								/>\n							<i class="icon icon-checkbox"></i>\n							<div class="item-inner">\n								<div class="item-title">\n									Anomalie Durata\n								</div>\n							</div>\n						</label>\n					</li>\n					<li>\n						<a class="list-button"  @click="saveMailChanges">\n							<div class="button">\n								Salva le Modifiche!\n							</div>\n						</a>\n					</li>\n				</ul>\n			</div>\n			\n			<!-- PUSH NOTIFICATION SETTINGS -->\n			<div class="block-title">Notifiche push</div>\n			';
      r += Template7Helpers.js_if.call(ctx_1, '"serviceWorker" in navigator', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list inset inline-labels">\n				<ul>\n					<li class="item-content item-input">\n						<div class="item-media">\n							<i class="icon f7-icons text-color-lightblue">bell</i>\n						</div>\n						<div class="item-inner">\n							<div class="item-title item-label">Imposta il tipo di notifiche che vuoi ricevere</div>\n						</div>\n					</li>\n					<li>\n						<label class="item-checkbox item-content">\n							<input type="checkbox" name="push-notification-type" value="gps_anomalies" id="gps-anomalies-push-checkbox"\n								';
          r += Template7Helpers.js_if.call(ctx_2, 'this.notification_doc.push_notification.gps', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								checked\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								/>\n							<i class="icon icon-checkbox"></i>\n							<div class="item-inner">\n								<div class="item-title">\n									Anomalie Posizione\n								</div>\n							</div>\n						</label>\n					</li>\n					<li>\n						<label class="item-checkbox item-content">\n							<input type="checkbox" name="push-notification-type" value="time_anomalies" id="time-anomalies-push-checkbox"\n								';
          r += Template7Helpers.js_if.call(ctx_2, 'this.notification_doc.push_notification.time', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n								checked\n								';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n								/>\n							<i class="icon icon-checkbox"></i>\n							<div class="item-inner">\n								<div class="item-title">\n									Anomalie Durata\n								</div>\n							</div>\n						</label>\n					</li>\n					<li>\n						<a class="list-button"  @click="savePushChanges">\n							<div class="button">\n								Salva le Modifiche!\n							</div>\n						</a>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="list inset inline-labels">\n				<ul>\n					<li class="item-content item-input">\n						<div class="item-media">\n							<i class="icon f7-icons text-color-lightblue">bell_slash</i>\n						</div>\n						<div class="item-inner">\n							<div class="item-title">\n								Il browser che stai utilizzando non supporta le notifiche push.\n							</div>\n						</div>\n					</li>\n				</ul>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n		</div>\n\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    