import {
	downloadImage,
	putCheck
} from '../modules/couchdb-checks-utils';
import {
	getBase64
} from '../modules/config'

export function startDownload(self) {
	if (self.new_org) {
		for(var attachment of self.images.pre) {
			downloadImage(self.check._id, attachment.caption);
		}
		for(var attachment of self.images.post) {
			downloadImage(self.check._id, attachment.caption);
		}
	} else {
		for(var attachment of Object.keys(self.images)) {
			downloadImage(self.check._id, attachment);
		}
	}
}

export function loadImagesUtil(e, self) {
	var $input = self.$(e.target)
	var photo_type = $input.attr("photo-type")
	var photos = $input.prop("files")
	if (photos.length > 0) {
		self.$app.dialog.confirm(`Sicuro di voler caricare queste foto ${photo_type} pulizia?<br><span class="text-color-red">Non sarà più possibile modificarle!</span>`, async () => {
			self.$app.preloader.show();
			var photo_count = 0;
			if (!self.check._attachments) {
				self.check._attachments = {};
			}
			var attach = self.check._attachments;

			for(var photo of photos) {
				var encoded_photo = await getBase64(photo);
				photo_count += 1;
				encoded_photo = encoded_photo.split('base64,')[1].trim();
				attach[`Foto ${photo_type.charAt(0).toUpperCase() + photo_type.slice(1)} Pulizia ${photo_count}`] = {
					content_type: photo.type,
					data: encoded_photo
				}
				photo_count +=1;
			}
			self.check.addImgCnt += photo_count;
			console.log(self.check);
			putCheck(self.check).then((response) => {
				self.$app.dialog.alert(`Immagini inserite.`, () => {
					self.$router.refreshPage();
				})
			}).catch((error) => {
				console.error(error);
				self.$app.dialog(`Errore durante il caricamento delle immagini`);
			}).finally(() => {
				self.$app.preloader.hide();
			});
		}, () => {
			// $input.prop('files', [])
		})
	}
}
