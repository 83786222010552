import {login} from '../modules/couchdb-auth-utils'

export function loginUtil(self) {
    if (!self.username && !self.password) {
        self.$$('#network-error-login').css('display', 'none');
        self.$$('#password-missing-message').css('display', 'block');
        self.$$('#username-missing-message').css('display', 'block');
        self.$$('#username-input-title').css('display', 'none');
        self.$$('#password-input-title').css('display', 'none');
        self.$$('#login-username-input').addClass('missing-input');
        self.$$('#login-password-input').addClass('missing-input');
        return;
    }
    if (!self.username) {
        self.$$('#network-error-login').css('display', 'none');
        self.$$('#password-missing-message').css('display', 'none');
        self.$$('#username-missing-message').css('display', 'block');
        self.$$('#username-input-title').css('display', 'none');
        self.$$('#password-input-title').css('display', 'block');
        self.$$('#login-password-input').removeClass('missing-input');
        self.$$('#login-username-input').addClass('missing-input');
        self.$$('#login-password-value').val('');
        self.password = undefined;
        return;
    }
    if (!self.password) {
        self.$$('#network-error-login').css('display', 'none');
        self.$$('#password-missing-message').css('display', 'block');
        self.$$('#username-missing-message').css('display', 'none');
        self.$$('#username-input-title').css('display', 'block');
        self.$$('#password-input-title').css('display', 'none');
        self.$$('#login-username-input').removeClass('missing-input');
        self.$$('#login-password-input').addClass('missing-input');
        return;
    }
    self.$$('#network-error-login').css('display', 'none');
    self.$$('#password-missing-message').css('display', 'none');
    self.$$('#username-missing-message').css('display', 'none');
    self.$$('#username-input-title').css('display', 'block');
    self.$$('#password-input-title').css('display', 'block');
    self.$$('#login-username-input').removeClass('missing-input');
    self.$$('#login-password-input').removeClass('missing-input');

    login(self);
}