
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { getAllChecks, pagination_limit } from '../../js/modules/couchdb-pagination-module';
import { openMapUtil } from '../../js/modules/map-utils';
import { deletePass, endPass } from '../../js/modules/couchdb-passes-utils';
import { setCheckDateFilter } from '../../js/utils/date-filter';
import { monthNames, monthNamesShort, dayNames, dayNamesShort, monthNumber } from '../../js/modules/config';
import { setResizeEvent } from '../../js/utils/resize-util';
import { setChecksInfiniteScrool } from '../../js/utils/pagination-util';
export default {
  methods: {
    openCheck(id) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation()) e.stopPropagation();
      this.$router.navigate('/check/' + id);
    },

    openToilet(id, type) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation()) e.stopPropagation();
      this.$router.navigate('/toilet/' + id + '/' + type, {
        reloadCurrent: true,
        ignoreCache: true
      });
    },

    openPass(id, type) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation()) e.stopPropagation();
      this.$router.navigate('/pass/' + id + '/' + type + '/', {
        reloadCurrent: true,
        ignoreCache: true
      });
    },

    openMap(lat, lon) {
      if (!e) {
        var e = window.event;
      }

      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      openMapUtil(lat, lon);
    },

    removePass(pass) {
      this.$app.dialog.confirm("Sei sicuro di voler eliminare il passaggio e <strong>tutti<strong/> i suoi controlli?<br><strong style='color:red;'>L'azione è irreversibile!<strong/>", () => {
        deletePass(this, pass);
      });
    },

    closePass(pass) {
      if (!pass.allowStop) {
        this.$app.dialog.alert('Eseguire un controllo prima di chiudere il passaggio!', () => {
          this.$router.refreshPage();
        });
        return;
      }

      this.$app.dialog.confirm("Sei sicuro di voler chiudere il passaggio?", () => {
        endPass(this, pass);
      });
    },

    loadMore(type) {
      // this.$('#'+type+'-checks-infinite-preloader').css('display', 'block');
      this.$('#tab-' + type + '-checks').trigger('infinite');
    },

    openCalendar() {
      var self = this;
      var calendar = this.$app.calendar.create({
        openIn: "customModal",
        backdrop: true,
        cssClass: 'theme-dark popover-init',
        monthNames: monthNames,
        monthNamesShort: monthNamesShort,
        dayNames: dayNames,
        dayNamesShort: dayNamesShort,
        rangePicker: true,
        rangePickerMaxDays: 31,
        maxDate: new Date(),
        minDate: new Date(2021, 0, 18),
        on: {
          closed: function (calendar) {
            if (!calendar.value) {
              return;
            }

            var start = calendar.value[0];
            var end = calendar.value[1];

            if (!end) {
              end = new Date(start);
            }

            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);
            self.$app.dialog.confirm(`Impostare il filtro dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()}?`, () => {
              setCheckDateFilter(start, end, self);
            });
          }
        }
      });
      calendar.open();
    }

  },
  on: {
    pageInit: function (page) {
      var self = this; // Resize event

      setResizeEvent(self); // Set visible tab

      if (!this.backVisible) {
        this.$app.tab.show('#tab-toilets-checks', '#link-tab-toilets-checks');
      } else {
        this.$app.tab.show('#tab-' + this.type + '-checks', '#link-tab-' + this.type + '-checks');
      } // Set searchbars


      for (var pdi_type of self.pdi_pass_types) {
        for (var pass_type of pdi_type.pass_types) {
          // Searchbars init
          this.$app.searchbar.create({
            el: `.search-${pass_type.name}-checks`,
            searchContainer: `.searchbar-found-${pass_type.name}-checks`,
            notFoundEl: `.searchbar-not-found-${pass_type.name}-checks`,
            searchIn: [`.item-search-${pass_type.name}-checks`]
          });
        }
      } // SET infinite scroll


      if (self.backVisible) {
        this.$('.checks-scroll-preloader').css('display', 'none');
      } else {
        setChecksInfiniteScrool(self);
      }
    }
  },
  id: '48754062a4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="checks-page">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n\n				<!-- Left -->\n				<div class="left">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.backVisible, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only back navbar-icon-link" data-ignore-cache="true" data-force="true">\n						<i class="icon f7-icons">arrow_left</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">Controlli su Passaggio del ';
          r += c(ctx_2.pass.name, ctx_2);
          r += '</div>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only panel-open navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">Lista Controlli ';
          r += c(ctx_2.date_range_title, ctx_2);
          r += '</div>\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n				</div>\n\n				<!-- Rigth -->\n				<div class="right">\n					';
      r += Template7Helpers.js_if.call(ctx_1, '!this.backVisible', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="icon-only link" @click="openCalendar()">\n						<i class="f7-icons icon text-color-lightblue tooltip-init">\n							calendar\n						</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					';
      r += Template7Helpers.js_if.call(ctx_1, '@root.backVisible && @root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only" @click="removePass(this.pass)">\n						<i class="icon f7-icons tooltip-init"\n						   data-tooltip="Elimina passaggio">\n							minus_circle_fill\n						</i>\n					</a>\n					';
          r += Template7Helpers.js_if.call(ctx_2, '!this.pass.time.end', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n					<a href="#" class="link icon-only" @click="closePass(this.pass)">\n						<i class="icon f7-icons tooltip-init"\n						   data-tooltip="Chiudi passaggio">\n							lock_circle_fill\n						</i>\n					</a>\n					';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only" @click="window.print()">\n						<i class="icon f7-icons tooltip-init"\n							 data-tooltip="Stampa pagina web">\n							printer_fill\n						</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<!-- End navbar -->\n\n		<!-- Tab switch -->\n		<div class="toolbar toolbar-top tabbar">\n			<div class="toolbar-inner">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<a href="#tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks" class="tab-link" id="link-tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks">\n					';
              r += Template7Helpers.js_if.call(ctx_3, 'window.innerWidth <= 800 && this.hasOwnProperty("title_short")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title_short', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				</a>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<!-- END TABS SWITCH -->\n\n		<!-- TABS -->\n		<div class="tabs-animated-wrap">\n			<div class="tabs">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="page-content tab infinite-scroll-content" id="tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks">\n					<!-- Searchbar -->\n					<div class="searchbar-div">\n						<form class="searchbar search-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks">\n							<div class="searchbar-inner">\n								<div class="searchbar-input-wrap">\n									<input type="search" placeholder="Cerca Controlli ';
              r += c(ctx_3.title, ctx_3);
              r += '...">\n									<i class="searchbar-icon"></i>\n									<span class="input-clear-button"></span>\n								</div>\n								<span class="searchbar-disable-button if-not-aurora">Annulla</span>\n							</div>\n						</form>\n					</div>\n					<!-- End Searchbar -->\n					<!-- searchbar backdrop -->\n					<div class="searchbar-backdrop"></div>\n					<!-- LIST -->\n					<div class="list media-list searchbar-found-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks searchbar-found-div">\n						<ul>\n							';
              r += Template7Helpers.each.call(ctx_3, ctx_3.elems, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<li>\n								<a href="#" class="item-link item-content" @click="openCheck(\'';
                  r += c(ctx_4._id, ctx_4);
                  r += '\')">\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks">\n												Controllo su Passaggio del\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks text-color-lightblue" @click="openPass(\'';
                  r += c(ctx_4.pass.id, ctx_4);
                  r += '\',\'';
                  r += c(ctx_3.name, ctx_4);
                  r += '\')">\n													';
                  r += c(ctx_4.pass.name, ctx_4);
                  r += '\n												</span>\n											</div>\n											<div class="item-after">\n												<!--<i>  </i> TODO: We need icon here? -->\n											</div>\n										</div>\n										<div class="item-subtitle">\n											<div>\n												Punto di Interesse:\n												<span @click="openToilet(\'';
                  r += c(ctx_4.toilet.id, ctx_4);
                  r += '\',\'';
                  r += c(ctx_3.name, ctx_4);
                  r += '\')">\n													<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks text-color-lightblue">\n														';
                  r += Template7Helpers.js.call(ctx_4, 'this.toilet.id', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' - ';
                  r += Template7Helpers.js.call(ctx_4, 'this.toilet.name', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n													</span>\n												</span>\n											</div>\n											';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.toilet.elem_id && this.toilet.elem_id !== "square"', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n												<div>\n													Elemento di Manutenzione\n													<span class="text-color-lightblue item-search-';
                      r += c(ctx_3.name, ctx_5);
                      r += '-checks">\n														';
                      r += Template7Helpers.js.call(ctx_5, 'this.toilet.elem_id', {
                        hash: {},
                        data: data_5 || {},
                        fn: function empty() {
                          return '';
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '\n													</span>\n												</div>\n											';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n											<div class="item-text text-color-lightblue">\n												<i class="icons f7-icons tooltip-init"\n												   @click="openMap(\'';
                  r += c(ctx_4.gps.lat, ctx_4);
                  r += '\',\'';
                  r += c(ctx_4.gps.lon, ctx_4);
                  r += '\')"\n												   data-tooltip="Apri Mappa" >\n													map_pin_ellipse\n												</i>\n											</div>\n											<div class="item-text">\n												Effettuato da\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks">\n												';
                  r += c(ctx_4.user.firstname, ctx_4);
                  r += '\n											</span>\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks">\n												';
                  r += c(ctx_4.user.familyname, ctx_4);
                  r += '\n											</span>\n												il\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-checks">\n													';
                  r += c(ctx_4.date_time, ctx_4);
                  r += '\n												</span>\n											</div>\n										</div>\n									</div>\n								</a>\n							</li>\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						</ul>\n					</div>\n\n					<!-- Searchbar not found-->\n					<div class="list media-list searchbar-not-found searchbar-not-found-';
              r += c(ctx_3.name, ctx_3);
              r += '-checks">\n						<ul>\n							<li>\n								<a href="#" class="item-content">\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title text-color-yellow">\n												Nessun Controllo ';
              r += c(ctx_3.title, ctx_3);
              r += ' trovato\n											</div>\n										</div>\n									</div>\n								</a>\n							</li>\n						</ul>\n					</div>\n\n					<!-- Infinite scrool preloader -->\n					<div class="preloader infinite-scroll-preloader checks-scroll-preloader" id="';
              r += c(ctx_3.name, ctx_3);
              r += '-checks-infinite-preloader"></div>\n\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    