import {app} from '../app';
import {passesDB, checksDB} from './config';

export function deletePass(self, pass) {
	self.$app.preloader.show();

	checksDB.find({
		selector: {
			"pass.id": pass._id
		},
		sort: [
			{
				"_id": "desc"
			}
		],
		use_index: 'pass-id-index'
	}).then((response) => {
		// REMOVE CHECKS
		var checks = response.docs;
		checks.forEach((check) => {
			checksDB.remove(check).catch((error) => {
				console.log('ERROR IN DELETING CHECK');
			});
		});

		// REMOVE PASS
		passesDB.remove(pass, (err, res) => {
			if(err) {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Qualcosa è andato storto! Riprova più tardi', () => {
					self.$router.refreshPage();
				});
				return;
			}
			self.$app.preloader.hide();
			self.$app.dialog.alert('Passaggio e relativi controlli eliminati!', () => {
				self.$router.back({
					ignoreCache: true,
					force: true
				});
			});
			return;
		});
	}).catch((error) => {
		console.log(error);
		self.$app.preloader.hide();
		self.$app.dialog.alert('Qualcosa è andato storto! Riprova più tardi', () => {
			self.$router.refreshPage();
		});
		return;
	});
}

export function endPass(self, pass) {
	self.$app.preloader.show();

	var now = new Date();
	pass.time.end = now.getDate()+'/'+now.getMonth()+'/'+now.getFullYear()+' - '+now.getHours()+':'+now.getMinutes()+':'+now.getSeconds();
	pass.gps.end = JSON.parse(JSON.stringify(pass.gps.start));


	passesDB.put(pass, (error, response) => {
		if(error) {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Qualcosa è andato storto! Riprova più tardi', () => {
				self.$router.refreshPage();
			});
			return;
		}
		self.$app.preloader.hide();
		self.$app.dialog.alert('Passaggio chiuso!', () => {
			self.$router.refreshPage({ignoreCache: true});
		});
		return;
	});


}

export function putPass(pass) {
	return passesDB.put(pass);
}

export function getPass(passID, callback = undefined) {
	if (callback) {
		passesDB.get(passID, (err, res) => {
			if(err) {
				return callback(err, undefined);
			}
			return callback(undefined, res);
		});
	} else {
		return passesDB.get(passID);
	}
}
