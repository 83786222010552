/*
 *	COUCHDB UTILS
 */

import PouchDB from 'pouchdb-browser';
import PouchDBFind from 'pouchdb-find';
import PouchDBAuth from 'pouchdb-authentication';

PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBAuth);

const couch_address = 'https://gestioneintegrataservizi.it:3394/';
// const couch_address = 'https://192.168.118.101:3394/';

export var remoteDBroot = new PouchDB(couch_address, {skip_setup:true});
export var checksDB = new PouchDB(couch_address+'bagni-check', {skip_setup: true});
export var passesDB = new PouchDB(couch_address+'bagni-pass', {skip_setup: true});
export var toiletsDB = new PouchDB(couch_address+'bagni-list', {skip_setup:true});
export var userDB = new PouchDB(couch_address, {skip_setup:true});
export var thresholdsDB = new PouchDB(`${couch_address}cleo-thresholds`, {skip_setup: true});
export var anomaliesDB = new PouchDB(`${couch_address}cleo-anomalies`, {skip_setup: true});
export var notificationDB = new PouchDB(`${couch_address}cleo-push-notification`, {skip_setup: true});

/*
 *	BACKEND UTILS
 */

export const api_url = 'https://gestioneintegrataservizi.it:3393/';
// export const api_url = 'http://192.168.118.101:3030/';



/*
 *	CALENDAR UTILS
 */

export const monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
export const monthNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export const monthNamesShort = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
export const dayNames = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
export const dayNamesShort = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];

export const pdi_types = ['toilets', 'cabins', 'square'];
export const pass_types = ['toilets', 'sanitization', 'cabins', 'square'];

export const pdi_pass_types = [
	{
		pdi_type: 'toilets',
		pass_types: [
			{
				name: 'toilets',
				title: "Bagni",
				frequency: 'day'
			},
			{
				name: 'sanitization',
				title: 'Sanificazione Bagni',
				title_short: 'San. Bagni',
				frequency: 'week'
			}
		]
	},
	{
		pdi_type: 'cabins',
		pass_types: [
			{
				name: 'cabins',
				title: "Cabine",
				frequency: 'day'
			},
			// {
			// 	name: 'tettoie',
			// 	title: 'Tettoie',
			// 	frequency: 'week'
			// }
		]
	},
	{
		pdi_type: 'square',
		pass_types: [
			{
				name:'square',
				title: 'Piazzali',
				frequency: 'week'
			}
		]
	}
];


export function getBase64(file) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			resolve(reader.result);
		};
		reader.onerror = function (error) {
			reject(error);
		};
	})
}
