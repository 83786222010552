import {
	monthNumber,
	getBase64
} from '../modules/config'

import {
	getPass,
	putPass
} from '../modules/couchdb-passes-utils';

import {
	getCheck,
	putChecks
} from '../modules/couchdb-checks-utils';

export function getPassBlankDoc() {
	return {
		_id: '',
		name: "",
		toilet: {
			type: []
		},
		user: {},
		time: {
			start: null,
			end: null
		},
		gps: {
			start: {
				gps_lat: null,
				gps_lon: null,
				gps_acc: -1
			},
			end: {
				gps_lat: null,
				gps_lon: null,
				gps_acc: -1
			},
		},
		allowStop: true
	}
}

export function getCheckBlankDoc() {
	return {
		_id: '',
		user: {},
		pass: {},
		toilet: {},
		date_time: '',
		gps: {
			lat: null,
			lon: null,
			acc: -1
		},
		notes: ''
	}
}

function changePassType(pass_type, pdi_type, self) {
	var $input = self.$(`input[name='pdi'][value=${self.pass.toilet.id}]`)
	$input.prop('checked', false)
	$input.prop("defaultChecked", false)
	var selected_user = self.pass.user.id;
	if (selected_user) {
		selected_user = selected_user.split(':')[1]
		$input = self.$(`input[name='users'][value='${selected_user}']`)
		$input.prop('checked', false)
		$input.prop("defaultChecked", false)
	}
	self.check_blank = getCheckBlankDoc();
	self.checks = [];
	self.pass = self.blank_pass;
	self.pass.toilet.type = [pass_type];
	self.pass.toilet.id = null;
	self.selected_pass_type = pass_type;
	self.selected_pdi_type = pdi_type;
	self.pdis = self.pdi_list.filter(pdi => Object.keys(pdi[pdi_type]).length > 0)
	self.users = self.users_list.filter(user => user.opType.includes(pass_type))
	self.$setState();
}

export function selectPassType(e, self) {
	var $elem = self.$(e.target);
	const pass_type = $elem.val();
	const pdi_type = $elem.attr("pdi-type")
	if (self.pass.toilet.type.length > 0) {
		self.$app.dialog.confirm("Cambiare il tipo di passaggio resetta l'inserimento. Sicuro di voler continuare?", () => {
			changePassType(pass_type, pdi_type, self);
		}, () => {
			var $input = self.$(`input[name='pass-type'][value=${self.selected_pass_type}]`)
			$input.prop('checked', true)
			$input.prop("defaultChecked", true)
		})
	} else {
		changePassType(pass_type, pdi_type, self);
	}
}

function changePdi(pdi_id, self) {
	// Get pdi
	const pdi = self.pdis.find(pdi => pdi._id === pdi_id);
	// SET TOILET INFO IN PASS
	self.pass.toilet.id = pdi._id;
	self.pass.toilet.name = pdi.name;
	let gps_doc = {
		gps_lat: pdi.gps.lat,
		gps_lon: pdi.gps.lon,
		gps_acc: -1
	};
	self.pass.gps.start = gps_doc;
	self.pass.gps.end = gps_doc;
	// SET TOILET INFO IN CHECK BLANK
	var check = self.check_blank;
	check.toilet = JSON.parse(JSON.stringify(self.pass.toilet));
	check.gps = {
		lat: pdi.gps.lat,
		lon: pdi.gps.lon,
		acc: -1
	}

	// GET NUMBER OF CHECKS
	const elems_info = pdi[self.selected_pdi_type];
	self.checks = [];
	var checks = self.checks;
	switch (self.selected_pdi_type) {
		case 'square':
			var check = JSON.parse(JSON.stringify(self.check_blank));
			check.toilet.elem_id = 'square';
			check.toilet.elem_unusable = false;
			checks.push(check)
			break;
		case 'toilets':
			for(var elem_id of Object.keys(elems_info.female.elems)) {
				var check = JSON.parse(JSON.stringify(self.check_blank));
				check.toilet.elem_id = elem_id;
				check.toilet.elem_unusable = elems_info.female.elems[elem_id].unusable;
				check.toilet.gender = 'female';
				checks.push(check)
			}
			for(var elem_id of Object.keys(elems_info.male.elems)) {
				var check = JSON.parse(JSON.stringify(self.check_blank));
				check.toilet.elem_id = elem_id;
				check.toilet.elem_unusable = elems_info.male.elems[elem_id].unusable;
				check.toilet.gender = 'male';
				checks.push(check)
			}
			break;
		case 'cabins':
			for(var elem_id of Object.keys(elems_info.elems)) {
				var check = JSON.parse(JSON.stringify(self.check_blank));
				check.toilet.elem_id = elem_id;
				check.toilet.elem_unusable = elems_info.elems[elem_id].unusable;
				checks.push(check)
			}
			break;
		default:
			break;
	}
	self.$setState(self.checks);
}

export function selectPdiUtil(e, self) {
	var $elem = self.$(e.target);
	const pdi_id = $elem.val();
	if (self.pass.toilet.id) {
		self.$app.dialog.confirm("Cambiare il pdi resetta l'inserimento. Sicuro di voler continuare?", () => {
			changePdi(pdi_id, self);
		}, () => {
			var $input = self.$(`input[name='pdi'][value=${self.pass.toilet.id}]`)
			$input.prop('checked', true)
			$input.prop("defaultChecked", true)
		});
	} else {
		changePdi(pdi_id, self);
	}
}

export function selectUserUtil(e, self) {
	var $elem = self.$(e.target);
	const username = $elem.val();
	const user = self.users.find(user => user.name === username);
	var user_obj = {
		id: `org.couchdb.user:${username}`,
		firstname: user.firstname,
		familyname: user.familyname
	}
	self.pass.user = user_obj;
	self.check_blank.user = user_obj;
	for(var check of self.checks) {
		check.user = user_obj
	}
}

export function selectPassDateUtil(e, self) {
	var $input = self.$(e.target);
	const date = new Date($input.val())
	self.pass_date_time.date = date;
}

export function selectPassTimeUtil(e, self) {
	var $input = self.$(e.target);
	const time = $input.val();
	const time_type = $input.attr('time-key')
	self.pass_date_time[time_type] = time
}

export function selectCheckTimeUtil(e, self) {
	var $input = self.$(e.target);
	const time = $input.val();
	const check_elem = $input.attr("check-elem");
	var check = self.checks.find(check => check.toilet.elem_id === check_elem)
	check.selected_time = time;
}

export function missingInput(self) {
	// CHECK PASS DATE AND TIME
	const pass_date_time = self.pass_date_time;
	if (!pass_date_time || !pass_date_time.date || !pass_date_time.start || !pass_date_time.end	) {
		return true
	}

	// CHECK PASS TYPE
	if (!self.selected_pass_type) {
		return true
	}
	// CHECK USER
	if (!self.pass.user || !self.pass.user.id || !self.pass.user.firstname || !self.pass.user.familyname ) {
		return true
	}
	// CHECK PDI
	if (!self.pass.toilet || !self.pass.toilet.id) {
		return true
	}

	// CHECK CHECKS
	for(var check of self.checks) {
		const elem_id = check.toilet.elem_id
		var $time_input = self.$(`input[name='check-time-${elem_id}']`);
		const time_value = $time_input.val();
		if (!time_value || time_value.length === 0) {
			return true;
		}
	}

	return false
}

function getTimeString(date) {
	function getNumberString(value) {
		return (value < 10)? `0${value}`:value;
	}
	return `${getNumberString(date.getDate())}/${monthNumber[date.getMonth()]}/${date.getFullYear()} - ${getNumberString(date.getHours())}:${getNumberString(date.getMinutes())}:${getNumberString(date.getSeconds())}`;
}

function getPassId(start_date) {
	return new Promise((resolve, reject) => {
		var id = start_date.toISOString();
		getPass(id).then((pass) => {
			start_date.setMilliseconds(start_date.getMilliseconds() + 1)
			getPassId(start_date).then((id) => {
				resolve(id)
			}).catch((error) => {
				reject(error)
			})
		}).catch((error) => {
			switch (error.status) {
				case 404:
					return resolve(id);
					break;
				default:
					return reject(error);
					break;
			}
		})
	});
}

function getCheckId(date) {
	return new Promise((resolve, reject) => {
		var id = date.toISOString();
		getCheck(id).then((check) => {
			// console.log(check);
			date.setMilliseconds(date.getMilliseconds() + 1)
			getCheckId(date).then((ide) => {
				resolve(id)
			}).catch((error) => {
				reject(error)
			})
		}).catch((error) => {
			switch (error.status) {
				case 404:
					return resolve(id);
					break;
				default:
					return reject(error);
					break;
			}
		})
	});
}

function createPassDoc(self) {
	return new Promise((resolve, reject) => {
		var pass = JSON.parse(JSON.stringify(self.pass))

		/*
		 *	GET START AND END DATE
		 */
		const pass_date_time = self.pass_date_time;
		// Start Date
		var start_date = new Date(pass_date_time.date);
		start_date.setHours(pass_date_time.start.split(':')[0])
		start_date.setMinutes(pass_date_time.start.split(':')[1])
		pass.time.start = getTimeString(start_date);
		// End Date
		var end_date = new Date(pass_date_time.date);
		end_date.setHours(pass_date_time.end.split(':')[0])
		end_date.setMinutes(pass_date_time.end.split(':')[1])
		pass.time.end = getTimeString(end_date);
		// Pass Name
		pass.name = pass.time.start;
		// Set insert manual flag
		pass.manual_insert = true;

		getPassId(start_date).then((id) => {
			pass._id = id;
			resolve(pass);
		}).catch((error) => {
			reject(error);
		})
	})
}

function createCheckDoc(check, pass, self) {
	return new Promise(async (resolve, reject) => {
		var pass_obj = {
			id: pass._id,
			name: pass.name
		};
		check.pass= pass_obj;
		const elem_id = check.toilet.elem_id;
		// set date_time field
		var $time_input = self.$(`input[name='check-time-${elem_id}']`);
		const value = $time_input.val();
		var date = new Date(pass._id);
		date.setHours(value.split(':')[0]);
		date.setMinutes(value.split(':')[1]);
		date.setSeconds(0);
		check.date_time = getTimeString(date);

		/*
		 * Set attachments
		 */
		var attach = {};
		// PRE PHOTOS
		var $pre_input = self.$(`input[name='check-pre-photo-${elem_id}']`);
		var pre_photos = $pre_input.prop('files');
		var pre_photo_count = 1;
		var photo_count = 0;
		for(var photo of pre_photos) {
			var encoded_photo = await getBase64(photo);
			encoded_photo = encoded_photo.split('base64,')[1].trim();

			attach[`Foto Pre Pulizia ${pre_photo_count}`] = {
				content_type: photo.type,
				data: encoded_photo
			}
			pre_photo_count += 1;
			photo_count +=1;
		}
		// POST PHOTOS
		var $post_input = self.$(`input[name='check-post-photo-${elem_id}']`);
		var post_photos = $post_input.prop('files');
		var post_photo_count = 1;
		for(var photo of post_photos) {
			var encoded_photo = await getBase64(photo);
			attach[`Foto Post Pulizia ${post_photo_count}`] = {
				content_type: photo.type,
				data: encoded_photo
			}
			post_photo_count += 1;
			photo_count +=1;
		}

		// Add attachements obj to check
		if (photo_count > 0) {
			check._attachments = attach;

		}
		check.addImgCnt = photo_count;
		// Set manula insert flag
		check.manual_insert = true;
		getCheckId(date).then((id) => {
			check._id = id;
			resolve(check);
		}).catch((error) => {
			reject(error)
		})
	});
}

function createChecksDoc(pass, self) {
	return new Promise((resolve, reject) => {
		var promises = [];
		for(var check of self.checks) {
			promises.push(
				createCheckDoc(check, pass, self)
			)
		}
		Promise.all(promises).then((checks) => {
			resolve(checks);
		}).catch((error) => {
			reject(error)
		})
	})
}

export function savePassUtil(self) {
	createPassDoc(self).then((pass) => {
		createChecksDoc(pass, self).then((checks) => {
			Promise.all([
				putPass(pass),
				putChecks(checks)
			]).then((response) => {
				self.$app.dialog.confirm(`Passaggio Aggiunto. Stampare riepilogo?`, () => {
					setTimeout(() => {
						window.print()
						self.$router.navigate('/');
					}, 100);
				}, () => {
					self.$router.navigate('/');
				});
			}).catch((error) => {
				console.error(error);
				self.$app.dialog.alert(`Errore durante l'aggiunta del passaggio`);
			}).finally(() => {
				self.$app.preloader.hide();
			})
		}).catch((error) => {
			console.error(error);
			self.$app.dialog.alert(`Errore durante l'aggiunta del passaggio`);
		}).finally(() => {
		});
	}).catch((error) => {
		console.error(error);
		self.$app.dialog.alert(`Errore durante l'aggiunta del passaggio`);
	}).finally(() => {
	})
}
