import {saveThresholdsDoc} from '../modules/couchdb-thresholds-module';

export function saveThresholdsUtils(self) {
	console.log(self.thresholds);
	self.$app.dialog.confirm('Sicuro di voler cambiare le soglie per le anomalie?', () => {
		self.$app.preloader.show();
		saveThresholdsDoc(self.thresholds, (error, response) => {
			if (error) {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Qualcosa è andato storto!', () => {
					self.$router.refreshPage();
				});
			} else {
				self.$app.preloader.hide();
				self.$app.dialog.alert('Modifica effettuata con successo!', () => {
					self.$router.refreshPage();
				});
			}
		});
	});
}
