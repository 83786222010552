
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { resolveAnomalyConfirmMsg, resolveTimeAnomalyUtil } from '../js/utils/anomalies-util';
import { openDirectionMap, openMapUtil } from '../js/modules/map-utils';
export default {
  methods: {
    openDirMap: function (mode) {
      var lat1 = this.anomaly.pdi.gps.lat;
      var lon1 = this.anomaly.pdi.gps.lon;
      var lat2, lon2;

      switch (mode) {
        case 'start':
          lat2 = this.anomaly.pass.gps.start.gps_lat;
          lon2 = this.anomaly.pass.gps.start.gps_lon;
          break;

        case 'end':
          lat2 = this.anomaly.pass.gps.end.gps_lat;
          lon2 = this.anomaly.pass.gps.end.gps_lon;
          break;

        default:
          return;
      }

      openDirectionMap(lat1, lon1, lat2, lon2);
    },
    openMap: function () {
      openMapUtil(this.anomaly.pdi.gps.lat, this.anomaly.pdi.gps.lon);
    },
    openPass: function () {
      this.$router.navigate(`/pass/${this.anomaly.pass._id}/${this.anomaly.pass.toilet.type[0]}`);
    },
    resolveAnomalyClick: function (mode) {
      resolveAnomalyConfirmMsg(mode, this);
    },
    resolveTimeAnomalyClick: function (e) {
      resolveTimeAnomalyUtil(this);
    }
  },
  on: {
    pageInit: function (page) {}
  },
  id: '759bce0329',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=anomalies-page><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only back navbar-icon-link" data-ignore-cache=true data-force=true><i class="icon f7-icons">arrow_left</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Anomalia ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomaly.type === "gps"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' GPS ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomaly.type === "time"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' Durata ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' su Passaggio del ';
      r += Template7Helpers.js.call(ctx_1, 'this.anomaly.pass.time.start', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' (';
      r += Template7Helpers.js.call(ctx_1, 'this.anomaly.pdi.name', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ')</div></div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><div class=page-content><!-- GPS ANOMALY --> ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomaly.type ==="gps"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Riepilogo --><div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">square_favorites</i></div><div class=demo-facebook-name>Riepilogo Anomalia</div></div><div class=card-content><div class=list><ul><li class=item-content><div class="item-inner item-row"><div class=item-cell><strong>Operatore</strong></div><div class=item-cell><strong>Data/Ora</strong></div><div class=item-cell><strong>Punto di Interesse</strong></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.id.split(":")[1]', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.firstname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.familyname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>Inizio: <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.time.start', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span><br>Fine: <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.time.end', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi._id', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span><br>Tipologia Passaggio: <span class=text-color-yellow>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.pass.toilet.type.includes("toilets")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Bagni e Cabine ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Bagni ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("sanitization")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("sanitization")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Sanificazione Bagno ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Cabine ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Piazzali ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.pass.toilet.type.includes("cabins")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div></div></li><li class=item-content><div class=item-inner><div class=item-title><strong>Info Posizione</strong></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Indirizzo Punto di Interesse</div><div class=item-cell><span class="">';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi.address', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Posizione di partenza registrata nel passaggio</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.gps_start', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-red>';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.pass.start_address', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-green>';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.pass.start_address', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>Accuratezza GPS: ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.bad_accuracy_start', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-red>';
              r += Template7Helpers.js.call(ctx_3, 'Number(this.anomaly.pass.gps.start.gps_acc).toFixed(0)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-green>';
              r += Template7Helpers.js.call(ctx_3, 'Number(this.anomaly.pass.gps.start.gps_acc).toFixed(0)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' metri</div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Posizione finale registrata nel passaggio</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.gps_end', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-red>';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.pass.end_address', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-green>';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.pass.end_address', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>Accuratezza GPS: ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.bad_accuracy_end', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-red>';
              r += Template7Helpers.js.call(ctx_3, 'Number(this.anomaly.pass.gps.end.gps_acc).toFixed(0)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class=text-color-green>';
              r += Template7Helpers.js.call(ctx_3, 'Number(this.anomaly.pass.gps.end.gps_acc).toFixed(0)', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' metri</div></div></li><li><a href=# class="item-content item-link" @click=openPass><div class=item-inner><div class=item-title-row><div class=item-title>Visualizza i controlli relativi al passaggio</div></div><div class=item-subtitle></div></div></a></li></ul></div></div></div><!-- Diagnosi --><div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="fas fa-stethoscope text-color-yellow font-awesome-icons"></i></div><div class=demo-facebook-name>Diagnosi</div></div><div class=card-content><div class="list media-list"><ul>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.gps_start', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=item-content><div class=item-inner><div class=item-title-row><div class=item-title>Errore nella posizione di partenza</div><div class=item-after><a href=# class="icon-only tooltip-init" data-tooltip="Mostra nella mappa" @click="openDirMap(\'start\')"><i class="icon f7-icons">map</i></a></div></div><div class=item-subtitle>Distanza dal punto di interesse: <span class=text-color-red>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.start_distance > 1000', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'Number(this.anomaly.start_distance/1000).toFixed(0)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' Km ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'Number(this.anomaly.start_distance).toFixed(0)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' metri ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Massima consentita: ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.thresholds.gps_distance', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' metri)</div>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.bad_accuracy_start', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-text>L\'errore è probabilmente dovuto ad una scarsa accuratezza del modulo GPS del dispositivo utilizzato.</div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.gps_end', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=item-content><div class=item-inner><div class=item-title-row><div class=item-title>Errore nella posizione finale</div><div class=item-after><a href=# class="icon-only tooltip-init" data-tooltip="Mostra nella mappa" @click="openDirMap(\'end\')"><i class="icon f7-icons">map</i></a></div></div><div class=item-subtitle>Distanza dal punto di interesse: <span class=text-color-red>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.end_distance > 1000', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'Number(this.anomaly.end_distance/1000).toFixed(0)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' Km ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'Number(this.anomaly.end_distance).toFixed(0)', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' metri ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Massima consentita: ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.thresholds.gps_distance', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' metri)</div>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.bad_accuracy_end', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=item-text>L\'errore è probabilmente dovuto ad una scarsa accuratezza del modulo GPS del dispositivo utilizzato.</div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div>';
          r += Template7Helpers.js_if.call(ctx_2, '!this.anomaly.resolved', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, '@root.$app.data.user.role==="Admin"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Risoluzione --><div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">lightbulb</i></div><div class=demo-facebook-name>Risoluzione</div></div><div class=card-content><div class="list media-list"><ul>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.anomaly.gps_start && this.anomaly.gps_end', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><a href=# class="item-link item-content" @click="resolveAnomalyClick(\'both\')"><div class=item-inner><div class=item-title-row><div class=item-title>Correggi le posizioni <span class=text-color-yellow>di partenza</span> e <span class=text-color-yellow>finale</span></div></div><div class=item-subtitle>La posizione del passaggio (sia di partenza che finale) sarà corretta automaticamente con la posizione del punto di interesse relativo</div></div></a></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.anomaly.gps_start', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><a href=# class="item-link item-content" @click="resolveAnomalyClick(\'start\')"><div class=item-inner><div class=item-title-row><div class=item-title>Correggi la posizione <span class=text-color-yellow>di partenza</span> ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.anomaly.gps_end', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ed <strong>ignora</strong> l\'anomalia sulla posizione finale ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div></div><div class=item-subtitle>La posizione di partenza del passaggio sarà corretta automaticamente con la posizione del punto di interesse relativo</div></div></a></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.anomaly.gps_end', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<li><a href=# class="item-link item-content" @click="resolveAnomalyClick(\'end\')"><div class=item-inner><div class=item-title-row><div class=item-title>Correggi la posizione <span class=text-color-yellow>finale</span> ';
                      r += Template7Helpers.js_if.call(ctx_5, 'this.anomaly.gps_start', {
                        hash: {},
                        data: data_5 || {},
                        fn: function (ctx_6, data_6) {
                          var r = '';
                          r += ' ed <strong>ignora</strong> l\'anomalia sulla posizione di partenza ';
                          return r;
                        },
                        inverse: function empty() {
                          return '';
                        },
                        root: root,
                        parents: [ctx_4, ctx_3, ctx_2, ctx_1]
                      });
                      r += '</div></div><div class=item-subtitle>La posizione finale del passaggio sarà corretta automaticamente con la posizione del punto di interesse relativo</div></div></a></li>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '<li><a href=# class="item-link item-content" @click="resolveAnomalyClick(\'ignore\')"><div class=item-inner><div class=item-title-row><div class=item-title>Ignora l\'anomalia</div></div><div class=item-subtitle>Ignora l\'anomalia ed eliminala dalla lista.</div></div></a></li></ul></div></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "start"', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "end"', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "both"', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "ignore"', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">lightbulb</i></div><div class=demo-facebook-name>Modalità di risoluzione</div></div><div class=card-content><div class="list media-list"><ul><li class=item-content><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "start"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Posizione di partenza modificata ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "end"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Posizione finale modificata ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "both"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Posizione di partenza e finale modificata ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.resolve_mode === "ignore"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Anomalia ignorata ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div><div class=item-subtitle></div></div></li></ul></div></div></div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<!-- TIME ANOMALY --> ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomaly.type ==="time"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">square_favorites</i></div><div class=demo-facebook-name>Riepilogo Anomalia</div></div><div class=card-content><div class=list><ul><li class=item-content><div class="item-inner item-row"><div class=item-cell><strong>Operatore</strong></div><div class=item-cell><strong>Data/Ora</strong></div><div class=item-cell><strong>Punto di Interesse</strong></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.id.split(":")[1]', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.firstname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.user.familyname', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>Inizio: <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.time.start', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span><br>Fine: <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pass.time.end', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi._id', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span><br>Tipologia Passaggio: <span class=text-color-yellow>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.pass.toilet.type.includes("toilets")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Bagni e Cabine ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Bagni ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("sanitization")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("sanitization")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Sanificazione Bagno ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("cabins")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Cabine ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.pass.toilet.type.includes("square")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Piazzali ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.pass.toilet.type.includes("cabins")', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div></div></li><li class=item-content><div class=item-inner><div class=item-title><strong>Info Passaggio</strong></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Indirizzo Punto di Interesse</div><div class=item-cell><span class="">';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.pdi.address', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell><a href=# class="icon\'only tooltip-init" data-tooltip="Apri Mappa!" @click=openMap><i class="icon f7-icons text-color-yellow">map_pin_ellipse</i></a></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Durata media dei passaggi<br>(dal <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'new Date(this.anomaly.pdi.stats.from).toLocaleDateString("it")', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' </span>al <span class=text-color-yellow>';
          r += Template7Helpers.js.call(ctx_2, 'new Date(this.anomaly.pdi.stats.to).toLocaleDateString("it")', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' </span>)</div><div class=item-cell><span class=text-color-green>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_pdi_mean_time.hour === 0 && this.anomaly.formatted_pdi_mean_time.min === 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' &lt; 1 minuto ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_pdi_mean_time.hour > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_pdi_mean_time.hour', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_pdi_mean_time.hour > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ore ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ora ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_pdi_mean_time.min > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_pdi_mean_time.min', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_pdi_mean_time.min > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuti ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuto ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>Range durata ammissibile:<br><span class=text-color-yellow>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_min_time.hour > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_min_time.hour', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_min_time.hour > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ore ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ora ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_min_time.min > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_min_time.min', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_min_time.min > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuti ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuto ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ( -';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.thresholds.elapsed_time_percentage_low', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '% ) - ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_max_time.hour > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_max_time.hour', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_max_time.hour > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ore ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ora ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_max_time.min > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_max_time.min', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_max_time.min > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuti ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuto ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ( +';
          r += Template7Helpers.js.call(ctx_2, 'this.anomaly.thresholds.elapsed_time_percentage_high', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '% )</span></div></div></li><li class=item-content><div class="item-inner item-row"><div class=item-cell>Durata passaggio</div><div class=item-cell><span class=text-color-red>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_delta_time.hour === 0 && this.anomaly.formatted_delta_time.min === 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' &lt; 1 minuto ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_delta_time.hour > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_delta_time.hour', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_delta_time.hour > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ore ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ora ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.formatted_delta_time.min > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js.call(ctx_3, 'this.anomaly.formatted_delta_time.min', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.anomaly.formatted_delta_time.min > 1', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuti ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' minuto ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.time_type === "high"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# class="icon-only tooltip-init" data-tooltip="Durata Passaggio<br>più lunga della media"><i class="icon f7-icons text-color-yellow">tortoise_fill </i></a>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomaly.time_type === "low"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# class="icon-only tooltip-init" data-tooltip="Durata Passaggio<br>più corta della media"><i class="icon f7-icons text-color-yellow">cat </i></a>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></li><li><a href=# class="item-content item-link" @click=openPass><div class=item-inner><div class=item-title-row><div class=item-title>Visualizza i controlli relativi al passaggio</div></div><div class=item-subtitle></div></div></a></li></ul></div></div></div>';
          r += Template7Helpers.js_if.call(ctx_2, '!this.anomaly.resolved', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, '@root.$app.data.user.role==="Admin"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Risoluzione --><div class="card demo-facebook-card"><div class=card-header><div class=demo-facebook-avatar><i class="icon f7-icons text-color-yellow">lightbulb</i></div><div class=demo-facebook-name>Risoluzione</div></div><div class=card-content><div class="list media-list"><ul><li><a href=# class="item-link item-content" @click=resolveTimeAnomalyClick><div class=item-inner><div class=item-title-row><div class=item-title>Presa visione dell\'anomalia, rimuovila dalla lista delle anomalia non risolte.</div></div></div></a></li></ul></div></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    