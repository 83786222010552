var pdfMake = require('pdfmake/build/pdfmake.js');
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import {requestPdfReport} from '../../modules/reports/pdf-report';
import {monthNumber} from '../../modules/config';

export function checkPdfReportRequest(self) {
	var start = new Date(self.date_range.start);
	var end = new Date(self.date_range.end);
	self.$app.dialog.confirm(`Generare il report dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()}`, () => {
		self.$app.preloader.show();
		getPdfReport(self).then((response) => {
			self.$app.preloader.hide();
		}).catch((error) => {
			console.error(error);
			self.$app.preloader.hide();
			self.$app.dialog.alert("Errore durante la creazione del report.")
		});
	});

	// if(false) {
	// 	// If Date filter set
	// } else {
	// 	self.$app.dialog.confirm("Non è stato settato nessun range di date.<br>Il report generato partirà dal primo del mese corrente.", () => {
	// 		self.$app.preloader.show();
	// 		getPdfReport(self).then((response) => {
	// 			self.$app.preloader.hide();
	// 		}).catch((error) => {
	// 			console.error(error);
	// 			self.$app.preloader.hide();
	// 			self.$app.dialog.alert("Errore durente la creazione del report.")
	// 		});
	// 	});
	// }
}

function getPdfReport(self) {
	return new Promise((resolve, reject) => {
		var active_tab = self.$('.infinite-scroll-content.tab-active');
		var elem = active_tab.prop('id').split('-')[1];
		var pdi = undefined;
		if (self.backVisible) {
			pdi = self.toilet._id;
		}
		requestPdfReport(elem, pdi, self.date_range).then((response) => {
			const pdf_report = pdfMake.createPdf(response.message.docDefinition);
			pdf_report.download(response.message.filename, (error, response) => {
				if (error) {
					return reject(error);
				} else {
					return resolve(response)
				}
			});
			// var win = window.open('', '_blank');
			// pdf_report.open({}, win);
			// self.$preloader.hide();

		}).catch((error) => {
			return reject(error);
		});

	});
}
