import {getReport} from '../../modules/reports/reports-utils.js';
import {api_url} from '../../modules/config';

export async function getWeeklyReport(self){
	self.$app.preloader.show();
	const file_url = api_url+'report/getFile/';
	getReport(self, 'week', (error, response) => {
		if(error) {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Errore! Riprova più tardi.', () => {
				self.$router.refreshPage();
			});
			return;
		}
		var file = response.file;
		var filename = response.filename;
		self.$app.preloader.hide();
		window.open(file_url+file+'/'+filename, '_blank');
	});
}

export function getMonthlyReport(self) {
	self.$app.preloader.show();
	const file_url = api_url+'report/getFile/';
	getReport(self, 'month', (error, response) => {
		if(error) {
			self.$app.preloader.hide();
			self.$app.dialog.alert('Errore! Riprova più tardi.', () => {
				self.$router.refreshPage();
			});
			return;
		}
		var file = response.file;
		var filename = response.filename;
		self.$app.preloader.hide();
		window.open(file_url+file+'/'+filename, '_blank');
	});
}
