export default {
  id: '8ade1309c5',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Not found</div></div></div><div class=page-content><div><div><pre style="line-height: 1.5; white-space: pre;">\n\n        \          SORRY            /\n         \                         /\n          \    This page does     /\n           ]   not exist yet.    [    ,\'|\n           ]                     [   /  |\n           ]___               ___[ ,\'   |\n           ]  ]\             /[  [ |:   |\n           ]  ] \           / [  [ |:   |\n           ]  ]  ]         [  [  [ |:   |\n           ]  ]  ]__     __[  [  [ |:   |\n           ]  ]  ] ]\ _ /[ [  [  [ |:   |\n           ]  ]  ] ] (#) [ [  [  [ :====\'\n           ]  ]  ]_].nHn.[_[  [  [\n           ]  ]  ]  HHHHH. [  [  [\n           ]  ] /   `HH("N  \ [  [\n           ]__]/     HHH  "  \[__[\n           ]         NNN         [\n           ]         N/"         [\n           ]         N H         [\n          /          N            \\n         /           q,            \\n        /                           \\n</pre></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};