import {app} from './app';
// Login Page
import LoginPage from '../pages/login.f7.html';
// Main Pages
import PdisPage from '../pages/main-pages/pdis.f7.html';
import PassesPage from '../pages/main-pages/passes.f7.html';
// import PassPage from '../pages/pass-details.f7.html';
import ChecksPage from '../pages/main-pages/checks.f7.html';
import CheckPage from '../pages/check-detail.f7.html';
// User Management
import UsersPage from '../pages/users.f7.html';
import AddUserPage from '../pages/add-user.f7.html';
import EditUserPage from '../pages/update-user.f7.html';
// Report Pages
import ReportPage from '../pages/reports.f7.html';
import PdiStatsPage from '../pages/pdi-stats.f7.html';
import PdiStatsPageDetails from '../pages/pdi-stats-details.f7.html';
// Anomalies Management
import AnomaliesPage from '../pages/anomalies.f7.html';
import AnomalyDetailPage from '../pages/anomaly-detail-page.f7.html'
import ManageThresholdsPage from '../pages/manage-thresholds.f7.html';
// Notification Management
import NotificationPage from '../pages/user-notification.f7.html';
// PDI MANAGEMENT
import ToiletsPage from '../pages/pdi-management/toilets.f7.html';
import EditToiletPage from '../pages/pdi-management/update-toilet.f7.html';
import AddToiletPage from '../pages/pdi-management/add-toilet.f7.html';
import PdiManagementPage from '../pages/pdi-management/manage-pdi.f7.html';
// ADD PASS
import AddPassPage from '../pages/add-pass.f7.html';

import NotFoundPage from '../pages/404.f7.html';

import {
	isUserAuthenticated,
	isUserAuthenticatedAndAdmin,
	getSelf
} from './modules/couchdb-auth-utils';

import {
	getAllUsers,
	getUser
} from './modules/couchdb-users-utils';

import {
	getAllToilets,
	getCheck,
	getToilet,
	getAllToiletsWithDailyPasses
} from "./modules/couchdb-toilets-utils";

import {
	getPdiBlankDoc
} from './utils/pdi-management';

import {
	getPassBlankDoc,
	getCheckBlankDoc
} from './utils/pass-management';

import {
	getAllPasses,
	getAllPasses_new,
	getAllChecks
} from './modules/couchdb-pagination-module';

import {
	getChecks
} from './modules/couchdb-checks-utils';

import {
	getAnomalies,
	getAnomaly
} from './modules/couchdb-anomalies-module';

import {
	getThresholds
} from './modules/couchdb-thresholds-module';

import {
	getNotificationDoc
} from './modules/push-notification-module';

import {
	monthNumber,
	pdi_pass_types
} from './modules/config';

import {
	getPdis
} from './modules/pdi-module';

var routes = [
	{
		name: 'login',
		path: '/login/',
		component: LoginPage,
	},
	{
		name: 'home',
		path: '/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((res) =>{
				if(res) {
					getPdis().then((response) => {
						var result = JSON.parse(JSON.stringify(pdi_pass_types));
						for(var pdi_type of result) {
							for(var pass_type of pdi_type.pass_types) {
								pass_type.elems = response[pass_type.name];
							}
						}
						app.preloader.hide();
						resolve(
							{
								component: PdisPage
							},
							{
								context: {
									pdi_pass_types: result,
									dayIndex: (new Date()).getDay()
								}
							}
						);
					}).catch((error) => {
						app.preloader.hide();
						return reject();
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'passes',
		path: '/passes',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if(isAuth) {
					var end = new Date()
					var start = new Date(end.getTime());
					start.setDate(1);
					start.setHours(0);
					start.setMinutes(0);
					start.setSeconds(0);
					start.setMilliseconds(0);
					end.setHours(23);
					end.setMinutes(59);
					end.setSeconds(59);
					end.setMilliseconds(999);
					var date_range = {
						start: start.toISOString(),
						end: end.toISOString()
					}
					getAllPasses_new(undefined, [], date_range, {}).then((response) => {
						var result = JSON.parse(JSON.stringify(pdi_pass_types));
						for(var pdi_type of result) {
							for(var pass_type of pdi_type.pass_types) {
								pass_type.elems = response[`${pass_type.name}_passes`];
							}
						}
						app.preloader.hide();
						return resolve(
							{
								component: PassesPage
							},
							{
								context: {
									pdi_pass_types: result,
									date_range: date_range,
									date_range_title: `(Dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()})`,
									bookmarks: response.bookmarks,
									backVisible: false
								}
							}
						);
					}).catch((error) => {
						console.error(error);
						app.preloader.hide();
						return reject();
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'toilet-details',
		path: '/toilet/:toiletID/:type',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth){
					const toiletID = routeTo.params.toiletID;
					var end = new Date()
					var start = new Date(end.getTime());
					start.setDate(1);
					start.setHours(0);
					start.setMinutes(0);
					start.setSeconds(0);
					start.setMilliseconds(0);
					end.setHours(23);
					end.setMinutes(59);
					end.setSeconds(59);
					end.setMilliseconds(999);
					var date_range = {
						start: start.toISOString(),
						end: end.toISOString()
					}
					getAllPasses_new(toiletID, [], date_range, {}).then((response) => {
						var pdi = response.toilet;
						var result = [];
						for(var pdi_type of pdi_pass_types) {
							if (Object.keys(pdi[pdi_type.pdi_type]).length > 0) {
								var elem = JSON.parse(JSON.stringify(pdi_type));
								for(var pass_type of elem.pass_types) {
									pass_type.elems = response[`${pass_type.name}_passes`];
								}
								result.push(elem);
							}
						}
						app.preloader.hide();
						resolve(
							{
								component: PassesPage
							},
							{
								context: {
									pdi_pass_types: result,
									toilet: response.toilet,
									type: routeTo.params.type,
									date_range: date_range,
									date_range_title: `(Dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()})`,
									bookmarks: response.bookmarks,
									backVisible: true
								}
							}
						);
					}).catch((error) => {
						console.error(error);
						app.preloader.hide();
						return reject();
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'checks-list',
		path: '/checks/',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (isAuth) {
					var end = new Date()
					var start = new Date(end.getTime());
					start.setDate(1);
					start.setHours(0);
					start.setMinutes(0);
					start.setSeconds(0);
					start.setMilliseconds(0);
					end.setHours(23);
					end.setMinutes(59);
					end.setSeconds(59);
					end.setMilliseconds(999);
					var date_range = {
						start: start.toISOString(),
						end: end.toISOString()
					};
					getAllChecks(date_range, [], {}).then((response) => {
						var result = JSON.parse(JSON.stringify(pdi_pass_types));
						for(var pdi_type of result) {
							for(var pass_type of pdi_type.pass_types) {
								pass_type.elems = response[`${pass_type.name}_checks`];
							}
						}
						app.preloader.hide();
						resolve(
							{
								component: ChecksPage
							},
							{
								context: {
									pdi_pass_types: result,
									// toilets_checks: response.toilets_checks,
									// cabins_checks: response.cabins_checks,
									// square_checks: response.square_checks,
									bookmarks: response.bookmarks,
									date_range: date_range,
									date_range_title: `(Dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()})`,
									backVisible: false,
								}
							}
						);
					}).catch((error) => {
						console.error(error);
						app.preloader.hide();
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'pass-details',
		path: '/pass/:passID/:type',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (isAuth) {
					getChecks(routeTo.params.passID, (err, response) => {
						if(err) {
							app.preloader.hide();
							return reject();
						}
						var pdi = response.toilet;
						var pass = response.pass;
						var result = [];
						for(var pdi_type of pdi_pass_types) {
							if (Object.keys(pdi[pdi_type.pdi_type]).length > 0) {
								var elem = JSON.parse(JSON.stringify(pdi_type));
								elem.pass_types = [];
								for(var pass_type of pdi_type.pass_types) {
									if (pass.toilet.type.includes(`${pass_type.name}`) ) {
										var pass_type_aux = JSON.parse(JSON.stringify(pass_type));
										pass_type_aux.elems = response[`${pass_type.name}_checks`];
										elem.pass_types.push(pass_type_aux);
									}
								}
								result.push(elem);
							}
						}
						app.preloader.hide();
						resolve(
							{
								component: ChecksPage
							},
							{
								context: {
									// toilets_checks: res.toilets_checks,
									// cabins_checks: res.cabins_checks,
									// square_checks: res.square_checks,
									pdi_pass_types: result,
									toilet: pdi,
									pass: pass,
									backVisible: true,
									type: routeTo.params.type,
									passName: routeTo.params.passName
								}
							}
						);
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'check-details',
		path: '/check/:checkID',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) => {
				if (isAuth) {
					getCheck(routeTo.params.checkID, (err, res) => {
						if(err) {
							app.preloader.hide();
							return reject();
						}
						// Get images
						var photoArr = [];
						var imageCaption= '';
						var images = {};
						var images_keys = [];
						if (res._attachments) {
							images_keys = Object.keys(res._attachments);
						}
						var new_org;
						if (images_keys.includes('mainImg.jpg')) {
							// OLD IMAGE ORGANIZATION
							new_org = false;
							for (var image of images_keys) {
								if(image === 'mainImg.jpg') {
									images.mainImage = res._attachments[image].data;
									imageCaption = 'Immagine principale';
								} else {
									images[image] = res._attachments[image].data;
									imageCaption = 'Immagine aggiuntiva n. '+image.split('addImg')[1];
								}
								photoArr.push({
									url: 'data:image/jpg; base64, '+res._attachments[image].data,
									caption: imageCaption
								});
							}
						} else {
							new_org = true;
							images.pre = [];
							images.post = [];
							images_keys.sort();
							for(var image of images_keys) {
								var img_obj = {
									url: 'data:image/jpg; base64, '+res._attachments[image].data,
									caption: image
								}
								if (image.includes('Pre')) {
									images.pre.push(img_obj);
								} else {
									images.post.push(img_obj);
								}
							}
							photoArr = images.pre.concat(images.post);
						}
						if (!res.manual_insert) {
							res.manual_insert = false
						}
						app.preloader.hide();
						resolve(
							{
								component: CheckPage
							},
							{
								context: {
									check: res,
									photoArr: photoArr,
									images: images,
									new_org: new_org
								}
							}
						);
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'toilets-handling',
		path: '/toilets/',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					getAllToilets((err, res) => {
						if(err) {
							app.preloader.hide();
							return reject();
						}
						app.preloader.hide();
						resolve(
							{
								component: ToiletsPage
							},
							{
								context: {
									toilets: res
								}
							}
						);
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'edit-toilet',
		path: '/edit-toilet/:toiletID',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					getToilet(routeTo.params.toiletID, (err, res) => {
						if(err) {
							app.preloader.hide();
							return reject();
						}
						app.preloader.hide();
						resolve(
							{
								component: PdiManagementPage
							},
							{
								context: {
									pdi: res,
									new: false
								}
							}
						);
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'add-toilet',
		path: '/add-toilet/:nextID',
		async: function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					// Get pdi blank document
					var pdi = getPdiBlankDoc();
					pdi._id = routeTo.params.nextID;
					app.preloader.hide();
					resolve(
						{
							component: PdiManagementPage
						},
						{
							context: {
								pdi: pdi,
								new: true
							}
						}
					);
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		},
	},
	{
		name: 'users',
		path: '/users/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					getAllUsers((error, response) => {
						if(error) {
							app.preloader.hide();
							return reject();
						}
						app.preloader.hide();
						resolve(
							{
								component: UsersPage
							},
							{
								context: {
									users: response.users
								}
							}
						);
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'add-user',
		path: '/add-user/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					resolve({
						component: AddUserPage
					});
				} else {
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'edit-user',
		path: '/edit-user/:username',
		async: async function (routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					// Get user
					const username = routeTo.params.username;
					getUser(username, (error, response) => {
						if (error) {
							app.preloader.hide();
							return reject();
						}  else {  // NO ERROR
							app.preloader.hide();
							resolve(
								{
									component: EditUserPage
								},
								{
									context: {
										user: response.user
									}
								}
							);
						}
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'notification-page',
		path: '/user-notification/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					// Get user
					const username = app.data.user.username;
					// TODO REDO REQUESTS
					getNotificationDoc(username, (error, response) => {
						if (error) {
							app.preloader.hide();
							return reject();
						} else {
							app.preloader.hide();
							resolve(
								{
									component: NotificationPage
								},
								{
									context: {
										notification_doc: response
									}
								}
							);
						}
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'excel-reports',
		path: '/reports/',
		async: async function (routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					app.preloader.hide();
					return resolve({
						component: ReportPage
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'anomalies-page',
		path: '/anomalies/:type',
		async: async function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					// Get anomalies
					const anomalies_type = routeTo.params.type;
					const resolved = false;
					getAnomalies(anomalies_type, undefined, resolved, (error, response) => {
						if (error) {
							app.preloader.hide();
							return reject();
						}  else {  // NO ERROR
							app.preloader.hide();
							resolve(
								{
									component: AnomaliesPage
								},
								{
									context: {
										anomalies: response,
										anomalies_type: anomalies_type,
										resolved: resolved
									}
								}
							);
						}
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'anomaly-page',
		path: '/anomaly/:anomaly',
		async: async function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					// Get anomaly
					const anomalies_id = routeTo.params.anomaly;
					getAnomaly(anomalies_id, (error, response) => {
						if (error) {
							app.preloader.hide();
							return reject();
						}  else {  // NO ERROR
							app.preloader.hide();
							resolve(
								{
									component: AnomalyDetailPage
								},
								{
									context: {
										anomaly: response,
									}
								}
							);
						}
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'pdi-stats',
		path: '/pdi-stats/:id',
		alias: '/pdi-stats/',
		async: async function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth) =>{
				if(isAuth) {
					var pdi_id = routeTo.params.id;
					if(pdi_id) {
						getToilet(pdi_id, (error, pdi) => {
							if (error) {
								app.preloader.hide();
								return reject();
							}  else {  // NO ERROR
								app.preloader.hide();
								if (!pdi.stats.to || !pdi.stats.from) {
									app.dialog.alert(`Statistiche non disponibile per <span class="text-color-black">${pdi.name}</span>.<br>Nessun passaggio effettuato.`);
									return reject();
								}
								var stats = [];
								if (Object.keys(pdi.toilets).length > 0 && Object.keys(pdi.cabins).length > 0) {
									stats.push({
										title: 'Bagni e Cabine',
										object: pdi.stats.toilets_cabins
									});
									stats.push({
										title: 'Bagni',
										object: pdi.stats.toilets
									});
									if (Object.keys(pdi.stats.sanitization).length > 0) {
										stats.push({
											title: "Sanificazione Bagni",
											object: pdi.stats.sanitization
										});
									}
									stats.push({
										title: 'Cabine',
										object: pdi.stats.cabins
									});
								} else {
									if (Object.keys(pdi.toilets).length > 0) {
										stats.push({
											title: 'Bagni',
											object: pdi.stats.toilets
										});
										if (pdi.stats.sanitization && Object.keys(pdi.stats.sanitization).length > 0) {
											stats.push({
												title: "Sanificazione Bagni",
												object: pdi.stats.sanitization
											});
										}
									}
									if (Object.keys(pdi.cabins).length > 0) {
										stats.push({
											title: 'Cabine',
											object: pdi.stats.cabins
										});
									}
								}
								if (Object.keys(pdi.square).length > 0) {
									stats.push({
										title: 'Piazzali',
										object: pdi.stats.square
									});
								}
								resolve(
									{
										component: PdiStatsPageDetails
									},
									{
										context: {
											pdi: pdi,
											stats: stats
										}
									}
								);
							}
						});
					} else {
						// Get pdi
						getAllToilets((error, response) => {
							if (error) {
								app.preloader.hide();
								return reject();
							}  else {  // NO ERROR
								app.preloader.hide();
								resolve(
									{
										component: PdiStatsPage
									},
									{
										context: {
											pdi: response
										}
									}
								);
							}
						});
					}
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'manage-thresholds',
		path: '/manage-thresholds/',
		async: async function(routeTo, routeFrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticatedAndAdmin((isAuth, isAdmin) =>{
				if(isAuth) {
					if (!isAdmin) {
						app.preloader.hide();
						return reject();
					}
					// Get user
					getThresholds((error, response) => {
						if (error) {
							app.preloader.hide();
							return reject();
						}  else {  // NO ERROR
							app.preloader.hide();
							resolve(
								{
									component: ManageThresholdsPage
								},
								{
									context: {
										thresholds: response
									}
								}
							);
						}
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		name: 'add-pass',
		path: '/add-pass/',
		async: async function(routeTo, routefrom, resolve, reject) {
			app.preloader.show();
			isUserAuthenticated((isAuth, isAdmin) =>{
				if(isAuth) {
					var now = new Date()
					const now_date_str = `${now.getFullYear()}-${monthNumber[now.getMonth()]}-${(now.getDate() < 10)? `0${now.getDate()}`: now.getDate()}`
					getAllToilets((err, res) => {
						if(err) {
							app.preloader.hide();
							return reject();
						}
						getAllUsers((error, response) => {
							if(error) {
								app.preloader.hide();
								return reject();
							}
							let users = response.users.map(user => user.doc);
							users = users.filter(user => user.roles.includes("Operation"))
							let pdis = res.map(pdi => pdi.doc);
							app.preloader.hide();
							resolve(
								{
									component: AddPassPage
								},
								{
									context: {
										blank_pass: getPassBlankDoc(),
										pass: getPassBlankDoc(),
										check_blank: getCheckBlankDoc(),
										pass_date_time: {},
										pdi_list: pdis,
										pdis: [],
										users_list: users,
										users: [],
										pdi_pass_types: pdi_pass_types,
										now_date_str: now_date_str,
										selected_pass_type: '',
										selected_pdi_type: '',
										checks: []
									}
								}
							);
						});
					});
				} else {
					app.preloader.hide();
					resolve({
						component: LoginPage
					});
				}
			});
		}
	},
	{
		path: '(.*)',
		component: NotFoundPage
	},
];

export default routes;
