import {
	pagination_limit,
	getAllPasses_new,
	getAllChecks
} from '../modules/couchdb-pagination-module';

export function setPassesInfiniteScrool(self) {
	var allow_infinite = {};
	var passes_types = {};
	for(var pdi_type of self.pdi_pass_types) {
		for(var pass_type of pdi_type.pass_types) {
			// Infinite scrool
			passes_types[pass_type.name] = pass_type;
			if (pass_type.elems.length < pagination_limit) {
				if (pass_type.elems.length === 0) {
					self.$(`.searchbar-not-found-${pass_type.name}-passes`).css('display', 'block')
				} else {
					self.$(`.searchbar-not-found-${pass_type.name}-passes`).css('display', 'none')
				}
				self.$(`#${pass_type.name}-infinite-preloader`).css('display', 'none');
			} else {
				self.$(`.searchbar-not-found-${pass_type.name}-passes`).css('display', 'none')
				self.$(`#${pass_type.name}-infinite-preloader`).css('display', 'block');
				allow_infinite[pass_type.name] = true;
				self.$(`#tab-${pass_type.name}-passes`).off('infinite');
				self.$(`#tab-${pass_type.name}-passes`).on('infinite', function(e) {
					var target_elem = self.$(e.target);
					const pass_type_name = target_elem.prop('id').split('-')[1];
					if (!allow_infinite[pass_type_name]) return;
					allow_infinite[pass_type_name] = false;
					const toilet_id = (self.backVisible)? self.toilet._id: undefined;
					getAllPasses_new(toilet_id, [`${pass_type_name}`], self.date_range, self.bookmarks).then((response) => {
						if (response[`${pass_type_name}_passes`].length > 0) {
							self.bookmarks[pass_type_name] = response.bookmarks[pass_type_name];
							passes_types[pass_type_name].elems.push(...response[`${pass_type_name}_passes`]);
							self.$(`.searchbar-not-found-${pass_type_name}-passes`).css('display', 'none');
							self.$(`#${pass_type_name}-infinite-preloader`).css('display', 'block');
							if (response[`${pass_type_name}_passes`].length < pagination_limit) {
								self.$(`#${pass_type_name}-infinite-preloader`).css('display', 'none');
								self.$(`#tab-${pass_type_name}-passes`).off('infinite');
							}
							self.$setState();
							allow_infinite[pass_type_name] = true;
						} else {
							self.$(`#${pass_type.name}-infinite-preloader`).css('display', 'none');
							self.$(`#tab-${pass_type.name}-passes`).off('infinite');
						}
					}).catch((error) => {
						console.error(error);
						self.$app.dialog.alert('Non riesco a caricare altri passaggi.<br>Riprova più tardi', () => {
							self.$router.refreshPage();
						});
					});
				});

			}
		}
	}
}


export function setChecksInfiniteScrool(self) {
	var allow_infinite = {};
	var passes_types = {};
	for(var pdi_type of self.pdi_pass_types) {
		for(var pass_type of pdi_type.pass_types) {
			// Infinite scrool
			passes_types[pass_type.name] = pass_type;
			if (pass_type.elems.length < pagination_limit) {
				if (pass_type.elems.length === 0) {
					self.$(`.searchbar-not-found-${pass_type.name}-checks`).css('display', 'block')
				} else {
					self.$(`.searchbar-not-found-${pass_type.name}-checks`).css('display', 'none')
				}
				self.$(`#${pass_type.name}-checks-infinite-preloader`).css('display', 'none');
			} else {
				self.$(`.searchbar-not-found-${pass_type.name}-checks`).css('display', 'none')
				self.$(`#${pass_type.name}-checks-infinite-preloader`).css('display', 'block');
				allow_infinite[pass_type.name] = true;
				self.$(`#tab-${pass_type.name}-checks`).off('infinite');
				self.$(`#tab-${pass_type.name}-checks`).on('infinite', function(e) {
					var target_elem = self.$(e.target);
					const pass_type_name = target_elem.prop('id').split('-')[1];
					if (!allow_infinite[pass_type_name]) return;
					allow_infinite[pass_type_name] = false;
					getAllChecks(self.date_range, [`${pass_type_name}`], self.bookmarks).then((response) => {
						if (response[`${pass_type_name}_checks`].length > 0) {
							self.bookmarks[pass_type_name] = response.bookmarks[pass_type_name];
							passes_types[pass_type_name].elems.push(...response[`${pass_type_name}_checks`]);
							self.$(`.searchbar-not-found-${pass_type_name}-checks`).css('display', 'none');
							self.$(`#${pass_type_name}-checks-infinite-preloader`).css('display', 'block');
							if (response[`${pass_type_name}_checks`].length < pagination_limit) {
								self.$(`#${pass_type_name}-checks-infinite-preloader`).css('display', 'none');
								self.$(`#tab-${pass_type_name}-checks`).off('infinite');
							}
							self.$setState();
							allow_infinite[pass_type_name] = true;
						} else {
							self.$(`#${pass_type.name}-checks-infinite-preloader`).css('display', 'none');
							self.$(`#tab-${pass_type.name}-checks`).off('infinite');
						}
					}).catch((error) => {
						console.error(error);
						self.$app.dialog.alert('Non riesco a caricare altri passaggi.<br>Riprova più tardi', () => {
							self.$router.refreshPage();
						});
					});
				});
			}
		}
	}
}
