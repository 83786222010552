import {getAllPasses_new, getAllChecks, pagination_limit} from '../modules/couchdb-pagination-module';
import {monthNumber} from '../modules/config';
import {setPassesInfiniteScrool} from './pagination-util';

export function setPassDateFilter(start, end, self) {
	self.date_range = {
		start: start.toISOString(),
		end: end.toISOString()
	};
	self.date_range_title = `(Dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()})`;

	var toilet_id = undefined;
	if (self.backVisible) {
		toilet_id = self.toilet._id;
	}

	self.$app.preloader.show();
	getAllPasses_new(toilet_id, [], self.date_range, {}).then((response) => {
		for(var pdi_type of self.pdi_pass_types) {
			for(var pass_type of pdi_type.pass_types) {
				pass_type.elems = response[`${pass_type.name}_passes`];
				if (pass_type.elems.length < pagination_limit) {
					if (pass_type.elems.length === 0) {
						self.$(`.searchbar-not-found-${pass_type.name}-passes`).css('display', 'block')
					}
					self.$(`#${pass_type.name}-infinite-preloader`).css('display', 'none');
				} else {}
			}
		}
		self.bookmarks = response.bookmarks;
		self.$setState(self.date_range, () => {
			setPassesInfiniteScrool(self);
			self.$app.preloader.hide();
		})
	}).catch((error) => {
		self.$app.preloader.hide();
		console.error(error);
		self.$app.dialog.alert(`Errore durante il caricamento dei passaggi. Riprova!`, () => {

		});
	});
}

export function setCheckDateFilter(start, end, self) {
	self.date_range = {
		start: start.toISOString(),
		end: end.toISOString()
	};
	self.date_range_title = `(Dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()})`;

	self.$app.preloader.show();
	getAllChecks(self.date_range, [], {}).then((response) => {
		console.log(response);
		for(var pdi_type of self.pdi_pass_types) {
			for(var pass_type of pdi_type.pass_types) {
				pass_type.elems = response[`${pass_type.name}_checks`];
				if (pass_type.elems.length < pagination_limit) {
					if (pass_type.elems.length === 0) {
						self.$(`.searchbar-not-found-${pass_type.name}-checks`).css('display', 'block')
					}
					self.$(`#${pass_type.name}-checks-infinite-preloader`).css('display', 'none');
				} else {}
			}
		}
		// self.bookmarks = response.bookmarks;
		// self.toilets_checks = response.toilets_checks;
		// self.cabins_checks = response.cabins_checks;
		// self.square_checks = response.square_checks;
		// self.bookmarks = response.bookmarks;
		// console.log(self.toilets_checks);
		self.$setState(self.toilets_checks, () => {
			self.$app.preloader.hide();
		})
	}).catch((error) => {
		self.preloader.hide();
		self.$app.dialog.alert(`Errore durante il caricamento dei controlli. Riprova!`, () => {

		});
	})
}
