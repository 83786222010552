import {checksDB, passesDB, toiletsDB, pdi_pass_types} from './config';
var FileSaver = require('file-saver');

export function downloadImage(check_id, attachment) {
	if (attachment === 'mainImage') {
		attachment = 'mainImg.jpg';
	}
	checksDB.getAttachment(check_id, attachment, function(error, blob) {
		if (error) {
			console.log(error);
		} else {
			if (attachment === 'mainImage.jpg') {
				FileSaver.saveAs(blob, `${attachment}`);
			}else {
				FileSaver.saveAs(blob, `${attachment}.jpg`);
			}
		}
	});
}

export function getChecks(pass_id, callback) {
	passesDB.get(pass_id).then((response) => {
		var result = {
			pass: response
		};
		var promises = [];
		promises.push(toiletsDB.get(result.pass.toilet.id));
		promises.push(checksDB.find({
			selector: {
				"pass.id": pass_id
			},
			sort: [
				{
					"_id": "desc"
				}
			]
		}));

		Promise.all(promises).then((values) => {
			result.toilet = values[0];

			for(var pdi_type of pdi_pass_types) {
				for(var pass_type of pdi_type.pass_types) {
					result[`${pass_type.name}_checks`] = values[1].docs.filter((elem) => {
						return elem.toilet.type.includes(`${pass_type.name}`);
					});
				}
			}

			callback(undefined, result);
		});
	}).catch((error) => {
		callback(error, undefined);
	})
}

export function getCheck(check_id) {
	return checksDB.get(check_id);
}

export function putChecks(checks) {
	return checksDB.bulkDocs(checks)
}

export function putCheck(check) {
	return checksDB.put(check);
}
