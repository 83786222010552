import {
	checkSubscriptionDoc,
	modifyNotificationDoc
} from '../../modules/push-notification-module';

const publicVapidKey = 'BO1oRA_k_1rbH422pqdRmzYw6pueamhq-EkvfRCTy3vNEreEiDtaEveqWIyaqtj4czJc-bj0972rIr9WByDsBb8';

function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	.replace(/-/g, '+')
	.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export function detectBrowser() {
	console.log(navigator.userAgent);
	var sBrowser, sUsrAg = navigator.userAgent;
	
	if (sUsrAg.indexOf("Firefox") > -1) {
		sBrowser = "Mozilla Firefox";
		// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
	} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
		sBrowser = "Samsung Internet";
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
	} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
		sBrowser = "Opera";
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
	} else if (sUsrAg.indexOf("Trident") > -1) {
		sBrowser = "Microsoft Internet Explorer";
		// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
	} else if (sUsrAg.indexOf("Edge") > -1) {
		sBrowser = "Microsoft Edge (Legacy)";
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	} else if (sUsrAg.indexOf("Edg") > -1) {
		sBrowser = "Microsoft Edge (Chromium)";
		// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
	} else if (sUsrAg.indexOf("Chrome") > -1) {
		sBrowser = "Google Chrome or Chromium";
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
	} else if (sUsrAg.indexOf("Safari") > -1) {
		sBrowser = "Apple Safari";
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
	} else {
		sBrowser = "unknown";
	}

	return sBrowser
}

export function showErroNotification(text, self) {
	var notification = self.$app.notification.create({
		icon: '<i class="icon f7-icons">bell_slash</i>',
		title: `${self.$app.name}`,
		text: text,
		closeOnClick: true,
		closeTimeout: 5000,
		cssClass: 'theme-dark'
	});
	notification.open();
}

async function checkSubscription(serviceWorker, self){
	return new Promise(async (resolve, reject) => {
		var subscription = await serviceWorker.pushManager.getSubscription();
		if (!subscription) {
			// GET NEW SUBSCRIPTION
			serviceWorker.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
			}).then((new_subscription) => {
				subscription = new_subscription;
				checkSubscriptionDoc(subscription, self).then((response) => {
					return resolve(response);
				}).catch((error) => {
					return reject(error);
				});
			}).catch((error) => {
				console.error(error);
				return reject({
					message: 'Failed to subscribe'
				});
			});
		} else {
			checkSubscriptionDoc(subscription, self).then((response) => {
				return resolve(response);
			}).catch((error) => {
				return reject(error);
			});
		}
	});
}

function requestNotificationPermissions(serviceWorker, self) {
	return new Promise((resolve, reject) => {
		Notification.requestPermission().then((response) => {
			switch (response) {
				case 'default':
					return reject({
						message: "Permission not specified"
					});
					break;
				case 'granted':
					checkSubscription(serviceWorker, self).then((response) => {
						return resolve(response);
					}).catch((error) => {
						return reject(error);
					});
					break;
				case 'denied':
					return reject({
						message: "Permission denied"
					});
					break;
				default:

			}
		}).catch((error) => {
			return reject({
				message: "Failed to request notification permission"
			})
		});
	});
}

export function setPushNotification(self) {
	return new Promise(async (resolve, reject) => {
		if ("serviceWorker" in navigator) {
			var serviceWorker = await navigator.serviceWorker.getRegistration();
			if (!serviceWorker) {
				showErroNotification('Il tuo browser non supporta le notifiche push', self);
				reject({
					message: 'Failed Service Worker retrieve'
				})
				return;
			}
			var push_permission = await serviceWorker.pushManager.permissionState({
				userVisibleOnly: true
			});
			var promise;
			switch (push_permission) {
				case 'denied':
					promise = showErroNotification('Consenti le notifiche push nelle impostazioni del sito per riceverle', self);
					break;
				case 'granted':
					promise = checkSubscription(serviceWorker, self);
					break;
				case 'prompt':
					promise = requestNotificationPermissions(serviceWorker, self);
					break;
				default:
					reject({
						message: 'Failed to get notification permission'
					})
					break;
			}
			promise.then((response) => {
				return resolve(response);
			}).catch((error) => {
				return reject(error);
			});
		} else {
			// Mostra notifiche push non disponibili messaggio
			showErroNotification('Il tuo browser non supporta le notifiche push', self);
			reject({
				message: 'Notification not supported'
			});
		}
	});
}

export function savePushNotificationsSettings(self) {
	setPushNotification(self).then((response) => {
		var push_notification_doc = self.notification_doc.push_notification;
		push_notification_doc.gps = self.$(`#gps-anomalies-push-checkbox`).prop('checked');
		push_notification_doc.time = self.$(`#time-anomalies-push-checkbox`).prop('checked');
		self.$app.preloader.show();
		modifyNotificationDoc(self.notification_doc).then((response) => {
			self.$app.preloader.hide();
			self.$app.dialog.alert("Impostazioni Salvate", ()=>{
				self.$router.refreshPage();
			});
		}).catch((error) => {
			console.error(error);
			self.$app.preloader.hide();
			self.$app.dialog.alert("Qualcosa è andato storto!", ()=>{
				self.$router.refreshPage();
			});
		});
	}).catch((error) => {
		self.$app.preloader.hide();
		self.$app.dialog.alert("Qualcosa è andato storto!", ()=>{
			self.$router.refreshPage();
		});
	})

}
