import {modifyNotificationDoc} from '../../modules/push-notification-module';

export function saveMailNotificationsSettings(self) {
	var mail_notification_doc = self.notification_doc.mail_notification;
	mail_notification_doc.gps = self.$(`#gps-anomalies-mail-checkbox`).prop('checked');
	mail_notification_doc.time = self.$(`#time-anomalies-mail-checkbox`).prop('checked');
	self.$app.preloader.show();
	modifyNotificationDoc(self.notification_doc).then((response) => {
		console.log(response);
		self.$app.preloader.hide();
		self.$app.dialog.alert("Impostazioni Salvate", ()=>{
			self.$router.refreshPage();
		});
	}).catch((error) => {
		console.error(error);
		self.$app.preloader.hide();
		self.$app.dialog.alert("Qualcosa è andato storto!", ()=>{
			self.$router.refreshPage();
		});
	})
}
