
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { initInfiniteScroll, reloadAnomaliesUtils } from '../js/utils/anomalies-util';
export default {
  methods: {
    openAnomaly: function (anomaly_id) {
      this.$router.navigate(`/anomaly/${anomaly_id}`);
    },
    reloadAnomalies: function (resolved) {
      reloadAnomaliesUtils(resolved, this);
    }
  },
  on: {
    pageInit: function (page) {
      initInfiniteScroll(this);
    }
  },
  id: '6fc4f4daa6',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=anomalies-page><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only panel-open navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i> </a>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomalies_type === "gps"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Anomalie GPS</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomalies_type === "time"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Anomalie Durate</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><!-- FAB Backdrop --><div class=fab-backdrop></div><!-- FILTER FLOATING BUTTON --><div class="fab fab-right-bottom"><a href=#><i class="icon f7-icons">line_horizontal_3_decrease </i><i class="icon f7-icons">xmark</i></a><div class="fab-buttons fab-buttons-top"><a href=# class=fab-label-button @click=reloadAnomalies(false)>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.resolved === false', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <i class="icon f7-icons">checkmark_alt </i>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <span></span> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <span class=fab-label>MOSTRA SOLO ANOMALIE <strong>NON RISOLTE</strong> </span></a><a href=# class=fab-label-button @click=reloadAnomalies(true)>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.resolved === true', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <i class="icon f7-icons">checkmark_alt </i>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <span></span> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <span class=fab-label>MOSTRA SOLO ANOMALIE <strong>RISOLTE</strong> </span></a><a href=# class=fab-label-button @click="reloadAnomalies(undefined)">';
      r += Template7Helpers.js_if.call(ctx_1, 'this.resolved === undefined', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <i class="icon f7-icons">checkmark_alt </i>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' <span></span> ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <span class=fab-label>MOSTRA TUTTO</span></a></div></div><!-- GPS ANOMALIES TAB --><div class="page-content infinite-scroll-content" id=anomalies-page-content><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.anomalies, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# class="item-content item-link" @click="openAnomaly(\'';
          r += c(ctx_2._id, ctx_2);
          r += '\')"><div class=item-media><i class="f7-icons text-color-yellow">';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type === "gps"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' placemark ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type === "time"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' clock ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</i></div><div class=item-inner><div class=item-title-row><div class=item-title>Anomalia nel Passaggio del <span class=text-color-lightblue>';
          r += Template7Helpers.js.call(ctx_2, 'this.pass.time.start', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class=item-after>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.resolved', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <i class="icon f7-icons text-color-yellow tooltip-init" data-tooltip="Anomalia Risolta">checkmark_circle_fill </i>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class=item-subtitle>Punto di Interesse: <span class=text-color-lightblue>';
          r += Template7Helpers.js.call(ctx_2, 'this.pdi.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div><div class="item-subtitle item-search-cabins-passes">Eseguito da ';
          r += c(ctx_2.pass.user.firstname, ctx_2);
          r += ' ';
          r += c(ctx_2.pass.user.familyname, ctx_2);
          r += '</div>';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type === "gps"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=item-subtitle>Anomalia sul GPS ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.gps_start && this.gps_end', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <span class=text-color-lightblue>di partenza </span>e <span class=text-color-lightblue>finale </span>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.gps_start', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += Template7Helpers.js_if.call(ctx_4, 'this.gps_end', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <span class=text-color-lightblue>';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.gps_start', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' di partenza ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.gps_end', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' finale ';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' </span>';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.type === "time"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=item-subtitle>Durata passaggio più <span class=text-color-lightblue>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.time_type === "high"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' lunga ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += ' corta ';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>della media</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, 'this.anomalies.length === 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-media><i class="f7-icons text-color-green">';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomalies_type === "gps"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' placemark ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.anomalies_type === "time"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' clock ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</i></div><div class=item-inner><div class=item-title-row><div class=item-title>Nessuna anomalia trovata</div><div class=item-after></div></div></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><!-- Infinite scrool preloader --><div class="preloader infinite-scroll-preloader anomalies-scroll-preloader" id=anomalies-infinite-preloader></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    