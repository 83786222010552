import {app} from '../app';
import {toiletsDB, passesDB, checksDB, pdi_types, pass_types, pdi_pass_types} from './config';
import {getAllPasses_new} from './couchdb-pagination-module';

function setDayStart(date) {
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	return date;
}

function setDayEnd(date) {
	date.setHours(23);
	date.setMinutes(59);
	date.setSeconds(59);
	date.setMilliseconds(999);
	return date;
}

function checkCompletedPasses(pdi, pass_type, pdi_type, passes) {
	pdi.passes_summary_frequency = pass_type.frequency;
	if (pdi[pdi_type].cleaning_suspended) {
		pdi.passes_summary = 'cleaning_suspended';
		return pdi;
	}
	const now = new Date();
	const weekDay = now.getDay();
	var expected_passes;
	switch (pass_type.frequency) {
		case 'day':
			expected_passes = pdi[pdi_type].wpass[weekDay];
			break;
		case 'week':
			switch (pass_type.name) {
				case 'sanitization':
					expected_passes = (pdi.toilets.night_sanitization === -1)? 0 : 1;
					break;
				default:
					expected_passes = pdi[pdi_type].wpass.reduce((partial_sum, a) => partial_sum + a, 0);
			}
			break;
		default:
			return reject('Invalid Frequency pass')
	}

	var passes_summary = [];
	for(var pass of passes[`${pass_type.name}_passes`]) {
		if (pass.toilet.id === pdi._id) {
			if (pass.time.end) {
				if (passes_summary.length >= expected_passes) {
					passes_summary.push(-2); // PLUS PASS
				} else {
					passes_summary.push(1); // COMPLETED
				}
			} else {
				if (passes_summary.length >= expected_passes) {
					passes_summary.push(-3); // PLUS PASS IN PROGRESS
				} else {
					passes_summary.push(0); // IN PROGRESS
				}
			}
		}
	}
	var pass_length = passes_summary.length;
	for(var i = pass_length; i < expected_passes; i++) {
		passes_summary.push(-1);	// NOT DONE
	}
	passes_summary.sort((a, b) => {
		return a - b;
	});
	pdi.passes_summary = passes_summary;

	return pdi;
}

function getPassesFromFrequency(pass_frequency) {
	return new Promise((resolve, reject) => {
		var promises = [];
		for(var frequency of Object.keys(pass_frequency)) {
			const now = new Date();
			var start = new Date(now.getTime());
			var end = new Date(now.getTime());
			const weekDay = now.getDay();

			switch (frequency) {
				case 'day':
					start = setDayStart(start);
					end = setDayEnd(end);
					break;
				case 'week':
					switch (weekDay) {
						case 0:
							start.setDate(now.getDate() - 7);
							start = setDayStart(start);
							end = end.setDayEnd(end);
							break;
						default:
							start.setDate(now.getDate() - weekDay + 1);
							start = setDayStart(start);
							end.setDate(now.getDate() +7 - weekDay);
							end = setDayEnd(end);
							break;
					}
					break;
				default:
					return reject('Invalid Frequency pass')
			}
			var data_range = {
				start: start.toISOString(),
				end: end.toISOString()
			};
			promises.push(
				getAllPasses_new(undefined, pass_frequency[frequency], data_range, {}, 999)
			);
		}

		Promise.all(promises).then((values) => {
			var result = {};
			for(var value of values) {
				for(var key of Object.keys(value)) {
					if (key !== 'bookmarks') {
						result[key] = value[key];
					}
				}
			}
			return resolve(result);
		}).catch((error) => {
			console.error(error);
			return reject(error);
		})
	});
}

export function getPdis() {
	return new Promise((resolve, reject) => {
		toiletsDB.allDocs({
			include_docs: true,
			endkey: '_design/'
		}).then((response) => {
			var result = {};
			var promises = [];

			// GET PASSES
			var pass_frequency = {};
			for(var pdi_type of pdi_pass_types) {
				for(var pass_type of pdi_type.pass_types) {
					result[pass_type.name] = [];
					if (!pass_frequency.hasOwnProperty(pass_type.frequency)) {
						pass_frequency[pass_type.frequency] = [];
					}
					pass_frequency[pass_type.frequency].push(pass_type.name);
				}
			}
			getPassesFromFrequency(pass_frequency).then((passes) => {
				var docs = response.rows;
				for(var doc_elem of docs) {
					var doc = doc_elem.doc;
					for(var type of pdi_pass_types) {
						if (Object.keys(doc[type.pdi_type]).length > 0) {
							for(var pass_type of type.pass_types) {
								var pdi = checkCompletedPasses(JSON.parse(JSON.stringify(doc)), pass_type, type.pdi_type, passes);
								result[pass_type.name].push(pdi);
							}
						}
					}
				}

				return resolve(result);

			}).catch((error) => {	// GET PASSES CATCH
				console.error(error);
				return reject(error);
			});
		}).catch((error) => {	// TOILETS ALL DOCS CATCH
			console.error(error);
			return reject(error);
		})
	});
}
