import {anomaliesDB, passesDB} from './config';

export const default_limit = 20;

export function getAnomalies(anomalies_type, start_id, resolved, callback) {
	var selector = {
		type: anomalies_type
	};
	switch (resolved) {
		case true:
			selector['resolved'] = true;
			break;
		case false:
			selector['resolved'] = false;
			break;
		default:
			break;
	}
	if (start_id) {
		selector["_id"] = {
			$lt: start_id
		};
	}
	anomaliesDB.find({
		selector: selector,
		sort: [
			{
				"type": "desc"
			},
			{
				"_id": "desc"
			},
			{
				"resolved": "desc"
			}
		],
		limit: default_limit
	}).then((response) => {
		return callback(undefined, response.docs);
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	});
}

export function setResolvedFlagAnomaly(anomaly_id, mode, callback) {
	anomaliesDB.get(anomaly_id).then((anomaly) => {
		anomaly.resolved = true;
		anomaly.resolve_mode = mode;
		anomaliesDB.put(anomaly).then((response) => {
			return callback(undefined, response);
		});
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	})
}

export function resolveGpsAnomaly(anomaly, pass_gps_obj, mode, callback) {
	var promises = [];
	passesDB.get(anomaly.pass._id).then((pass) => {
		pass.stats_computed = false;
		pass.anomaly.gps = mode;
		if (pass_gps_obj) {
			pass.gps = pass_gps_obj;
		}
		// TODO: change anomaly flag
		passesDB.put(pass).then((response) => {
			setResolvedFlagAnomaly(anomaly._id, mode, (error, response) => {
				if(error) {
					console.log(error);
					return callback(error, undefined);
				} else {
					return callback(undefined, response);
				}
			});
		});
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	});

}

export function getAnomaly(id, callback) {
	anomaliesDB.get(id).then((response) => {
		return callback(undefined, response);
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined)
	})
}
