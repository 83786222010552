import {app} from '../../app';
import {userDB, api_url} from '../config';
var uuid = require("uuid");

const report_url = `${api_url}report/`;

export function getReport(self, time_key, callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(self.$app.data.user.username, {
			metadata: {
					tmp_key: tmpKey
			}
	}, (err, res) => {
		if(err) {
			console.log(err);
				// Error
				return callback(err, undefined);
		}
		self.$app.request({
				url: report_url+time_key,
				method: 'POST',
				dataType: 'json',
				headers: {
						'X-Access-Personal-Key': self.$app.data.user.username+':'+tmpKey
				},
				data: {
					start: self.time_range[0].toISOString(),
					end: self.time_range[1].toISOString()
				},
				error: (xhr, status, message) => {
					console.log(xhr);
					return callback(xhr, undefined);
				},
				success: (data, status, xhr) => {
					return callback(undefined, data);
				}
		});
	});
}
