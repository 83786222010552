import {passesDB, toiletsDB, checksDB, pdi_pass_types} from './config';

export const pagination_limit = 15;
const pdi_types = ['toilets', 'cabins', 'square'];

export function getPasses(toilet_id, present_types, base_selector, bookmarks, result, limit = undefined) {
	return new Promise((resolve, reject) => {
		var promises = [];
		var selectors = {}
		for(var type of present_types) {
			selectors[type] = JSON.parse(JSON.stringify(base_selector));
			var selector = selectors[type];
			if (type === 'toilets') {
				selector["$and"].push({
					"toilet.type": {
						"$in": [type, "sanitization"]
					}
				})
			} else {
				selector["$and"].push({
					"toilet.type": {
						"$in": [type]
					}
				})
			}
			// var index_name = `pagination-index-${type}`;
			var index_name = `pagination-index`;
			if (!limit) {
				limit = pagination_limit;
			}
			var find_obj = {
				selector: selector,
				sort: [
					{
						"_id": "desc"
					},
					{
						"toilet.id": "desc"
					}
				],
				use_index: index_name,
				limit: limit
			};
			if (bookmarks.hasOwnProperty(type)) {
				find_obj['bookmark'] = bookmarks[type];
			}
			promises.push(passesDB.find(find_obj));
		}

		Promise.all(promises).then((values) => {
			result['bookmarks'] = {};
			for(var i = 0; i < values.length; i++) {
				result[`${present_types[i]}_passes`] = values[i].docs;
				result.bookmarks[present_types[i]] = values[i].bookmark;
			}
			return resolve(result);
		}).catch((error) => {
			return reject(error)
		});

	});
}

export function getAllPasses(toilet_id, types, date_range, bookmarks, limit = undefined) {
	return new Promise((resolve, reject) => {
		var selector = {
			"$and": [
				{
					"_id": {
						"$gte": date_range.start
					}
				},
				{
					"_id": {
						"$lte": date_range.end
					}
				}
			]
		};
		var result = {};
		var promise = undefined;
		if (types.length === 0) {
			types = pdi_types;
		}
		if (toilet_id) {
			toiletsDB.get(toilet_id).then((pdi) => {
				var present_types = [];
				for(var type of types) {
					if (Object.keys(pdi[type]).length > 0) {
						present_types.push(type);
					}
				}
				result.toilet = pdi;
				// Selector
				selector["toilet.id"] = toilet_id;
				promise = getPasses(toilet_id, present_types, selector, bookmarks, result);
				promise.then((response) => {
					return resolve(response);
				}).catch((error) => {
					console.error(error);
					return reject(error);
				});
			}).catch((error) => {
				console.error(error);
				return reject(error);
			});
		} else {
			promise = getPasses(toilet_id, types, selector, bookmarks, result);
			promise.then((response) => {
				return resolve(response);
			}).catch((error) => {
				console.error(error);
				return reject(error);
			})
		}
	});
}

export function getPasses_new(toilet_id, present_types, base_selector, bookmarks, result, limit = undefined) {
	return new Promise((resolve, reject) => {
		var promises = [];
		var selectors = {}
		for(var type of present_types) {
			selectors[type] = JSON.parse(JSON.stringify(base_selector));
			var selector = selectors[type];
			selector["$and"].push({
				"toilet.type": {
					"$in": [type]
				}
			})
			// var index_name = `pagination-index-${type}`;
			var index_name = `pagination-index`;
			if (!limit) {
				limit = pagination_limit;
			}
			var find_obj = {
				selector: selector,
				sort: [
					{
						"_id": "desc"
					},
					{
						"toilet.id": "desc"
					}
				],
				use_index: index_name,
				limit: limit
			};
			if (bookmarks.hasOwnProperty(type)) {
				find_obj['bookmark'] = bookmarks[type];
			}
			promises.push(passesDB.find(find_obj));
		}

		Promise.all(promises).then((values) => {
			result['bookmarks'] = {};
			for(var i = 0; i < values.length; i++) {
				result[`${present_types[i]}_passes`] = values[i].docs;
				result.bookmarks[present_types[i]] = values[i].bookmark;
			}
			return resolve(result);
		}).catch((error) => {
			return reject(error)
		});

	});
}

export function getAllPasses_new(toilet_id, types, date_range, bookmarks, limit = undefined) {
	return new Promise((resolve, reject) => {
		var selector = {
			"$and": [
				{
					"_id": {
						"$gte": date_range.start
					}
				},
				{
					"_id": {
						"$lte": date_range.end
					}
				}
			]
		};
		var result = {};
		var present_types = [];
		var promise = undefined;
		if (toilet_id) {
			toiletsDB.get(toilet_id).then((pdi) => {
				if (types.length === 0) {
					for(var type of pdi_pass_types) {
						if (Object.keys(pdi[type.pdi_type]).length > 0) {
							for(var pass_type of type.pass_types) {
								present_types.push(pass_type.name);
							}
						}
					}
				} else {
					present_types = types;
				}
				result.toilet = pdi;
				// Selector
				selector["toilet.id"] = toilet_id;
				promise = getPasses_new(toilet_id, present_types, selector, bookmarks, result, limit);
				promise.then((response) => {
					return resolve(response);
				}).catch((error) => {
					console.error(error);
					return reject(error);
				});
			}).catch((error) => {
				console.error(error);
				return reject(error);
			});
		} else {
			if (types.length === 0) {
				for(var type of pdi_pass_types) {
					for(var pass_type of type.pass_types) {
						present_types.push(pass_type.name);
					}
				}
			} else {
				present_types = types;
			}
			promise = getPasses_new(toilet_id, present_types, selector, bookmarks, result, limit);
			promise.then((response) => {
				return resolve(response);
			}).catch((error) => {
				console.error(error);
				return reject(error);
			})
		}
	});
}

export function getAllChecks(date_range, types, bookmarks) {
	return new Promise((resolve, reject) => {
		var promises = [];
		var selectors = {}
		var base_selector = {
			"$and": [
				{
					"_id": {
						"$gte": date_range.start
					}
				},
				{
					"_id": {
						"$lte": date_range.end
					}
				}
			]
		};
		var result = {};
		var present_types = [];
		if (types.length === 0) {
			for(var type of pdi_pass_types) {
				for(var pass_type of type.pass_types) {
					present_types.push(pass_type.name);
				}
			}
		} else {
			present_types = types;
		}
		// if (types.length === 0) {
		// 	types = pdi_types;
		// }
		for(var type of present_types) {
			selectors[type] = JSON.parse(JSON.stringify(base_selector));
			var selector = selectors[type];
			// if (type === 'toilets') {
			// 	selector["$and"].push({
			// 		"toilet.type": {
			// 			"$in": [type, "sanitization"]
			// 		}
			// 	})
			// } else {
			// 	selector["$and"].push({
			// 		"toilet.type": {
			// 			"$in": [type]
			// 		}
			// 	})
			// }
			selector["$and"].push({
				"toilet.type": {
					"$in": [type]
				}
			});
			var find_obj = {
				selector: selector,
				sort: [
					{
						"_id": "desc"
					},
					{
						"toilet.type": "desc"
					}
				],
				use_index: "pagination-index",
				limit: pagination_limit
			};
			if (bookmarks.hasOwnProperty(type)) {
				find_obj['bookmark'] = bookmarks[type];
			}
			promises.push(checksDB.find(find_obj));
		}

		Promise.all(promises).then((values) => {
			result.bookmarks = {};
			for(var i = 0; i < values.length; i++) {
				result[`${present_types[i]}_checks`] = values[i].docs;
				result.bookmarks[present_types[i]] = values[i].bookmark;
			}
			return resolve(result);
		}).catch((error) => {
			console.error(error);
			return reject(error);
		});
	});
}
