import {deleteuser, putNewUser, putUpdateUser} from "../modules/couchdb-users-utils";

export function newUser(self){
	var userData = self.$app.form.convertToData('#user-form');
	self.$$('.wrong-field').removeClass('wrong-field');
	self.$$('#signup-username-exists').css('display','none');
	self.$$('#signup-username').css('display','block');
	self.$$('#signup-password-not-match').css('display', 'none');
	self.$$('#signup-password').css('display', 'block');
	// Check if all field are provided
	var missing_field = false;
	for (var key in userData) {
		if(!userData[key]) {
			self.$$('#'+key).addClass('wrong-field');
			missing_field = true;
		}
	}
	if(userData.level === 'Operation' && userData.opType.length === 0){
		self.$$('.operation-type').addClass('wrong-field');
		return;
	}
	if (missing_field) return;

	// Check password
	if (userData.password !== userData.passwordConfirm) {
		self.$$('#signup-password-not-match').css('display', 'block');
		self.$$('#signup-password').css('display', 'none');
		self.$$('#password').addClass('wrong-field');
		self.$$('#passwordConfirm').addClass('wrong-field');
		return;
	}

	// All field provided
	putNewUser(userData, (err, res) => {
		if(err) {
			if(err.status === 409){
				// User already exist
				self.$$('#signup-username-exists').css('display','block');
				self.$$('#signup-username').css('display','none');
				self.$$('#username').addClass('wrong-field');
			} else {
				self.$app.dialog.alert("Error di rete!\nRiprova più tardi!");
			}
		} else {
			self.$app.dialog.alert("Utente creato con successo!", () =>{
				self.$router.navigate('/users/');
			});
		}
	});
}

export function updateUser(self){
	var userData = self.$app.form.convertToData('#user-form');

	// Check password if exists
	if (userData.password && (userData.password !== userData.passwordConfirm)) {
		self.$$('#edit-password-not-match').css('display', 'block');
		self.$$('#edit-password').css('display', 'none');
		self.$$('#password').addClass('wrong-field');
		self.$$('#passwordConfirm').addClass('wrong-field');
		return;
	}

	if (userData.level ==='Operation' && userData.opType.length === 0) {
		self.$$('.operation-type').addClass('wrong-field');
		return;
	} else {
		self.$$('.operation-type').removeClass('wrong-field');
	}

	putUpdateUser(userData, (err, res) => {
		if(err) {
			self.$app.dialog.alert("Error di rete!\nRiprova più tardi!");
		} else {
			self.$app.dialog.alert("Utente aggiornato con successo!",() =>{
				self.$router.navigate('/users/');
			});
		}
	});
}

export function removeUser(self) {
	deleteuser(self.user.name, (err, res) => {
		if(err) {
			self.$app.dialog.alert('Errore!\nRiprova più tardi');
		} else {
			self.$app.dialog.alert(self.user.name+' eliminato!', () => {
				self.$router.navigate('/users/');
			});
		}
	});
}
