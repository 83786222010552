
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { removeUser, updateUser } from "../js/utils/user";
export default {
  methods: {
    save() {
      updateUser(this);
    },

    remove() {
      if (this.user.name === this.$app.data.user.username) {
        this.$app.dialog.alert("Non eliminare te stesso!");
        return;
      }

      this.$app.dialog.confirm("Sicuro di voler eliminare " + this.user.name + "?", () => {
        removeUser(this);
      }, () => {});
    }

  },
  on: {
    pageInit: function (page) {
      var self = this;
      this.$$('#' + this.user.roles[0] + '-select').attr('selected', 'true');

      if (this.user.roles[0] == "Operation") {
        this.$$('.operation-type').css('display', 'block');
        this.user.opType.forEach(type => {
          this.$$('#edit-checkbox-' + type).attr('checked', 'checked');
        });
      }

      this.$$('#edit-level-selector').on('change', function (e) {
        console.log(this.value);

        if (this.value === 'Operation') {
          self.$$('.operation-type').css('display', 'block');
        } else {
          self.$$('.operation-type').css('display', 'none');
        }
      });
    }
  },
  id: 'd67bb88f87',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=edit-user-page><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only back navbar-icon-link" data-ignore-cache=true data-force=true><i class="icon f7-icons if-not-md">arrow_left</i> <i class="icon material-icons if-md">arrow_back</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house_fill</i> <i class="icon material-icons if-md">home</i></a><div class=title>Aggiorna ';
      r += c(ctx_1.user.name, ctx_1);
      r += '</div></div><!-- Rigth --><div class=right><a href=# class="link icon-only" @click=remove><i class="icon f7-icons tooltip-init" data-tooltip="Elimina utente">person_crop_circle_badge_minus</i></a></div></div></div><!-- page content --><div class=page-content><form class=list id=user-form><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Username</div><div id=username class=item-input-wrap><input type=text name=username value="';
      r += Template7Helpers.js.call(ctx_1, 'this.user.name', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" readonly=readonly></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Cambia password</div><div id=password class=item-input-wrap><input type=password name=password placeholder=Password></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label" id=edit-password>Conferma Password **</div><div class="item-title item-label title-error" id=edit-password-not-match>Password non coincide **</div><div id=passwordConfirm class=item-input-wrap><input type=password name=passwordConfirm placeholder=Password></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Cambia Nome</div><div id=firstname class=item-input-wrap><input type=text name=firstname placeholder="';
      r += Template7Helpers.js.call(ctx_1, 'this.user.firstname', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Cambia Cognome</div><div id=familyname class=item-input-wrap><input type=text name=familyname placeholder="';
      r += Template7Helpers.js.call(ctx_1, 'this.user.familyname', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Livello</div><div id=level class=item-input-wrap><select name=level id=edit-level-selector><option value=Admin id=Admin-select>Admin</option><option value=Coordinator id=Coordinator-select>Coordinatore</option><option value=Guest id=Guest-select>Ospite</option><option value=Operation id=Operation-select>Operatore</option></select></div></div></div></li><li class=operation-type><div class=block-title>Tipo Operatore ***</div><div class=list><ul><li><label class="item-checkbox item-content"><input type=checkbox name=opType value=toilets id=edit-checkbox-toilets> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Operatore Bagni</div></div></label></li><li><label class="item-checkbox item-content"><input type=checkbox name=opType value=cabins id=edit-checkbox-cabins> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Operatore Cabine</div></div></label></li><li><label class="item-checkbox item-content"><input type=checkbox name=opType value=square id=edit-checkbox-square> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Operatore Piazzali</div></div></label></li><li><label class="item-checkbox item-content"><input type=checkbox name=opType value=sanitization id=edit-checkbox-sanitization> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Operatore Sanificazione Bagni</div></div></label></li></ul></div></li></ul><div class="block block-strong"><div class=btn-inner><a class="button button-fill text-color-black" href=# @click=save()>Aggiorna ';
      r += c(ctx_1.user.name, ctx_1);
      r += '</a></div></div><div class=block-footer><p>** Campo obbligatorio sole se si vuole cambiare la password</p><p class=operation-type>***	Selezionare almeno un tipo di operatore</p></div></form></div></div>';
      return r;
    }(this);
  },

  style: `
	.operation-type {
		display: none;
	}

	.btn-inner {
		width: 50%;
		margin: 0 auto;
	}

	#edit-password-not-match {
		color: indianred;
		display: none;
	}

	.wrong-field {
		border-style: groove;
		border-color: indianred;
		border-width: thin;
		border-radius: 5px;
	}
`,
  styleScoped: false
};
    