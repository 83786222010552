
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { checkPdfReportRequest } from '../../js/utils/reports/pdf-report';
import { pagination_limit, getAllPasses_new } from '../../js/modules/couchdb-pagination-module';
import { monthNames, monthNamesShort, dayNames, dayNamesShort, monthNumber } from '../../js/modules/config';
import { setPassDateFilter } from '../../js/utils/date-filter';
import { setPassesInfiniteScrool } from '../../js/utils/pagination-util';
import { setResizeEvent } from '../../js/utils/resize-util';
export default {
  methods: {
    openPass(id, type) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation()) e.stopPropagation();
      this.$router.navigate('/pass/' + id + '/' + type + '/');
    },

    openToilet(id, type) {
      if (!e) var e = window.event;
      e.cancelBubble = true;
      if (e.stopPropagation()) e.stopPropagation();
      this.$router.navigate('/toilet/' + id + '/' + type, {
        reloadCurrent: true,
        ignoreCache: true
      });
    },

    getPdfReport() {
      checkPdfReportRequest(this);
    },

    openCalendar() {
      var self = this;
      var calendar = this.$app.calendar.create({
        openIn: "customModal",
        backdrop: true,
        cssClass: 'theme-dark popover-init',
        monthNames: monthNames,
        monthNamesShort: monthNamesShort,
        dayNames: dayNames,
        dayNamesShort: dayNamesShort,
        rangePicker: true,
        rangePickerMaxDays: 31,
        maxDate: new Date(),
        minDate: new Date(2021, 0, 18),
        on: {
          closed: function (calendar) {
            if (!calendar.value) {
              return;
            }

            var start = calendar.value[0];
            var end = calendar.value[1];

            if (!end) {
              end = new Date(start);
            }

            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            end.setMilliseconds(999);
            self.$app.dialog.confirm(`Impostare il filtro dal ${start.getDate()}/${monthNumber[start.getMonth()]}/${start.getFullYear()} al ${end.getDate()}/${monthNumber[end.getMonth()]}/${end.getFullYear()}?`, () => {
              setPassDateFilter(start, end, self);
            });
          }
        }
      });
      calendar.open();
    }

  },
  on: {
    pageInit: function (page) {
      var self = this; // Resize event

      setResizeEvent(self); // Set tabs active if complete list of passes

      if (!this.backVisible) {
        this.$app.tab.show('#tab-toilets-passes', '#link-tab-toilets-passes');
      } else {
        this.$app.tab.show('#tab-' + this.type + '-passes', '#link-tab-' + this.type + '-passes');
      }

      for (var pdi_type of self.pdi_pass_types) {
        for (var pass_type of pdi_type.pass_types) {
          // Searchbars init
          this.$app.searchbar.create({
            el: `.search-${pass_type.name}-passes`,
            searchContainer: `.searchbar-found-${pass_type.name}-passes`,
            notFoundEl: `.searchbar-not-found-${pass_type.name}-passes`,
            searchIn: [`.item-search-${pass_type.name}-passes`]
          });
        }
      }

      setPassesInfiniteScrool(self);
    }
  },
  id: '0a2ff89c9d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="passes-page">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n\n				<!-- Left -->\n				<div class="left">\n					';
      r += Template7Helpers.if.call(ctx_1, ctx_1.backVisible, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only back navbar-icon-link" data-ignore-cache="true" data-force="true">\n						<i class="icon f7-icons">arrow_left</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">Passaggi ';
          r += c(ctx_2.toilet.name, ctx_2);
          r += ' ';
          r += c(ctx_2.date_range_title, ctx_2);
          r += '</div>\n					';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="#" class="link icon-only panel-open navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n							<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">Lista Passaggi ';
          r += c(ctx_2.date_range_title, ctx_2);
          r += '</div>\n					';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n\n				</div>\n\n				<!-- Rigth -->\n				<div class="right">\n					<a href="#" class="icon-only link" @click="openCalendar()">\n						<i class="f7-icons icon text-color-lightblue tooltip-init">\n							calendar\n						</i>\n					</a>\n					';
      r += Template7Helpers.js_if.call(ctx_1, '@root.backVisible && @root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a href="/edit-toilet/';
          r += c(ctx_2.toilet._id, ctx_2);
          r += '" class="link icon-only">\n						<i class="icon f7-icons tooltip-init"\n						   data-tooltip="Modifica">\n							pencil_circle_fill\n						</i>\n					</a>\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a href="#" class="link icon-only" @click="getPdfReport()">\n						<i class="icon f7-icons tooltip-init" data-tooltip="Scarica PDF report">\n							arrow_down_doc_fill\n						</i>\n				</a>\n				</div>\n			</div>\n		</div>\n		<!-- End navbar -->\n\n		<!-- Tab Switch -->\n		<div class="toolbar toolbar-top tabbar">\n			<div class="toolbar-inner">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<a href="#tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes" class="tab-link" id="link-tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes">\n					';
              r += Template7Helpers.js_if.call(ctx_3, 'window.innerWidth <= 800 && this.hasOwnProperty("title_short")', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title_short', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n						';
                  r += Template7Helpers.js.call(ctx_4, 'this.title', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n					';
                  return r;
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n				</a>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n		<!-- END Tab switch -->\n\n\n		<div class="tabs-animated-wrap">\n			<!-- Tabs Views -->\n			<div class="tabs">\n				';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi_pass_types, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n				';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pass_types, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n				<div class="page-content tab infinite-scroll-content" id="tab-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes">\n					<!-- Searchbar -->\n					<div class="searchbar-div">\n						<form class="searchbar search-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes">\n							<div class="searchbar-inner">\n								<div class="searchbar-input-wrap">\n									<input type="search" placeholder="Cerca Passaggi Su ';
              r += c(ctx_3.title, ctx_3);
              r += '...">\n									<i class="searchbar-icon"></i>\n									<span class="input-clear-button"></span>\n								</div>\n								<span class="searchbar-disable-button if-not-aurora">Annulla</span>\n							</div>\n						</form>\n					</div>\n					<!-- End Searchbar -->\n					<!-- Searchbar backdrop -->\n					<div class="searchbar-backdrop"></div>\n					<div class="list media-list searchbar-found-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes searchbar-found-div">\n						<ul>\n							';
              r += Template7Helpers.each.call(ctx_3, ctx_3.elems, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n							<li>\n								<a href="#" class="item-content item-link" @click="openPass(\'';
                  r += c(ctx_4._id, ctx_4);
                  r += '\', \'';
                  r += c(ctx_3.name, ctx_4);
                  r += '\')">\n									<div class="item-inner">\n										<div class="item-title-row">\n											<div class="item-title">\n												Passaggio del\n												<span class="text-color-white item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes">\n													';
                  r += c(ctx_4.time.start, ctx_4);
                  r += '\n												</span>\n												<span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes" hidden>';
                  r += c(ctx_4._id, ctx_4);
                  r += '</span>\n											</div>\n											';
                  r += Template7Helpers.js_if.call(ctx_4, '!this.time.end', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n											<div class="item-after">\n												<i class="icon f7-icons text-color-lightblue tooltip-init"\n												   data-tooltip="Pulizia in corso!">\n													tornado\n												</i>\n											</div>\n											<span class="item-search-';
                      r += c(ctx_3.name, ctx_5);
                      r += '-passes" hidden>\n												in corso aperti aperto\n											</span>\n											';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n											<span class="item-search-';
                      r += c(ctx_3.name, ctx_5);
                      r += '-passes" hidden>\n												completati completi chiusi\n											</span>\n											';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n										</div>\n										<div class="item-subtitle item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes" >\n											Punto di Interesse:\n											<span class=" item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes text-color-lightblue" @click="openToilet(\'';
                  r += c(ctx_4.toilet.id, ctx_4);
                  r += '\',\'';
                  r += c(ctx_3.name, ctx_4);
                  r += '\')">\n												';
                  r += c(ctx_4.toilet.name, ctx_4);
                  r += '\n											</span>\n										</div>\n										<div class="item-subtitle">\n											Tipologia: <span class="item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes">';
                  r += c(ctx_3.title, ctx_4);
                  r += '</span>\n										</div>\n										<div class="item-subtitle item-search-';
                  r += c(ctx_3.name, ctx_4);
                  r += '-passes">\n											Eseguito da ';
                  r += c(ctx_4.user.firstname, ctx_4);
                  r += ' ';
                  r += c(ctx_4.user.familyname, ctx_4);
                  r += '\n										</div>\n										';
                  r += Template7Helpers.if.call(ctx_4, ctx_4.time.end, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '\n										<div class="item-text">\n											Chiuso il <span class="item-search-';
                      r += c(ctx_4.name, ctx_5);
                      r += '-passes">';
                      r += c(ctx_5.time.end, ctx_5);
                      r += '</span>\n										</div>\n										';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '\n									</div>\n								</a>\n							</li>\n							';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n						</ul>\n					</div>\n\n					<!-- Searchbar nor found-->\n					<div class="list media-list searchbar-not-found searchbar-not-found-';
              r += c(ctx_3.name, ctx_3);
              r += '-passes">\n						<ul>\n							<li>\n								<div class="item-inner">\n									<div class="item-title-row">\n										<div class="item-title text-color-yellow">\n											Nessun passaggio su ';
              r += c(ctx_3.title, ctx_3);
              r += ' Trovato\n										</div>\n									</div>\n								</div>\n							</li>\n						</ul>\n					</div>\n\n					<!-- Infinite scrool preloader -->\n					<div class="preloader infinite-scroll-preloader" id="';
              r += c(ctx_3.name, ctx_3);
              r += '-infinite-preloader"></div>\n				</div>\n				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n				';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			</div>\n		</div>\n\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    