
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { userLogout } from './js/modules/couchdb-auth-utils';
export default {
  methods: {
    logout(e) {
      userLogout(this);
    }

  },
  on: {
    pageInit: function (page) {
      this.$setState();
    }
  },
  id: 'd5a202b833',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Left panel --><div class="panel panel-left panel-cover"><div class="view view-init" data-name=left><div class=page><!-- panel navbar --><div class="navbar theme-dark"><div class=navbar-bg></div><div class="navbar-inner sliding"><div class="title main-left-title">';
      r += c(ctx_1.$app.name, ctx_1);
      r += '</div><div class=right><img id=clean-app-logo-navbar src=../static/icons/logo.png></div></div></div><div class="subnavbar panel-subnavbar"><div class="subnavbar-inner gis-logo-div-navbar"><a class=external href=https://www.gestioneintegrataservizi.it/home/ target=_blank><img class=gis-logo-img src=static/icons/BNorfano.png></a></div></div><!-- panel footer --><div class="toolbar toolbar-bottom theme-dark"><div class=toolbar-inner class=powered-by><div></div><div class=powered-by-panel><span>Powered by</span> <a class=external href=https://www.arpsoft.it/en/ target=_blank><img class=powered-logo-panel src=../static/icons/arpsoft-logo-white.png></a></div></div><div class=clean-app-version><span class=text-color-black>ver ';
      r += c(ctx_1.$app.data.version.number, ctx_1);
      r += '</span></div></div><div class="page-content panel-content-list"><div class=block-title>Home</div><div class="list links-list"><ul><li><a href=/ data-view=.view-main class=panel-close>Lista PDI</a></li><li><a href=/passes/ data-view=.view-main class=panel-close>Lista Passaggi</a></li><li><a href=/checks/ data-view=.view-main class=panel-close>Lista Controlli</a></li></ul></div><div class=block-title>Reportistica</div><div class="list links-list"><ul><li><a href=/reports/ data-view=.view-main class=panel-close>Ottieni Report</a></li>';
      r += Template7Helpers.js_if.call(ctx_1, '@root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/pdi-stats/ data-view=.view-main class=panel-close>Statistiche PDI</a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div>';
      r += Template7Helpers.js_if.call(ctx_1, '@root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=block-title>Gestione Anomalie</div><!-- <div class="block-title">Visualizza Anomalie</div> --><div class="list links-list"><ul><li><a href=/anomalies/gps data-view=.view-main class=panel-close>Anomalie GPS</a></li><li><a href=/anomalies/time data-view=.view-main class=panel-close>Anomalie Durate</a></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, '@root.$app.data.user.role==="Admin" || @root.$app.data.user.role==="Coordinator"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=block-title>Amministrazione</div><div class="list links-list"><ul><li><a href=/toilets/ data-view=.view-main class=panel-close>Gestisci PDI</a></li>';
          r += Template7Helpers.js_if.call(ctx_2, '@root.$app.data.user.role==="Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=/users/ data-view=.view-main class=panel-close>Gestisci Utenti</a></li><li><a href=/manage-thresholds/ data-view=.view-main class=panel-close>Gestisci Soglie Anomalie</a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<li><a href=/add-pass/ data-view=.view-main class=panel-close>Aggiungi Passaggio</a></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=block-title>';
      r += c(ctx_1.$app.data.user.username, ctx_1);
      r += '</div><div class="list links-list"><ul>';
      r += Template7Helpers.js_if.call(ctx_1, '@root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/user-notification/ data-view=.view-main class=panel-close>Gestisci Notifiche</a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<li><a @click=logout class=panel-close><b>Logout</b></a></li></ul></div></div></div></div></div><!-- Your main view, should have "view-main" class --><div class="view view-main view-init safe-areas theme-dark" data-url=/ ></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    