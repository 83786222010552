var axios = require('axios');

const google_maps_api_key = 'AIzaSyDxK5180RJZW4fcHencZSKHMtD0fPBhwcI';

export function openDirectionMap(lat1, lon1, lat2, lon2) {
	const address = `https://www.google.com/maps/dir/?api=1&origin=${lat1},${lon1}&destination=${lat2},${lon2}`;
	window.open(address);
}

export function openMapUtil(lat, lon) {
	const address = 'https://www.google.com/maps?q='+lat+','+lon;
	window.open(address);
}

export function getGpsFromAddress(address, callback){

	let xhr = new XMLHttpRequest();
	// open a connection

	let gpsggurl = 'https://maps.googleapis.com/maps/api/geocode/json?address='+ address.replace(/ /g, '+') +
	'&key=AIzaSyDxK5180RJZW4fcHencZSKHMtD0fPBhwcI';

	xhr.open("GET", gpsggurl);

	xhr.onload = function () {
		if (xhr.readyState === 4 && xhr.status === 200) {
			let resp = JSON.parse(xhr.responseText);
			if(resp.status == 'OK') {
				let gps = {
					lat: resp.results[0].geometry.location.lat,
					lon: resp.results[0].geometry.location.lng
				};
				callback(undefined, gps);
			}else{
				console.log('errore risposta ggapi');
				callback(true, undefined);
			};
		}else{
			console.log('errore connessione ggapi');
			callback(true, undefined);
		}
	};

	xhr.onerror = function () {
		console.log('errore connessione ggapi');
		callback('err');
	};

	xhr.send();
}

// https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=YOUR_API_KEY
export function getAddressFromGps(lat, lon, callback) {
	axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${google_maps_api_key}`)
		.then((response) => {
			console.log(response);
			return callback(undefined, response);
		}).catch((error) => {
			console.log(error);
			return callback(error, undefined);
		});
}
