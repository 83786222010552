import {
	toiletsDB
} from './config';

export function putPdi(pdi, new_pdi) {
	return new Promise(async (resolve, reject) => {
		if (!new_pdi) {
			var response;
			try {
				response = await toiletsDB.get(pdi._id);
			} catch (e) {
				return reject(e);
			}
			pdi._rev = response._rev;
		}
		toiletsDB.put(pdi).then((response) => {
			return resolve(response);
		}).catch((error) => {
			console.error(error);
			return reject(error);
		});
	});
}

export function deletePdi(pdi) {
	return new Promise((resolve, reject) => {
		toiletsDB.remove(pdi).then((response) => {
			return resolve(response);
		}).catch((error) => {
			console.error(error);
			return reject(error);
		});
	});
}
