import {app} from '../app';
import {toiletsDB, passesDB, checksDB} from './config';

function getDailyPasses(callback) {
	var day = new Date();
	day.setUTCHours(0, 0, 0, 0);
	var now = new Date();
	passesDB.allDocs({
		startkey: now.toISOString(),
		endkey: day.toISOString(),
		include_docs: true,
		descending: true
	}, (err, res) => {
		if (err) {
			return callback(err, undefined);
		}
		callback(undefined, res.rows);
	});
}

function linkPoiToPasses(poi, passes, type) {
	var dayIndex = (new Date()).getDay();
	for (var i in poi) {
		poi[i].doc[type].completedDailyPasses = passes.filter( (elem) => {
			return elem.doc.toilet.id === poi[i].doc._id && elem.doc.toilet.type.includes(type);
		});
		poi[i].doc[type].dailyPasses = [];
		for (var j = 0; j < poi[i].doc[type].completedDailyPasses.length && j < poi[i].doc[type].wpass[dayIndex]; j++) {
			if(poi[i].doc[type].completedDailyPasses[j].doc.time.end) {
				poi[i].doc[type].dailyPasses.unshift(1);	// Complete Pass
			} else {
				poi[i].doc[type].dailyPasses.unshift(2);	// Uncomplete Pass
			}
		}
		var offset = (poi[i].doc[type].wpass[dayIndex] - poi[i].doc[type].dailyPasses.length);
		for (var j = 0;  j < offset ; j++) {
			poi[i].doc[type].dailyPasses.unshift(0);	// Missing Pass
		}

		offset = (poi[i].doc[type].completedDailyPasses.length - poi[i].doc[type].wpass[dayIndex]);
		var actual_length =  poi[i].doc[type].dailyPasses.length;

		for (var j = 0;  j < offset ; j++) {
			if(poi[i].doc[type].completedDailyPasses[j + actual_length].doc.time.end) {
				poi[i].doc[type].dailyPasses.unshift(3);	// Extra Complete Pass
			} else {
				poi[i].doc[type].dailyPasses.unshift(4);	// Extra Uncomplete Pass
			}
		}

	}
}

function linkToiletsToSanitization(toilets, res) {
	for (var i in toilets) {
		if (toilets[i].doc.toilets.night_sanitization === (new Date()).getDay()) {
			var sanitizations = res.filter((elem) => {
				return elem.doc.toilet.id === toilets[i].doc._id && elem.doc.toilet.type.includes('sanitization');
			});
			for (var j in sanitizations) {
				if (sanitizations[j].doc.time.end) {
					toilets[i].doc.toilets.sanitization_completed = true;
					return;
				}
			}
			toilets[i].doc.toilets.sanitization_completed = false;
		}
	}
}

export function getAllToiletsWithDailyPasses(callback) {
	getDailyPasses((err, res) => {
		if(err) {
			callback(err, undefined);
		}
		toiletsDB.allDocs({include_docs: true}, (error, response) => {
			if (error) {
				return callback(error, undefined);
			}
			response = response.rows;
			var result = {};
			result.toilets = response.filter( (elem) => {
				return Object.keys(elem.doc.toilets).length !== 0;
			});
			result.cabins = response.filter( (elem) => {
				return Object.keys(elem.doc.cabins).length !== 0;
			});
			result.square = response.filter( (elem) => {
				return Object.keys(elem.doc.square).length !== 0;
			});
			linkPoiToPasses(result.toilets, res, 'toilets');
			linkToiletsToSanitization(result.toilets, res);
			linkPoiToPasses(result.cabins, res, 'cabins');
			linkPoiToPasses(result.square, res, 'square');

			callback (undefined, result);
		});
	});
}

export function getAllToilets(callback) {
	toiletsDB.allDocs({include_docs:true}, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		return callback(undefined, res.rows);
	});
}

export function getToilet(toiletId, callback) {
	toiletsDB.get(toiletId, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		return callback(undefined, res);
	});
}

export function getPassesByStep(toiletID, startkey = undefined, callback) {
	var options = {};
	if (startkey) {
		options = {
			include_docs:true,
			descending: true,
			startkey: startkey,
			limit: 100
		};
	} else {
		options = {
			include_docs:true,
			descending: true,
			limit: 100
		};
	}
	passesDB.allDocs(options, (err, res) =>{
		if (err){
			return callback(err, undefined);
		}
		res = res.rows.filter((elem) => {
			return elem.doc.toilet.id === toiletID;
		});
		var result = {};
		result.toilets_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
		});
		result.cabins_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('cabins');
		});
		result.square_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('square');
		});
		getToilet(toiletID, (error, toilet) => {
			if(error) {
				return callback(error, undefined);
			}
			result.toilet = toilet;
			return callback(undefined, result);
		});

	});
}

export function getPassesByTimeRange(toiletID, start_time, end_time, callback) {
		passesDB.allDocs({
			include_docs:true,
			descending: true,
			startkey: start_time.toISOString(),
			endkey:end_time.toISOString()
		}, (err, res) =>{
			if (err){
				return callback(err, undefined);
			}
			res = res.rows.filter((elem) => {
				return elem.doc.toilet.id === toiletID;
			});
			var result = {};
			result.toilets_passes = res.filter((elem) => {
				return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
			});
			result.cabins_passes = res.filter((elem) => {
				return elem.doc.toilet.type.includes('cabins');
			});
			result.square_passes = res.filter((elem) => {
				return elem.doc.toilet.type.includes('square');
			});
			getToilet(toiletID, (error, toilet) => {
				if(error) {
					return callback(error, undefined);
				}
				result.toilet = toilet;
				return callback(undefined, result);
			});

		});
}

export function getPasses(toiletID, callback) {
	passesDB.allDocs({
		include_docs:true,
		descending: true
	}, (err, res) =>{
		if (err){
			return callback(err, undefined);
		}
		res = res.rows.filter((elem) => {
			return elem.doc.toilet.id === toiletID;
		});
		var result = {};
		result.toilets_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
		});
		result.cabins_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('cabins');
		});
		result.square_passes = res.filter((elem) => {
			return elem.doc.toilet.type.includes('square');
		});
		getToilet(toiletID, (error, toilet) => {
			if(error) {
				return callback(error, undefined);
			}
			result.toilet = toilet;
			return callback(undefined, result);
		});

	});
}

export function getAllPassesByTimeRange(start_time, end_time, callback) {
	passesDB.allDocs({
		include_docs:true,
		descending: true,
		startkey: start_time.toISOString(),
		endkey:end_time.toISOString()
	}, (err, res) =>{
		if (err){
			return callback(err, undefined);
		}
		var result = {};
		result.toilets_passes = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
		});
		result.cabins_passes = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('cabins');
		});
		result.square_passes = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('square');
		});
		callback(undefined, result);
	});
}

function getPass(passID, callback = undefined) {
	if (callback) {
		passesDB.get(passID, (err, res) => {
			if(err) {
				return callback(err, undefined);
			}
			return callback(undefined, res);
		});
	} else {
		return passesDB.get(passID);
	}
}

export function getChecks(passID, callback) {
	checksDB.allDocs({
		include_docs: true,
		descending:true
	}, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		res = res.rows.filter((elem) => {
			return elem.doc.pass.id === passID;
		});
		var result = {};
		result.toilets_checks = res.filter((elem) => {
			return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
		});
		result.cabins_checks = res.filter((elem) => {
			return elem.doc.toilet.type.includes('cabins');
		});
		result.square_checks = res.filter((elem) => {
			return elem.doc.toilet.type.includes('square');
		});

		getPass(passID, (error, pass) => {
			if(error) {
				return callback(error, undefined);
			}
			result.pass = pass;
			getToilet(pass.toilet.id, (error2, toilet) => {
				if(error2) {
					return callback(error2, undefined);
				}
				result.toilet = toilet;
				return callback(undefined, result);
			});
		});
	});
}

export function getAllChecksByTimeRange(start_time, end_time, callback) {
	checksDB.allDocs({
		include_docs:true,
		descending: true,
		startkey: start_time.toISOString(),
		endkey:end_time.toISOString()
	}, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		var result = {};
		result.toilets_checks = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('toilets') || elem.doc.toilet.type.includes('sanitization');
		});
		result.cabins_checks = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('cabins');
		});
		result.square_checks = res.rows.filter((elem) => {
			return elem.doc.toilet.type.includes('square');
		});
		callback(undefined, result);
	});
}

export function getCheck(checkID, callback) {
	checksDB.get(checkID, {
		attachments: true
	}, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		callback(undefined, res);
	});
}

export function putToilet (toilet, callback) {
	toiletsDB.put(toilet, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		return callback(undefined, res);
	});
}

export function deleteToilet(toilet, callback) {
	toiletsDB.remove(toilet, (err, res) => {
		if(err) {
			return callback(err, undefined);
		}
		return callback(undefined, res);
	});
}
