
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { refactorPdis } from '../../js/db-refactor/pdi-refactor';
export default {
  methods: {
    addToilet() {
      var nextID = parseInt(this.toilets[this.toilets.length - 1].id.substr(1)) + 1;

      if (nextID < 10) {
        nextID = 'B00' + nextID;
      } else if (nextID < 100) {
        nextID = 'B0' + nextID;
      } else if (nextID < 1000) {
        nextID = 'B' + nextID;
      }

      this.$router.navigate('/add-toilet/' + nextID);
    },

    openToilet(id) {
      this.$router.navigate('/edit-toilet/' + id);
    },

    refactor: function () {
      refactorPdis(this);
    }
  },
  on: {
    pageInit: function (page) {
      //Initialize search bar
      this.$app.searchbar.create({
        el: '.search-manage-toilets',
        searchContainer: '.searchbar-found-manage-toilets',
        searchIn: ['.item-search-manage-toilets']
      });
    }
  },
  id: '654790d39a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=toilets-edit-list><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only panel-open navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Gestisci Bagni</div></div><!-- Rigth --><div class=right><!--\n					<a href="#" class="link icon-only" @click="refactor">\n						<i class="icon f7-icons">\n							flame\n						</i>\n					</a>\n					--> <a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill </i></a>';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role === "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=# class="link icon-only" @click=addToilet><i class="icon f7-icons tooltip-init" data-tooltip="Aggiungi Bagno">plus_circle_fill </i></a>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><!-- Searchbar --><div class=subnavbar><form class="searchbar search-manage-toilets"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Bagni..."> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Annulla</span></div></form></div><!-- End Searchbar --></div></div><!-- End navbar --><!-- Scrollable page content --><div class=page-content><!-- Searchbar backdrop--><div class=searchbar-backdrop></div><!-- The big list of bagni --><div class="list searchbar-found-manage-toilets"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.toilets, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# class="item-link item-content" @click="openToilet(\'';
          r += c(ctx_2.doc._id, ctx_2);
          r += '\')"><div class=item-media><i class="f7-icons text-color-yellow">pencil_outline</i></div><div class=item-inner><div class="item-title item-search-manage-toilets">';
          r += c(ctx_2.doc._id, ctx_2);
          r += ' - ';
          r += c(ctx_2.doc.name, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="block searchbar-not-found"><div class=block-inner>Nessun bagno trovato</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    