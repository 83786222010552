import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import {customTemplate7reg} from './custom-template7-helpers';

// REGISTER CUSTOM TEMPLATE7 HELPERS
customTemplate7reg();

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

export var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  name: 'CLeO Dashboard', // App name
  theme: 'auto', // Automatic theme detection
	panel: {
    swipe: false
  },

	data: function() {
		return {
			user: {
				username: '',
				role: ''
			},
			version: {
				number: '1.3.6'
			}
		};
	},

  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
});
