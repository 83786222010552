import { saveThresholdsUtils } from '../js/utils/thresholds-util';
export default {
  methods: {
    updateLowLimit: function (e) {
      var insertedValue = Number(e.target.value);

      if (insertedValue >= 0 && insertedValue <= 100) {
        this.thresholds.elapsed_time_percentage_low = insertedValue;
      } else {
        e.target.value = this.thresholds.elapsed_time_percentage_low;
      }
    },
    updateHighLimit: function (e) {
      var insertedValue = Number(e.target.value);

      if (insertedValue >= 0 && insertedValue <= 100) {
        this.thresholds.elapsed_time_percentage_high = insertedValue;
      } else {
        e.target.value = this.thresholds.elapsed_time_percentage_high;
      }
    },
    updateGpsAccuracy: function (e) {
      var insertedValue = Number(e.target.value);

      if (insertedValue >= 0) {
        this.thresholds.gps_accuracy = insertedValue;
      } else {
        e.target.value = this.thresholds.gps_accuracy;
      }
    },
    updateGpsDistance: function (e) {
      var insertedValue = Number(e.target.value);

      if (insertedValue >= 0) {
        this.thresholds.gps_distance = insertedValue;
      } else {
        e.target.value = this.thresholds.gps_distance;
      }
    },
    saveThresholds: function (e) {
      saveThresholdsUtils(this);
    }
  },
  on: {
    pageInit: function (page) {}
  },
  id: '1dcadd7e38',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=pdi-stats-page><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only panel-open navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Gestisci le Soglie per le Anomalie</div></div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><div class=page-content><div class=block-title>Soglie per il GPS</div><div class="list inset inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-media><i class="icon f7-icons text-color-lightblue">placemark</i></div><div class=item-inner><div class="item-title item-label">Distanza massima [metri]</div><div class=item-input-wrap><input type=number placeholder="Inserisci la distanza massima in metri..." value=';
      r += c(ctx_1.thresholds.gps_distance, ctx_1);
      r += ' @change=updateGpsDistance></div></div></li><li class="item-content item-input"><div class=item-media><i class="icon f7-icons text-color-lightblue">map_pin</i></div><div class=item-inner><div class="item-title item-label">Accuratezza massima [metri]</div><div class=item-input-wrap><input type=number placeholder="Inserisci l\'accuratezza massima in metri..." value=';
      r += c(ctx_1.thresholds.gps_accuracy, ctx_1);
      r += ' @change=updateGpsAccuracy></div></div></li></ul></div><div class=block-title>Soglie per le durate</div><div class="list inset inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-media><i class="icon f7-icons text-color-lightblue">arrow_up_to_line</i></div><div class=item-inner><div class="item-title item-label">Limite superiore durata passaggio [percentuale]</div><div class=item-input-wrap><input type=number placeholder="Inserisci limite superiore in percentuale..." value=';
      r += c(ctx_1.thresholds.elapsed_time_percentage_high, ctx_1);
      r += ' @change=updateHighLimit></div></div></li><li class="item-content item-input"><div class=item-media><i class="icon f7-icons text-color-lightblue">arrow_down_to_line</i></div><div class=item-inner><div class="item-title item-label">Limite inferiore durata passaggio [percentuale]</div><div class=item-input-wrap><input type=number placeholder="Inserisci limite inferiore in percentuale..." value=';
      r += c(ctx_1.thresholds.elapsed_time_percentage_low, ctx_1);
      r += ' @change=updateLowLimit></div></div></li></ul></div><div class="list inset inline-labels no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class=item-input-wrap><div class=button @click=saveThresholds>Salva le Modifiche!</div></div></div></li></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};