
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { loginUtil } from '../js/utils/login';
export default {
  data: function () {
    return {
      landscape: false
    };
  },
  on: {
    pageInit() {
      this.$$('input').on('keypress', e => {
        if (e.which === 13) {
          this.performLogin();
        }
      });
      /*
              // Handle orientation
              this.$app.on('orientationchange', () => {
                  if (Object.keys(this).length === 0) return;
                  if (this.$$(window)[0].orientation === 0) {
                      this.landscape = false;
                  } else if (this.$$(window)[0].orientation === 90){
                      this.landscape = true;
                  }
                  this.$setState();
              });
              if (this.$$(window)[0].orientation === 0) {
                  this.landscape = false;
              } else if (this.$$(window)[0].orientation === 90){
                  this.landscape = true;
              }
              this.$setState();
      */
    }

  },
  methods: {
    updateUsername(e) {
      this.username = e.target.value;
      this.$update();
    },

    updatePassword(e) {
      this.password = e.target.value;
      this.$update();
    },

    updateCheckbox(e) {
      this.remember = e.target.checked;
    },

    performLogin() {
      loginUtil(this);
    }

  },
  id: '2aee2bc7a8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-swipeback" data-name=login-page id=login-page-id><!-- Footer --><div class="toolbar toolbar-bottom"><div class=toolbar-inner class=powered-by><div></div><div class=powered-by><span>Powered by</span> <a class=external href=https://www.arpsoft.it/en/ target=_blank><img class=powered-logo src=../static/icons/arpsoft-logo-white.png></a></div></div><!--\n			';
      r += Template7Helpers.if.call(ctx_1, ctx_1.landscape, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="gis-logo-login-div-landscape">\n				<a class="external" href="https://www.gestioneintegrataservizi.it/home/" target="_blank">\n					<img class="gis-logo-login" src="../static/icons/logo-gis-DOWN-black.svg">\n				</a>\n			</div>\n			';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n			<div class="gis-logo-login-div-portrait">\n				<a class="external" href="https://www.gestioneintegrataservizi.it/home/" target="_blank">\n					<img class="gis-logo-login" src="../static/icons/logo-gis-originale-black.svg">\n				</a>\n			</div>\n			';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n			--></div><div class="page-content login-screen-content"><div class=login-screen-title><img src=../static/icons/logo.png class=login-logo> <span>';
      r += c(ctx_1.$app.name, ctx_1);
      r += '</span></div><div class=list><div class="block login-error" id=network-error-login><span class=network-error-message>Errore di rete! Riprova più tardi.</span></div><div class="block login-error" id=invalid-credentials-id><span class=invalid-credentials-message>Username o password errati!</span></div><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label" id=username-input-title>Username</div><div class=input-missing-message id=username-missing-message>Username non inserito!</div><div class=item-input-wrap id=login-username-input><input type=text name=username placeholder="Your username" @input=updateUsername value=';
      r += c(ctx_1.username, ctx_1);
      r += '></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label" id=password-input-title>Password</div><div class=input-missing-message id=password-missing-message>Password non inserita!</div><div class=item-input-wrap id=login-password-input><input type=password name=password placeholder="Your password" id=login-password-value @input=updatePassword value=';
      r += c(ctx_1.password, ctx_1);
      r += '></div></div></li><!--\n					<li class="item-content item-input">\n						<div class="item-inner">\n							<label class="item-checkbox item-content">\n								<input type="checkbox" name="remember"\n								       @change="updateCheckbox" value="';
      r += c(ctx_1.remember, ctx_1);
      r += '">\n								<i class="icon icon-checkbox"></i>\n								<div class="item-label">Ricorda le mie credenziali</div>\n							</label>\n						</div>\n					</li>\n					--></ul></div><div class=list><ul><li><a href=# class="item-link list-button login-button" @click=performLogin>Login</a></li><div class=block-footer>Clicca per connetterti a CLeO - Clean Operation Assistant ver ';
      r += c(ctx_1.$app.data.version.number, ctx_1);
      r += '</div></ul><!--\n				<div class="block-footer">\n					Password dimenticata? <a href="#">Recupera Password</a><br>\n				</div>\n				--><div class="block-footer gis-logo-login-div"><a class=external href=https://www.gestioneintegrataservizi.it/home/ target=_blank><img class=gis-logo-login src=../static/icons/logo-gis-originale-black.svg></a></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    