import {app} from '../app';
import {remoteDBroot} from './config';

export function login(self){
	remoteDBroot.logIn(self.username, self.password, (err, res) => {
		if (!err) {     // No error
			if (!res.roles.includes('Operation')) {
				self.$app.data.user.username = res.name;
				self.$app.data.user.role = res.roles[0];
				self.$router.navigate({name: 'home'}, {reloadCurrent: true});
			} else {
				self.$$('#login-password-value').val('');
				self.password = undefined;
				self.$$('#invalid-credentials-id').css('display', 'flex');
			}
		}else{          // Error
			self.$$('#login-password-value').val('');
			self.password = undefined;
			if (err.status === 401 || err.name === 'forbidden'){
				self.$$('#invalid-credentials-id').css('display', 'flex');
			} else {
				self.$$('#network-error-login').css('display', 'flex');
			}
		}
	});
}

export async function isUserAuthenticated(callback) {
	const isLoggedIn = await remoteDBroot.getSession((err, res) =>{});
	if (!isLoggedIn.ok) {
		// Network error
		return callback(false);
	}
	if (!isLoggedIn.userCtx.name) {
		// nobody's logged in
		return callback(false);
	} else {
		// Logged in
		if (isLoggedIn.userCtx.roles.includes('Operation')) {
			return callback(false);
		}
		app.data.user.role = isLoggedIn.userCtx.roles[0];
		app.data.user.username = isLoggedIn.userCtx.name;
		return callback(true);
	}
}

export function getSelf(username, callback) {
	remoteDBroot.getUser(username).then((response) => {
		return callback(undefined, response);
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	})
}

export async function isUserAuthenticatedAndAdmin(callback) {
	const isLoggedIn = await remoteDBroot.getSession((err, res) =>{});
	if (!isLoggedIn.ok) {
			// Network error
			return callback(false, false);
	}
	if (!isLoggedIn.userCtx.name) {
		// nobody's logged in
		return callback(false, false);
	}else {
		// Logged in
		if (isLoggedIn.userCtx.roles.includes('Operation')) {
			return callback(false, false);
		}
		app.data.user.role = isLoggedIn.userCtx.roles[0];
		app.data.user.username = isLoggedIn.userCtx.name;
		if(app.data.user.role === 'Admin'){
				return callback(true, true);
		}
		else {
				return callback(true, false);
		}
	}
}

export function userLogout(self) {
	remoteDBroot.logOut((err, res) =>{
		if (err) {
			// Network error
		} else {
			// self.$app.data.user = undefined;
			self.$app.views.main.router.navigate('/login/');
		}
	});
}
