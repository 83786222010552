
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { bindEvents, addToiletsUtils, addCabinsUtils, savePdi, removePdi } from '../../js/utils/pdi-management';
import { openMapUtil } from '../../js/modules/map-utils';
export default {
  data: function () {
    return {
      week_day: [{
        index: 1,
        name: "Lunedì"
      }, {
        index: 2,
        name: "Martedì"
      }, {
        index: 3,
        name: "Mercoledì"
      }, {
        index: 4,
        name: "Giovedì"
      }, {
        index: 5,
        name: "Venerdì"
      }, {
        index: 6,
        name: "Sabato"
      }, {
        index: 0,
        name: "Domenica"
      }]
    };
  },
  methods: {
    addToilet: function (type) {
      addToiletsUtils(type, this);
      return;
    },
    addCabin: function () {
      addCabinsUtils(this);
    },
    openMap: function (lat, lon) {
      openMapUtil(lat, lon);
    },
    save: function () {
      this.$app.dialog.confirm(`<strong>Salvare</strong> ${this.pdi.name}?`, () => {
        savePdi(this);
      });
    },
    remove: function () {
      this.$app.dialog.confirm(`<span class='text-color-red'>Rimuovere</span> ${this.pdi.name}?`, () => {
        removePdi(this);
      });
    }
  },
  on: {
    pageInit: function (page) {
      bindEvents(this);
    }
  },
  id: 'a9ff6d8200',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="edit-toilet">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<!-- Left -->\n				<div class="left">\n					<a href="#" class="link icon-only back navbar-icon-link" data-ignore-cache="true" data-force="true">\n						<i class="icon f7-icons if-not-md">arrow_left</i>\n						<i class="icon material-icons if-md">arrow_back</i>\n					</a>\n					<a href="/" class="link icon-only navbar-icon-link" data-panel="left">\n						<i class="icon f7-icons if-not-md text-color-lightblue">house</i>\n						<i class="icon material-icons if-md">home</i>\n					</a>\n					<div class="title">\n						';
      r += Template7Helpers.js_if.call(ctx_1, 'this.new', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							Aggiungi Punto Di Interesse\n						';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n							Modifica ';
          r += Template7Helpers.js.call(ctx_2, 'this.pdi._id', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' - ';
          r += Template7Helpers.js.call(ctx_2, 'this.pdi.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n						';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					</div>\n				</div>\n				<div class="right">\n					';
      r += Template7Helpers.js_if.call(ctx_1, '!this.new && @root.$app.data.user.role==="Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<a class="icon-only tooltip-init" data-tooltip="Rimuovi PDI" @click="remove()">\n						<i class="icon f7-icons">\n							minus_circle_fill\n						</i>\n					</a>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<a class="icon-only tooltip-init" @click="save()"\n						';
      r += Template7Helpers.if.call(ctx_1, ctx_1.new, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n						data-tooltip="Salva nuovo PDI"\n						';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n						data-tooltip="Modifica ';
          r += c(ctx_2.pdi.name, ctx_2);
          r += '"\n						';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						>\n						<i class="icon f7-icons">\n							floppy_disk\n						</i>\n					</a>\n				</div>\n			</div>\n		</div>\n		<!-- End Navbar -->\n		<div class="page-content">\n			<!-- GENERAL INFO -->\n			<div class="block no-padding-horizontal">\n				<!-- NAME + ID -->\n				<div class="row no-padding-horizontal">\n					<!-- ID CARD -->\n					<div class="col-20 card no-padding-horizontal">\n						<div class="card-header">\n							ID\n							';
      r += Template7Helpers.js_if.call(ctx_1, 'this.new', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							(Automatico)\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n						<div class="card-content card-content-padding">\n							<div class="list">\n								<ul>\n									<li class="item-content item-input">\n										<div class="item-inner">\n											<div class="item-input-wrap">\n												<input type="text" name="pdi-name" value="';
      r += Template7Helpers.js.call(ctx_1, 'this.pdi._id', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" disabled />\n											</div>\n										</div>\n									</li>\n								</ul>\n							</div>\n						</div>\n					</div>\n					<!-- NAME CARD -->\n					<div class="col-60 card no-padding-horizontal" id="pdi-name-card">\n						<div class="card-header">\n							Nome\n							';
      r += Template7Helpers.js_if.call(ctx_1, 'this.new', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n							(Non potrà essere più cambiato)\n							';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n						</div>\n						<div class="card-content card-content-padding">\n							<div class="list">\n								<ul>\n									<li class="item-content item-input">\n										<div class="item-inner">\n											<div class="item-input-wrap">\n												<input type="text" name="pdi-name" id="pdi-name"\n													placeholder="Inserisci Nome" value="';
      r += Template7Helpers.js.call(ctx_1, 'this.pdi.name', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"\n													';
      r += Template7Helpers.js_if.call(ctx_1, '!this.new', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n													disabled\n													';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n												/>\n											</div>\n										</div>\n									</li>\n								</ul>\n							</div>\n						</div>\n					</div>\n					<!-- padding -->\n					<div class="col-20">\n					</div>\n				</div>\n				<!-- ADDRESS + GPS -->\n				<div class="row no-padding-horizontal">\n					<!-- ADDRESS CARD -->\n					<div class="col-50 card no-padding-horizontal" id="pdi-address-card">\n						<div class="card-header">\n							<div class="left">\n								Indirizzo\n								';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pdi.gps.lat && this.pdi.gps.lon', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n								<a style="margin-left: 2px;" @click="openMap(\'';
          r += c(ctx_2.pdi.gps.lat, ctx_2);
          r += '\', \'';
          r += c(ctx_2.pdi.gps.lon, ctx_2);
          r += '\')">\n									<i class="icon f7-icons tooltip-init pdi-management-map-icon" data-tooltip="Apri mappa">\n										map_pin_ellipse\n									</i>\n								</a>\n								';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n							</div>\n							<div class="right">\n								<div class="list simple-list">\n									<ul>\n										<li>\n											<span style="padding-right: 3px;">GPS Automatico</span>\n											<label class="toggle toggle-init">\n												<input type="checkbox" id="pdi-gps-auto"\n													';
      r += Template7Helpers.js_if.call(ctx_1, 'this.pdi.gps.auto', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n													checked\n													';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n													';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role !== "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n													disabled\n													';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n													/>\n												<span class="toggle-icon"></span>\n											</label>\n										</li>\n									</ul>\n								</div>\n							</div>\n						</div>\n						<div class="card-content card-content-padding">\n							<div class="list">\n								<ul>\n									<li class="item-content item-input">\n										<div class="item-inner">\n											<div class="item-input-wrap">\n												<input type="text" name="pdi-name" id="pdi-address"\n													placeholder="Inserisci Indirizzo" value="';
      r += Template7Helpers.js.call(ctx_1, 'this.pdi.address', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"\n													';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role !== "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n													disabled\n													';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n												/>\n											</div>\n										</div>\n									</li>\n								</ul>\n							</div>\n						</div>\n					</div>\n					';
      r += Template7Helpers.js_if.call(ctx_1, '!this.pdi.gps.auto', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<div class="col-35 card no-padding-horizontal" id="pdi-gps-card">\n						<div class="card-header">\n							GPS\n						</div>\n						<div class="card-content card-content-padding">\n							<div class="row">\n								<div class="col">\n									<div class="list inline-labels no-hardlines-md">\n										<div class="item-inner">\n											<div class="item-title item-label">\n												Latitudine\n											</div>\n											<div class="item-input-wrap">\n												<input type="number" id="pdi-gps-lat"\n													value="';
          r += Template7Helpers.js.call(ctx_2, 'this.pdi.gps.lat', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"\n													class="pdi-gps-input" gps_type="lat"\n													';
          r += Template7Helpers.js_if.call(ctx_2, 'this.@root.$app.data.user.role !== "Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n													disabled\n													';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n												/>\n											</div>\n										</div>\n									</div>\n								</div>\n								<div class="col">\n									<div class="list inline-labels no-hardlines-md">\n										<div class="item-inner">\n											<div class="item-title item-label">\n												Longitudine\n											</div>\n											<div class="item-input-wrap">\n												<input type="number" id="pdi-gps-lon"\n													value="';
          r += Template7Helpers.js.call(ctx_2, 'this.pdi.gps.lon', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"\n													class="pdi-gps-input" gps_type="lon"\n													';
          r += Template7Helpers.js_if.call(ctx_2, 'this.@root.$app.data.user.role !== "Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n													disabled\n													';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n												/>\n											</div>\n										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!-- padding -->\n					<div class="col-15">\n					</div>\n				</div>\n				<!-- ELEMS -->\n				<div class="row block no-padding-horizontal">\n					<!-- ELEMS CARD -->\n					<div class="col-100 card no-padding-horizontal" id="pdi-elems-selector">\n						<div class="card-header">\n							Elementi di Manutenzione\n						</div>\n						<div class="card-content card-content-padding data-table">\n							<div class="row">\n								<div class="col-33">\n									<div class="list">\n										<ul>\n											<li>\n												<label class="item-checkbox item-content">\n													<input type="checkbox" name="toilet-present-checkbox" id="pdi-toilets-present"\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.toilets).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														checked\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role !== "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														disabled\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														/>\n													<i class="icon icon-checkbox"></i>\n													<div class="item-inner">\n														<div class="item-title">\n															Bagni\n														</div>\n													</div>\n												</label>\n											</li>\n										</ul>\n									</div>\n								</div>\n								<div class="col-33">\n									<div class="list">\n										<ul>\n											<li>\n												<label class="item-checkbox item-content">\n													<input type="checkbox" name="cabins-present-checkbox" id="pdi-cabins-present"\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.cabins).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														checked\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role !== "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														disabled\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														/>\n													<i class="icon icon-checkbox"></i>\n													<div class="item-inner">\n														<div class="item-title">\n															Cabine\n														</div>\n													</div>\n												</label>\n											</li>\n										</ul>\n									</div>\n								</div>\n								<div class="col-33">\n									<div class="list">\n										<ul>\n											<li>\n												<label class="item-checkbox item-content">\n													<input type="checkbox" name="square-present-checkbox" id="pdi-square-present"\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.square).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														checked\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														';
      r += Template7Helpers.js_if.call(ctx_1, 'this.@root.$app.data.user.role !== "Admin"', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n														disabled\n														';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n														/>\n													<i class="icon icon-checkbox"></i>\n													<div class="item-inner">\n														<div class="item-title">\n															Piazzale\n														</div>\n													</div>\n												</label>\n											</li>\n										</ul>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n			<!-- END GENERAL INFO -->\n			<div class=\'block no-padding-horizontal\'>\n				<!-- TREEVIEW -->\n				<div class="treeview">\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.toilets).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<!-- TOILET TREEVIEW -->\n					<div class="treeview-item treeview-item-toilets">\n						<div class="treeview-item-root">\n							<div class="treeview-toggle">\n							</div>\n							<div class="treeview-item-content">\n								<div class="treeview-item-label">\n									Bagni\n								</div>\n							</div>\n						</div>\n						<div class="treeview-item-children">\n							<!-- CLEANING PLAN -->\n							<div class="treeview-item">\n								<div class="treeview-item-root block row">\n									<!-- CLEANING PLAN TABLE -->\n									<div class="col-80 card no-padding-horizontal">\n										<div class="card-header">\n											Piano Giornaliero\n										</div>\n										<div class="card-content data-table card-content-padding">\n											<table>\n												<thead>\n													<tr>\n														<th>\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<th>\n															';
              r += Template7Helpers.js.call(ctx_3, 'this.name', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n														</th>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<th>\n															Pulizie sospese\n														</th>\n													</tr>\n												</thead>\n												<tbody>\n													<tr>\n														<th>\n															Numero passaggi\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li class="item-content item-input">\n																		<div class="item-inner">\n																			<div class="item-input-wrap">\n																				';
              r += Template7Helpers.getCleaningPlanInput.call(ctx_3, 'toilets', ctx_3, root.pdi, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n																			</div>\n																		</div>\n																	</li>\n																</ul>\n															</div>\n														</td>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li>\n																		<label class="item-checkbox item-content">\n																			<input type="checkbox" id="pdi-toilets-cleaning-suspended"\n																				class="pdi-cleaning-suspended" pdi_type="toilets"\n																				';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pdi.toilets.cleaning_suspended', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n																				checked\n																				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n																				/>\n																			<i class="icon icon-checkbox"></i>\n																		</label>\n																	</li>\n																</ul>\n															</div>\n														</td>\n													</tr>\n												</tbody>\n											</table>\n										</div>\n									</div>\n									<!-- END CLEANING PLAN TABLE -->\n									<!-- SANIFICATION NIGHT -->\n									<div class="col-15 card no-padding-horizontal">\n										<div class="card-header">\n											Notte Sanificazione\n										</div>\n										<div class="card-content card-content-padding page-content" id="toilets-sanification-list-container" style="height: 140px">\n											<div class="list">\n												<ul>\n													<li>\n														<label class="item-radio item-radio-icon-start item-content">\n															<input type="radio" name="pdi-toilet-sanification" value="-1" class="pdi-toilets-sanification"\n																';
          r += Template7Helpers.js_if.call(ctx_2, 'this.@root.pdi.toilets.night_sanitization === -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n																checked\n																';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n																/>\n															<i class="icon icon-radio"></i>\n															<div class="item-inner">\n																<div class="item-title">\n																	No Sanificazione\n																</div>\n															</div>\n														</label>\n													</li>\n													';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n													<li>\n														<label class="item-radio item-radio-icon-start item-content">\n															<input type="radio" name="pdi-toilet-sanification" value="';
              r += c(ctx_3.index, ctx_3);
              r += '" class="pdi-toilets-sanification"\n																';
              r += Template7Helpers.js_if.call(ctx_3, 'this.index === this.@root.pdi.toilets.night_sanitization', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n																checked\n																';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n																/>\n															<i class="icon icon-radio"></i>\n															<div class="item-inner">\n																<div class="item-title">\n																	';
              r += Template7Helpers.js.call(ctx_3, 'this.name', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n																</div>\n															</div>\n														</label>\n													</li>\n													';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n												</ul>\n											</div>\n										</div>\n									</div>\n									<!-- END SANIFICATION NIGHT -->\n								</div>\n							</div>\n							<!-- END CLEANING PLAN -->\n							<!-- MALE TOILETS -->\n							<div class="treeview-item">\n								<div class="treeview-item-root">\n									<div class="treeview-toggle">\n									</div>\n									<div class="treeview-item-content">\n										<div class="treeview-item-label">\n											Bagni Uomo\n										</div>\n									</div>\n								</div>\n								<div class="treeview-item-children">\n									<div class="treeview-item">\n										<div class="treeview-item-root block row">\n											<!-- TOILET CARD -->\n											';
          r += Template7Helpers.getElemIdCards.call(ctx_2, 'toilets', 'male', ctx_2.pdi.toilets.male.elems, ctx_2.$app.data.user.role, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											<!-- END TOILET CARD -->\n											<!-- ADD TOILET BUTTON -->\n											';
          r += Template7Helpers.js_if.call(ctx_2, '@root.$app.data.user.role==="Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n											<div class="add-button" @click="addToilet(\'male\')">\n													<i class="icon f7-icons tooltip-init" data-tooltip="Aggiungi un bagno">plus</i>\n											</div>\n											';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											<!-- END TOILET BUTTON -->\n										</div>\n									</div>\n								</div>\n							</div>\n							<!-- FEMAL TOILETS -->\n							<div class="treeview-item">\n								<div class="treeview-item-root">\n									<div class="treeview-toggle">\n									</div>\n									<div class="treeview-item-content">\n										<div class="treeview-item-label">\n											Bagni Donna\n										</div>\n									</div>\n								</div>\n								<div class="treeview-item-children">\n									<div class="treeview-item">\n										<div class="treeview-item-root block row">\n											<!-- TOILET CARD -->\n											';
          r += Template7Helpers.getElemIdCards.call(ctx_2, 'toilets', 'female', ctx_2.pdi.toilets.female.elems, ctx_2.$app.data.user.role, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											<!-- END TOILET CARD -->\n											<!-- ADD TOILET BUTTON -->\n											';
          r += Template7Helpers.js_if.call(ctx_2, '@root.$app.data.user.role==="Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n											<div class="add-button" @click="addToilet(\'female\')">\n													<i class="icon f7-icons tooltip-init" data-tooltip="Aggiungi un bagno">plus</i>\n											</div>\n											';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n											<!-- END TOILET BUTTON -->\n										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!-- END TOILET TREEVIEW -->\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.cabins).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<!-- CABINS TREEVIEW -->\n					<div class="treeview-item db-treeview-external">\n						<div class="treeview-item-root">\n							<div class="treeview-toggle">\n							</div>\n							<div class="treeview-item-content">\n								<div class="treeview-item-label">\n									Cabine\n								</div>\n							</div>\n						</div>\n						<div class="treeview-item-children">\n							<!-- CLEANING PLAN & CABINS ELEMS-->\n							<div class="treeview-item">\n								<!-- CLEANING PLAN TABLE -->\n								<div class="treeview-item-root block row">\n									<div class="col-100 card no-padding-horizontal">\n										<div class="card-header">\n											Piano Giornaliero\n										</div>\n										<div class="card-content data-table card-content-padding">\n											<table>\n												<thead>\n													<tr>\n														<th>\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<th>\n															';
              r += Template7Helpers.js.call(ctx_3, 'this.name', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n														</th>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<th>\n															Pulizie sospese\n														</th>\n													</tr>\n												</thead>\n												<tbody>\n													<tr>\n														<th>\n															Numero passaggi\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li class="item-content item-input">\n																		<div class="item-inner">\n																			<div class="item-input-wrap">\n																				';
              r += Template7Helpers.getCleaningPlanInput.call(ctx_3, 'cabins', ctx_3, root.pdi, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n																			</div>\n																		</div>\n																	</li>\n																</ul>\n															</div>\n														</td>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li>\n																		<label class="item-checkbox item-content">\n																			<input type="checkbox" id="pdi-cabins-cleaning-suspended"\n																				class="pdi-cleaning-suspended" pdi_type="cabins"\n																				';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pdi.cabins.cleaning_suspended', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n																				checked\n																				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n																				/>\n																			<i class="icon icon-checkbox"></i>\n																		</label>\n																	</li>\n																</ul>\n															</div>\n														</td>\n													</tr>\n												</tbody>\n											</table>\n										</div>\n									</div>\n								</div>\n								<!-- END CLEANIG PLAN TABLE -->\n								<!-- CABINS ELEMS -->\n								<div class="treeview-item-root block row">\n									<!-- CABINS CARD -->\n									';
          r += Template7Helpers.getElemIdCards.call(ctx_2, 'cabins', '', ctx_2.pdi.cabins.elems, ctx_2.$app.data.user.role, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n									<!-- END CABINS CARD -->\n									<!-- ADD CABINS BUTTON -->\n									';
          r += Template7Helpers.js_if.call(ctx_2, '@root.$app.data.user.role==="Admin"', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n									<div class="add-button" @click="addCabin">\n											<i class="icon f7-icons tooltip-init" data-tooltip="Aggiungi una cabina">plus</i>\n									</div>\n									';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n									<!-- END CABINS BUTTON -->\n							</div>\n							<!-- END CABINS ELEMS -->\n							</div>\n							<!-- END CLEANING PLAN & CABINS ELEMS-->\n						</div>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!-- END CABINS TREEVIEW -->\n					';
      r += Template7Helpers.js_if.call(ctx_1, 'Object.keys(this.pdi.square).length > 0', {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n					<!-- SQUARE TREEVIEW -->\n					<div class="treeview-item db-treeview-external">\n						<div class="treeview-item-root">\n							<div class="treeview-toggle">\n							</div>\n							<div class="treeview-item-content">\n								<div class="treeview-item-label">\n									Piazzale\n								</div>\n							</div>\n						</div>\n						<div class="treeview-item-children">\n							<!-- CLEANING PLAN -->\n							<div class="treeview-item">\n								<div class="treeview-item-root block row">\n									<!-- CLEANING PLAN TABLE -->\n									<div class="col-100 card no-padding-horizontal">\n										<div class="card-header">\n											Piano Settimanale\n										</div>\n										<div class="card-content data-table card-content-padding">\n											<table>\n												<thead>\n													<tr>\n														<th>\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<th>\n															';
              r += Template7Helpers.js.call(ctx_3, 'this.name', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n														</th>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<th>\n															Pulizie sospese\n														</th>\n													</tr>\n												</thead>\n												<tbody>\n													<tr>\n														<th>\n															Numero passaggi\n														</th>\n														';
          r += Template7Helpers.each.call(ctx_2, ctx_2.week_day, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li class="item-content item-input">\n																		<div class="item-inner">\n																			<div class="item-input-wrap">\n																				';
              r += Template7Helpers.getCleaningPlanInput.call(ctx_3, 'square', ctx_3, root.pdi, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n																			</div>\n																		</div>\n																	</li>\n																</ul>\n															</div>\n														</td>\n														';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n														<td>\n															<div class="list">\n																<ul>\n																	<li>\n																		<label class="item-checkbox item-content">\n																			<input type="checkbox" id="pdi-square-cleaning-suspended"\n																				class="pdi-cleaning-suspended" pdi_type="square"\n																				';
          r += Template7Helpers.js_if.call(ctx_2, 'this.pdi.square.cleaning_suspended', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n																				checked\n																				';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n																				/>\n																			<i class="icon icon-checkbox"></i>\n																		</label>\n																	</li>\n																</ul>\n															</div>\n														</td>\n													</tr>\n												</tbody>\n											</table>\n										</div>\n									</div>\n									<!-- END CLEANIG PLAN TABLE -->\n								</div>\n							</div>\n							<!-- END CLEANING PLAN -->\n						</div>\n					</div>\n					';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n					<!-- END SQUARE TREEVIEW -->\n				</div>\n				<!-- END TREEVIEW -->\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  style: `
	.wrong-field {
		border-style: groove;
		border-color: #ffcc00;
		border-width: thin;
		border-radius: 5px;
	}
`,
  styleScoped: false
};
    