
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  on: {
    pageInit: function (page) {
      this.$app.searchbar.create({
        el: '.search-user',
        searchContainer: '.searchbar-found',
        searchIn: ['.item-search-user']
      });
    }
  },
  id: 'f33edd9275',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=users><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only panel-open navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Gestisci utenti</div></div><!-- Rigth --><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill </i></a><a href=/add-user/ class="link icon-only" data-panel=left><i class="icon f7-icons tooltip-init" data-tooltip="Aggiungi Utente">person_crop_circle_badge_plus</i></a></div><!-- Searchbar --><div class=subnavbar><form class="searchbar search-user"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca utente..."> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div></div><!-- Scrollable page content --><div class=page-content><div class=searchbar-backdrop></div><div class="list searchbar-found"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.users, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/edit-user/';
          r += c(ctx_2.doc.name, ctx_2);
          r += ' class="item-link item-content"><div class=item-media><i class=f7-icons>person</i></div><div class=item-inner><div class="item-title item-search-user">';
          r += c(ctx_2.doc.firstname, ctx_2);
          r += ' ';
          r += c(ctx_2.doc.familyname, ctx_2);
          r += '</div><div class="item-after item-search-user">';
          r += c(ctx_2.doc.roles[0], ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="block searchbar-not-found"><div class=block-inner>Nessun utente trovato</div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    