import {app} from '../../app';
import {userDB, api_url} from '../config';
var uuid = require("uuid");

const report_url = `${api_url}pdf/`;

export function requestPdfReport(elem, pdi_id, date_range = undefined) {
	return new Promise((resolve, reject) => {
		const tmpKey = uuid.v4();
		userDB.putUser(app.data.user.username, {
				metadata: {
						tmp_key: tmpKey
				}
		}, (err, res) => {
				if(err) {
					console.log(err);
					// Error
					return reject(err);
				}
				if (date_range) {
					app.request({
							url: `${report_url}${elem}/${(pdi_id)? pdi_id:''}`,
							method: 'POST',
							dataType: 'json',
							headers: {
									'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
							},
							data: {
								date_range: date_range
							},
							error: (xhr, status, message) => {
								console.log(xhr);
								return reject(xhr);
							},
							success: (data, status, xhr) => {
								return resolve(data);
							}
					});
				} else {
					app.request({
							url: `${report_url}${elem}/${(pdi_id)? pdi_id:''}`,
							method: 'GET',
							dataType: 'json',
							headers: {
									'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
							},
							error: (xhr, status, message) => {
								console.log(xhr);
								return reject(xhr);
							},
							success: (data, status, xhr) => {
								return resolve(data);
							}
					});

				}
		});

	});
}
