
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import { openMapUtil } from "../js/modules/map-utils";
export default {
  methods: {
    openMap(lat, lon) {
      if (!e) {
        var e = window.event;
      }

      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      openMapUtil(lat, lon);
    },

    openPdiStats(id) {
      if (!e) {
        var e = window.event;
      }

      e.cancelBubble = true;
      if (e.stopPropagation) e.stopPropagation();
      this.$router.navigate(`/pdi-stats/${id}/`);
    }

  },
  on: {
    pageInit: function (page) {
      this.$app.searchbar.create({
        el: '.search-pdi',
        searchContainer: '.searchbar-found',
        searchIn: ['.item-search-pdi']
      });
    }
  },
  id: '9cf374dc7a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=pdi-stats-page><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><!-- Left --><div class=left><a href=# class="link icon-only panel-open navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i> </a><a href=/ class="link icon-only navbar-icon-link" data-panel=left><i class="icon f7-icons if-not-md text-color-lightblue">house</i> <i class="icon material-icons if-md">home</i></a><div class=title>Statistiche Punti di Interesse</div></div><div class=right><a href=# class="link icon-only" @click=window.print()><i class="icon f7-icons tooltip-init" data-tooltip="Stampa pagina web">printer_fill</i></a></div></div></div><div class=page-content><!-- Searchbar --><div class=searchbar-div><form class="searchbar search-pdi"><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Cerca Punto di Interesse..."> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div><div class=searchbar-backdrop></div><div class="list searchbar-found pdi-stats-list"><ul><li class="item-content item-content-title searchbar-ignore"><div class=item-media></div><div class="item-inner item-cell text-color-lightblue"><div class=item-row><div class=item-cell><span>PDI - Info</span></div><div class=item-cell><span>Bagni+Cabine</span></div><div class=item-cell><span>Bagni</span></div><div class=item-cell><span>Cabine</span></div><div class=item-cell><span>Piazzali</span></div></div></div></li>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.pdi, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# class="item-link item-content" @click="openPdiStats(\'';
          r += c(ctx_2.doc._id, ctx_2);
          r += '\')"><div class=item-media><i class="f7-icons text-color-yellow">scope</i></div><div class="item-inner item-cell"><div class=item-row><div class=item-cell><div class=item-title-row><div class="item-title item-search-pdi"><span class=item-search-pdi>';
          r += Template7Helpers.js.call(ctx_2, 'this.doc._id', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' </span>- <span class=item-search-pdi>';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.name', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span></div></div></div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.toilets).length > 0 && Object.keys(this.doc.cabins).length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Numero passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.toilets_cabins.number_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Completati: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.toilets_cabins.completed_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>) ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' NON DISPONIBILE ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.toilets).length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Numero passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.toilets.number_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Completati: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.toilets.completed_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>) ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' NON DISPONIBILE ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.cabins).length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Numero passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.cabins.number_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Completati: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.cabins.completed_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>) ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' NON DISPONIBILE ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.square).length > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Numero passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.square.number_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>(Completati: <span class=text-color-yellow>';
              r += Template7Helpers.js.call(ctx_3, 'this.doc.stats.square.completed_passes', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>) ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' NON DISPONIBILE ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><div class=item-row><div class="item-text item-cell"><span class=item-search-pdi>';
          r += Template7Helpers.js.call(ctx_2, 'this.doc.address', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</span> <i class="icons f7-icons tooltip-init text-color-lightblue" @click="openMap(\'';
          r += c(ctx_2.doc.gps.lat, ctx_2);
          r += '\',\'';
          r += c(ctx_2.doc.gps.lon, ctx_2);
          r += '\')" data-tooltip="Apri Mappa">map_pin_ellipse</i></div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.toilets).length > 0 && Object.keys(this.doc.cabins).length > 0 && this.doc.stats.toilets_cabins.number_passes > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Durata Media passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.toilets_cabins.formatted_mean_time.hour > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.toilets_cabins.formatted_mean_time.hour', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.toilets_cabins.formatted_mean_time.hour > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ore ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ora ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.toilets_cabins.formatted_mean_time.min > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.toilets_cabins.formatted_mean_time.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.toilets_cabins.formatted_mean_time.min > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuti ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuto ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.toilets).length > 0 && this.doc.stats.toilets.number_passes > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Durata Media passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.toilets.formatted_mean_time.hour > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.toilets.formatted_mean_time.hour', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.toilets.formatted_mean_time.hour > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ore ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ora ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.toilets.formatted_mean_time.min > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.toilets.formatted_mean_time.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.toilets.formatted_mean_time.min > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuti ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuto ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.cabins).length > 0 && this.doc.stats.cabins.number_passes > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Durata Media passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.cabins.formatted_mean_time.hour > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.cabins.formatted_mean_time.hour', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.cabins.formatted_mean_time.hour > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ore ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ora ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.cabins.formatted_mean_time.min > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.cabins.formatted_mean_time.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.cabins.formatted_mean_time.min > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuti ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuto ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-cell>';
          r += Template7Helpers.js_if.call(ctx_2, 'Object.keys(this.doc.square).length > 0 && this.doc.stats.square.number_passes > 0', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Durata Media passaggi: <span class=text-color-yellow>';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.square.formatted_mean_time.hour > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.square.formatted_mean_time.hour', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.square.formatted_mean_time.hour > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ore ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' ora ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.doc.stats.square.formatted_mean_time.min > 0', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += Template7Helpers.js.call(ctx_4, 'this.doc.stats.square.formatted_mean_time.min', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  r += Template7Helpers.js_if.call(ctx_4, 'this.doc.stats.square.formatted_mean_time.min > 1', {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuti ';
                      return r;
                    },
                    inverse: function (ctx_5, data_5) {
                      var r = '';
                      r += ' minuto ';
                      return r;
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' </span>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class="list media-list searchbar-not-found"><ul><li><a href=# class="item-link item-content"><div class=item-media><i class="f7-icons text-color-yellow">scope</i></div><div class=item-inner><div class=item-title-row><div class="item-title item-search-pdi">Nessun Punto di Interesse Trovato</div></div></div></a></li></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    