const hash = require('object-hash');

import {
	notificationDB
} from './config';

function putSubscription(subscription, notification_doc, self) {
	return new Promise((resolve, reject) => {
		var subscription_list = notification_doc.push_notification.subscriptions;
		var sub_id = hash(subscription.toJSON());

		// CHECK IF ALREADY PRESENT
		var elem_find = subscription_list.find(elem => elem.id === sub_id);
		if (elem_find) {
			return resolve("Subscription completed");
		}

		subscription_list.push({
			id: sub_id,
			subscription: subscription
		});
		notificationDB.put(notification_doc).then((response) => {
			return resolve("Subscription completed");
		}).catch((error) => {
			subscription.unsubscribe();
			return reject("Failed to insert subscription in notification doc");
		})

	});
}

function createNotificationDoc(username) {
	return new Promise((resolve, reject) => {
		var notification_doc = {
			_id: username,
			mail_notification: {
				time: false,
				gps: false,
				email: ''
			},
			push_notification: {
				time: false,
				gps: false,
				subscriptions: []
			}
		};
		notificationDB.put(notification_doc).then((response) => {
			notification_doc._rev = response.rev;
			return resolve(notification_doc);
		}).catch((error) => {
			return reject(error);
		});
	});
}

export function modifyNotificationDoc(notification_doc) {
	return new Promise((resolve, reject) => {
		notificationDB.get(notification_doc._id).then((response) => {
			notification_doc._rev = response._rev;
			notificationDB.put(notification_doc).then((response) => {
				return resolve(response);
			}).catch((error) => {
				return reject(error);
			})
		}).catch((error) => {
			return reject(error);
		})
	});
}

export async function checkSubscriptionDoc(subscription, self) {
	return new Promise((resolve, reject) => {
		const username = self.$app.data.user.username;
		var promise;
		notificationDB.get(username).then((notification_doc) => {
			promise = putSubscription(subscription, notification_doc, self);
		}).catch(async (error) => {
			switch (error.status) {
				case 404:
					try {
						var notification_doc = await createNotificationDoc(username);
						promise = putSubscription(subscription, notification_doc, self);
					} catch(e) {
						return reject({
							message: 'Failed to create notification document'
						});
					}
					break;
				default:
					subscription.unsubscribe();
					return reject({
						message: "Failed to fecth notification document"
					});
			}
		}).finally(() => {
			if (!promise) {
				return;
			}
			promise.then((response) => {
				return resolve(response);
			}).catch((error) => {
				return reject(error);
			})
		});
	});

}

export function getNotificationDoc(username, callback) {
	notificationDB.get(username).then((response) => {
		callback(undefined, response);
	}).catch(async (error) => {
		switch (error.status) {
			case 404:
				try {
					var notification_doc = await createNotificationDoc(username);
					return callback(undefined, notification_doc);
				} catch(e) {
					return callback(e, undefined);
				}
				break;
			default:
				return callback(error, undefined);
		}
	});
}
