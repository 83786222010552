import {app} from '../app';
import {userDB, api_url} from './config';
var uuid = require("uuid");

// Get a list of all users
export function getAllUsers(callback) {
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (err, res) => {
		if(err) {
			// Error
			return callback(err, undefined);
		}
		app.request({
			url: api_url+'users',
			method: 'GET',
			dataType: 'json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Get a user
export function getUser(username, callback) {
	// Put key on user doc
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (err, res) => {
		if(err) {
			// Error
			return callback(err, undefined);
		}
		app.request({
			url: api_url+'user/'+username,
			method: 'GET',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Create a new user
export function putNewUser(userData, callback){
	// Put key on user doc
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (err, res) => {
		if(err) {
			// Error
			return callback(err, undefined);
		}
		app.request({
			url: api_url+'newUser',
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			data: userData,
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Update a user
export function putUpdateUser(userData, callback){
	// Put key on user doc
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (err, res) => {
		if(err) {
			// Error
			return callback(err, undefined);
		}
		app.request({
			url: api_url+'updateUser/',
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			data: userData,
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}

// Delete user
export function deleteuser(username, callback){
	// Put key on user doc
	const tmpKey = uuid.v4();
	userDB.putUser(app.data.user.username, {
		metadata: {
			tmp_key: tmpKey
		}
	}, (err, res) => {
		if(err) {
			// Error
			return callback(err, undefined);
		}
		app.request({
			url: api_url+'user/'+username,
			method: 'DELETE',
			dataType: 'json',
			contentType: 'application/json',
			headers: {
				'X-Access-Personal-Key': app.data.user.username+':'+tmpKey
			},
			error: (xhr, status, message) => {
				return callback(xhr, undefined);
			},
			success: (data, status, xhr) => {
				return callback(undefined, data);
			}
		});
	});
}


export function modifySelf(user, callback) {
	if (app.data.user.username !== user.name) {
		return callback({error: "Forbidden"}, undefined);
	}
	userDB.putUser(user.name, {
		metadata: {
			email: user.email,
			notifications: user.notifications
		}
	}).then((response) => {
		return callback(undefined, response);
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	})
}
