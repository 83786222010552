import {
	getGpsFromAddress
} from '../modules/map-utils';

import {
	putPdi,
	deletePdi
} from '../modules/pdi-management-module';

/*
 *	UTILS FUNCTIONS
 */
export function getPdiBlankDoc() {
	return {
		_id: '',
		name: '',
		address: '',
		toilets: {},
		cabins: {},
		square: {},
		gps: {
			lat: '',
			lon: '',
			auto: true
		}
	}
}

export function addToiletsUtils(type, self) {
	self.pdi.toilets[type].number++;
	var id;
	var pdi_toilets = self.pdi.toilets[type];
	// TODO: FIX ID GENERATOR

	var index = 1;
	switch (type) {
		case 'male':
			id=`${self.pdi._id}-TM-00`;
			break;
		case 'female':
			id=`${self.pdi._id}-TF-00`;
			break;
		default:
			return;
	}

	while (pdi_toilets.elems.hasOwnProperty(`${id}${index}`)) {
		index++;
	}
	id = `${id}${index}`;

	pdi_toilets.elems[id] = {
		unusable: false
	}
	updateView(self);
}

export function addCabinsUtils(self) {
	self.pdi.cabins.number++;
	var id = `${self.pdi._id}-C-00`;
	var index = 1;
	while (self.pdi.cabins.elems.hasOwnProperty(`${id}${index}`)) {
		index++;
	}
	id = `${id}${index}`;
	self.pdi.cabins.elems[id] = {
		unusable: false
	};

	updateView(self);
}

function checkFields(pdi, self) {
	if (!pdi.name || pdi.name.length === 0) {
		self.$(`#pdi-name-card`).addClass(`wrong-field`);
		return {
			success: false,
			code: 0,
			message: 'Missing Name'
		};
	} else {
		self.$(`#pdi-name-card`).removeClass(`wrong-field`);
	}

	if (!pdi.address || pdi.address.length === 0) {
		self.$(`#pdi-address-card`).addClass(`wrong-field`);
		return {
			success: false,
			code: 1,
			message: 'Missing Address'
		};
	} else {
		self.$(`#pdi-address-card`).removeClass(`wrong-field`);
	}

	if (Object.keys(pdi.toilets).length === 0 && Object.keys(pdi.cabins).length === 0 && Object.keys(pdi.square).length === 0) {
		self.$(`#pdi-elems-selector`).addClass(`wrong-field`);
		return {
			success: false,
			code: 2,
			message: 'Missing Elems'
		};
	} else {
		self.$(`#pdi-elems-selector`).removeClass(`wrong-field`);
	}

	if (Object.keys(pdi.toilets).length > 0) {
		if (pdi.toilets.male.number + pdi.toilets.female.number === 0) {
			return {
				success: false,
				code: 3,
				message: 'Add at least one toilet'
			};
		}
	}

	if (Object.keys(pdi.cabins).length > 0) {
		if (pdi.cabins.number === 0) {
			return {
				success: false,
				code: 4,
				message: 'Add at least one cabin'
			};
		}
	}

	return {
		success: true
	};
}

function checkCleaningSuspensionHistory(pdi) {
	const elems_types = ['toilets', 'cabins', 'square'];

	for(var type of elems_types) {
		if (Object.keys(pdi[type]).length === 0) {
			continue;
		}
		var elem = pdi[type];
		var now = new Date();
		if (elem.cleaning_suspended) { // SUSPENDED
			if (elem.suspension_history.length > 0) { // WITH HISTORY
				var last_suspension = elem.suspension_history[0];
				if (last_suspension.to.length > 0) {
					var suspension_elem = {
						from: (new Date(now.getFullYear(), now.getMonth(), now.getDate())).toISOString(),
						to: ''
					};
					elem.suspension_history.unshift(suspension_elem);
				}
			} else { // NO HISTORY
				var suspension_elem = {
					from: (new Date(now.getFullYear(), now.getMonth(), now.getDate())).toISOString(),
					to: ''
				};
				elem.suspension_history.unshift(suspension_elem);
			}
		} else { // NO SUSPENDED
			if (elem.suspension_history.length > 0) {
				var last_suspension = elem.suspension_history[0];
				if (last_suspension.to.length === 0) {
					last_suspension.to = (new Date(now.getFullYear(), now.getMonth(), now.getDate())).toISOString();
				}
			}
		}
	}

}

export function savePdi(self) {
	var pdi = self.pdi;
	var response = checkFields(pdi, self);
	if (response.success) {
		checkCleaningSuspensionHistory(pdi);

		self.$app.preloader.show();
		putPdi(pdi, self.new).then((response) => {
			self.$app.preloader.hide();
			self.$app.dialog.alert(`${pdi.name} salvato correttamente!`, () => {
				self.$router.navigate(`/edit-toilet/${pdi._id}`);
			});
		}).catch((error) => {
			self.$app.preloader.hide();
			self.$app.dialog.alert(`Qualcosa è andato storto! Riprova`, () => {
			});
		})
	} else {
		var msg = ``;
		switch (response.code) {
			case 0:
			case 1:
				msg = `Riempire tutti i campi!`;
				break;
			case 2:
				msg = `Selezionare almeno un elemento di manutenzione!`;
				break;
			case 3:
				msg = `Aggiungere almeno un bagno!`;
				break;
			case 4:
				msg = `Aggiungere almeno una cabina!`;
				break;
			default:
				msg = `Qualcosa è andato storto!`;
				break;
		}
		self.$app.dialog.alert(msg);
	}
}

export function removePdi(self) {
	self.$app.preloader.show();
	deletePdi(self.pdi).then((response) => {
		self.$app.preloader.hide();
		self.$app.dialog.alert(`<strong>${self.pdi.name}</strong> eliminato!`, () => {
			self.$router.navigate('/toilets/');
		});
	}).catch((error) => {
		self.$app.preloader.hide();
		self.$app.dialog.alert(`Qualcosa è andato storto! Riprova`, () => {
		});
	});
}

function updateView(self) {
	self.$setState(self.pdi, () => {
		bindEvents(self);
	});
}

/*
 *	PDI NAME
 */

function pdiNameBlur(e, self) {
	e.target.value = e.target.value.trim();
	var $elem = self.$(e.target);
	self.pdi.name = $elem.val();
	if (!self.pdi.name || self.pdi.name.length === 0) {
		self.$(`#pdi-name-card`).addClass(`wrong-field`);
	} else {
		self.$(`#pdi-name-card`).removeClass(`wrong-field`);
	}
}

/*
 *	ADDRESS + GPS
 */

function pdiAddressBlur(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	e.target.value = e.target.value.trim();
	pdi.address = $elem.val();
	if(!pdi.address || pdi.address.length === 0) {
		$(`#pdi-address-card`).addClass(`wrong-field`);
	} else {
		$(`#pdi-address-card`).removeClass(`wrong-field`);
		if (pdi.gps.auto) {
			self.$app.preloader.show();
			getGpsFromAddress(pdi.address, (error, response) => {
				if (error) {
					self.$app.preloader.hide();
					self.$app.dialoag.alert("Impossibile reperire le coordinate GPS!<br>Utilizza un indirizzo più preciso");
				} else {
					self.$app.preloader.hide();
					pdi.gps.lat = response.lat;
					pdi.gps.lon = response.lon;
					updateView(self);
				}
			});
		}
	}
}

function pdiGpsAutoToggle(e, self) {
	var $ = self.$;
	var $elem = $(e.target);
	var pdi = self.pdi;
	var pdi_gps = pdi.gps;

	pdi_gps.auto = $elem.prop('checked');

	if (pdi_gps.auto) {
		if (pdi.address && pdi.address.length > 0) {
			self.$app.preloader.show();
			getGpsFromAddress(pdi.address, (error, response) => {
				if (error) {
					self.$app.preloader.hide();
					self.$app.dialoag.alert("Impossibile reperire le coordinate GPS!<br>Utilizza un indirizzo più preciso");
				} else {
					self.$app.preloader.hide();
					pdi.gps.lat = response.lat;
					pdi.gps.lon = response.lon;
					updateView(self);
				}
			});
		} else {
			updateView(self);
		}
	} else {
		updateView(self);
	}
}

function pdiGpsInputBlur(e, self) {
	var $ = self.$;
	var $elem = $(e.target);
	e.target.value = e.target.value.trim();
	const gps_type = $elem.attr('gps_type');
	var insertedValue = $elem.val();
	if (insertedValue === '') {
		insertedValue = undefined;
	} else {
		insertedValue = Number(insertedValue);
	}
	self.pdi.gps[gps_type] = insertedValue;
	updateView(self);
}

/*
 *	ELEMS IN PDI
 */

function checkElems(pdi, self) {
	if (Object.keys(pdi.toilets).length === 0 && Object.keys(pdi.cabins).length === 0 && Object.keys(pdi.square).length === 0) {
		self.$(`#pdi-elems-selector`).addClass(`wrong-field`);
	} else {
		self.$(`#pdi-elems-selector`).removeClass(`wrong-field`);
	}
}

function pdiToiletsPresentClick(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	if ($elem.prop('checked')) {
		pdi.toilets = {
			cleaning_suspended: false,
			suspension_history: [],
			night_sanitization: -1,
			male: {
				number: 0,
				unusable: 0,
				elems: {}
			},
			female: {
				number: 0,
				unusable: 0,
				elems: {}
			},
			wpass: Array(7).fill(0)
		};
		checkElems(pdi, self);
		updateView(self);
	} else {
		self.$app.dialog.confirm(`Sicuro di voler eliminare i bagni dal punto di interesse?<br><span class='text-color-red'>Perderai tutte le modifiche effettuate</span>`, ()=>{
			pdi.toilets = {};
			checkElems(pdi, self);
			updateView(self);
		}, () => {
			$elem.prop('checked', true);
		});
	}
}

function pdiCabinsPresentClick(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	if ($elem.prop('checked')) {
		pdi.cabins = {
			cleaning_suspended: false,
			suspension_history: [],
			wpass: Array(7).fill(0),
			number: 0,
			elems: {}
		};
		checkElems(pdi, self);
		updateView(self);
	} else {
		self.$app.dialog.confirm(`Sicuro di voler eliminare le cabine dal punto di interesse?<br><span class='text-color-red'>Perderai tutte le modifiche effettuate</span>`, ()=>{
			pdi.cabins = {};
			checkElems(pdi, self);
			updateView(self);
		}, () => {
			$elem.prop('checked', true);
		});
	}
}

function pdiSquarePresentClick(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	if ($elem.prop('checked')) {
		pdi.square = {
			cleaning_suspended: false,
			suspension_history: [],
			wpass: Array(7).fill(0),
		};
		checkElems(pdi, self);
		updateView(self);
	} else {
		self.$app.dialog.confirm(`Sicuro di voler eliminare il piazzale dal punto di interesse?<br><span class='text-color-red'>Perderai tutte le modifiche effettuate</span>`, ()=>{
			pdi.square = {};
			checkElems(pdi, self);
			updateView(self);
		}, () => {
			$elem.prop('checked', true);
		});
	}
}

/*
 *	CLEANING PLAN
 */

function pdiCleaningPlanBlur(e, self){
	var $ = self.$;
	e.target.value = e.target.value.trim();
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	const pdi_type = $elem.attr("pdi_type");
	const day_index = Number($elem.attr("day_index"));
	var insertedValue = Number($elem.val());
	if (insertedValue < 0) {
		insertedValue = 0;
		e.target.value = insertedValue;
	}
	var pdi_elem = self.pdi[pdi_type];
	pdi_elem.wpass[day_index] = insertedValue;
}

function pdiCleaningSuspendClick(e, self) {
	var $ = self.$;
	e.target.value = e.target.value.trim();
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	const pdi_type = $elem.attr("pdi_type");
	var pdi_elem = self.pdi[pdi_type];
	pdi_elem.cleaning_suspended = $elem.prop('checked')

	updateView(self);
}

/*
 *	TOILETS MANAGEMENT
 */

function pdiRemoveToilet(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	const toilet_id = $elem.attr('toilet_id');
	const toilet_gender = $elem.attr('toilet_gender');
	self.$app.dialog.confirm(`Sicuro di voler eliminare <strong>${toilet_id}</strong>?`, () => {
		var pdi_toilets = pdi.toilets[toilet_gender];
		var elems = pdi_toilets.elems;
		pdi_toilets.number--;
		if (elems[toilet_id].unusable) {
			pdi_toilets.unusable--;
		}
		delete elems[toilet_id];

		updateView(self);
	});
}

function pdiToiletUnsableClick(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	const toilet_id = $elem.attr('toilet_id');
	const toilet_gender = $elem.attr('toilet_gender');
	var pdi_toilets = pdi.toilets[toilet_gender];
	var toilet = pdi_toilets.elems[toilet_id];
	toilet.unusable = $elem.prop('checked');
}

function pdiToiletsSanificationClick(e, self) {
	var $ = self.$;
	var $elem = $(e.target);

	self.pdi.toilets.night_sanitization = Number($elem.val());

	pdiSanitizationAutoScroll(self);

}

/*
 *	CABINS MANAGEMENT
 */

function pdiRemoveCabin(e, self) {
	var $ = self.$;
	var $elem = self.$(e.target);
	var pdi = self.pdi;
	const cabin_id = $elem.attr('cabin_id');
	self.$app.dialog.confirm(`Sicuro di voler eliminare <strong>${cabin_id}</strong>?`, () => {
		var pdi_elems = pdi.cabins.elems;
		pdi.cabins.number--;
		delete pdi_elems[cabin_id];
		updateView(self);
	});
}

/*
 *	NIGHT SANIFICATION AUTO SCROLL
 */

function pdiSanitizationAutoScroll(self) {
	var $ = self.$;
	if (Object.keys(self.pdi.toilets).length > 0 && self.pdi.toilets.night_sanitization !== -1) {
		var $treeview_item = $(`.treeview-item-toilets`);
		$treeview_item.off('treeview:open');
		$treeview_item.on('treeview:open', function(e) {
			var $sanification_list_container = document.querySelector(`#toilets-sanification-list-container`);
			var index = self.pdi.toilets.night_sanitization;
			index = (index === 0)? 7:index;
			var scroll = 44*index;
			$sanification_list_container.scrollTop = scroll;
		});
	}
}

/*
 *	BIND EVENTS
 */

export function bindEvents(self) {
	var $ = self.$;

	// Auto scroll night sanification
	pdiSanitizationAutoScroll(self);

	// NAME
	if(self.new) {
		$('#pdi-name').off('blur');
		$('#pdi-name').on('blur', function(e) {
			pdiNameBlur(e, self);
		});
	}

	// ADRRESS
	$(`#pdi-address`).off('blur');
	$(`#pdi-address`).on('blur', function(e) {
		pdiAddressBlur(e, self);
	});

	// TOILETS PRESENT
	$(`#pdi-toilets-present`).off('click');
	$(`#pdi-toilets-present`).on('click', function(e) {
		pdiToiletsPresentClick(e, self);
	});

	// CABINS PRESENT
	$(`#pdi-cabins-present`).off('click');
	$(`#pdi-cabins-present`).on('click', function(e) {
		pdiCabinsPresentClick(e, self);
	});

	// SQUARE PRESENT
	$(`#pdi-square-present`).off('click');
	$(`#pdi-square-present`).on('click', function(e) {
		pdiSquarePresentClick(e, self);
	});

	// CLEANING PLAN
	$(`.pdi-cleaning-plan`).off('blur');
	$(`.pdi-cleaning-plan`).on('blur', function(e) {
		pdiCleaningPlanBlur(e, self);
	});

	// CLEANING SUSPENDED
	$(`.pdi-cleaning-suspended`).off('click');
	$(`.pdi-cleaning-suspended`).on('click', function(e) {
		pdiCleaningSuspendClick(e, self);
	});

	$(`.remove-toilet-icon`).off('click');
	$(`.remove-toilet-icon`).on('click', function(e) {
		pdiRemoveToilet(e, self);
	});

	$(`.toilet-unusable`).off('click');
	$(`.toilet-unusable`).on('click', function(e) {
		pdiToiletUnsableClick(e, self);
	});

	$(`.pdi-toilets-sanification`).off('click');
	$(`.pdi-toilets-sanification`).on('click', function(e) {
		pdiToiletsSanificationClick(e, self);
	});

	$(`#pdi-gps-auto`).off('click');
	$(`#pdi-gps-auto`).on('click', function(e) {
		pdiGpsAutoToggle(e, self);
	});

	$(`.pdi-gps-input`).off('blur');
	$(`.pdi-gps-input`).on('blur', function(e) {
		pdiGpsInputBlur(e, self);
	});

	$(`.remove-cabin-icon`).off('click');
	$(`.remove-cabin-icon`).on('click', function(e) {
		pdiRemoveCabin(e, self);
	});

	// console.log(self.pdi);
}
