import {thresholdsDB} from './config';

export function saveThresholdsDoc(thresholds, callback) {
	thresholdsDB.get("anomalies_thresholds").then((response) => {
		thresholds._rev = response._rev;
		thresholdsDB.put(thresholds).then(res => callback(undefined, res));
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	});
}

export function getThresholds(callback) {
	thresholdsDB.get("anomalies_thresholds").then((response) => {
		return callback(undefined, response);
	}).catch((error) => {
		console.log(error);
		return callback(error, undefined);
	});
}
